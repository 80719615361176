/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Bondsmith Savings Exchange Bank Portal APIs
 * OpenAPI spec version: 0.1
 */

export type ProductType = typeof ProductType[keyof typeof ProductType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProductType = {
  INSTANT: 'INSTANT',
  NOTICE: 'NOTICE',
  TERM: 'TERM',
} as const;
