import {
  Box,
  Divider,
  Typography,
  List,
  ListItem,
  styled,
} from '@mui/material';
import { useTranslation } from 'next-i18next';

interface DepositMaturingListItemProps {
  readonly timePeriod: string;
  readonly amount: number;
}

const DepositMaturingListItem = ({
  timePeriod,
  amount,
}: DepositMaturingListItemProps): JSX.Element => {
  const { t } = useTranslation(['common']);

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '100%',
        p: 1,
      }}>
      <Typography variant="body1">{timePeriod}</Typography>
      {amount < 0 && (
        <NegativeDeposits sx={{ borderRadius: '10px' }}>
          <Box
            sx={{
              borderRadius: '10px',
              border: 'solid 1px',
              width: '100px',
              height: '32px',
              textAlign: 'center',
              p: 1,
            }}>
            {t('common:currency', {
              value: {
                amount: amount,
                currency: 'GBP',
              },
            })}
          </Box>
        </NegativeDeposits>
      )}
      {amount >= 0 && (
        <PositiveDeposits sx={{ borderRadius: '10px' }}>
          <Box
            sx={{
              borderRadius: '10px',
              border: 'solid 1px',
              width: '100px',
              height: '32px',
              textAlign: 'center',
              fontSize: '10px',
              p: 1,
            }}>
            {t('common:currency', {
              value: {
                amount: amount,
                currency: 'GBP',
              },
            })}
          </Box>
        </PositiveDeposits>
      )}
    </Box>
  );
};

interface DepositMaturingProps {
  readonly timePeriod: readonly string[];
  readonly deposits: readonly number[];
}

export const TotalDepositMaturing = ({
  timePeriod,
  deposits,
}: DepositMaturingProps): JSX.Element => {
  const { t } = useTranslation(['home']);
  return (
    <List
      sx={{
        p: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        height: '100%',
      }}>
      <ListItem>
        <Box sx={{ pt: 1, pb: 1 }}>
          <Typography variant="body1">
            {t('home:maturingDeposits.header')}
          </Typography>
        </Box>
      </ListItem>
      {timePeriod.map((props, idx) => (
        <div key={timePeriod[idx]}>
          <Divider />
          <ListItem>
            <DepositMaturingListItem
              timePeriod={timePeriod[idx]}
              amount={deposits[idx]}
            />
          </ListItem>
        </div>
      ))}
    </List>
  );
};

const PositiveDeposits = styled('div')({
  border: '#71CB94',
  color: '#71CB94',
  background: '#F3FDF7',
  fontSize: '10px',
});

const NegativeDeposits = styled('div')({
  border: '#CE6B85',
  color: '#CE6B85',
  background: '#FBF1F3',
  fontSize: '10px',
});
