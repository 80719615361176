/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Bondsmith Savings Exchange Bank Portal APIs
 * OpenAPI spec version: 0.1
 */
import { useQuery } from 'react-query';
import type {
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { customInstance } from '../../../../mutator/instance';
import type {
  BondsmithPageBatchEventPaginatedRecord,
  FetchEventsParams,
} from '../../models';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

export const fetchEvents = (
  params?: FetchEventsParams,
  signal?: AbortSignal
) => {
  return customInstance<BondsmithPageBatchEventPaginatedRecord>({
    url: `/banks/batch/events/paginated`,
    method: 'GET',
    params,
    signal,
  });
};

export const getFetchEventsQueryKey = (params?: FetchEventsParams) => {
  return [
    `/banks/batch/events/paginated`,
    ...(params ? [params] : []),
  ] as const;
};

export const getFetchEventsQueryOptions = <
  TData = Awaited<ReturnType<typeof fetchEvents>>,
  TError = unknown
>(
  params?: FetchEventsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof fetchEvents>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getFetchEventsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof fetchEvents>>> = ({
    signal,
  }) => fetchEvents(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof fetchEvents>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type FetchEventsQueryResult = NonNullable<
  Awaited<ReturnType<typeof fetchEvents>>
>;
export type FetchEventsQueryError = unknown;

export const useFetchEvents = <
  TData = Awaited<ReturnType<typeof fetchEvents>>,
  TError = unknown
>(
  params?: FetchEventsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof fetchEvents>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getFetchEventsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const fetchBatchEventFileURL = (
  batchUid: string,
  signal?: AbortSignal
) => {
  return customInstance<string>({
    url: `/banks/batch/events/${batchUid}/file`,
    method: 'GET',
    signal,
  });
};

export const getFetchBatchEventFileURLQueryKey = (batchUid: string) => {
  return [`/banks/batch/events/${batchUid}/file`] as const;
};

export const getFetchBatchEventFileURLQueryOptions = <
  TData = Awaited<ReturnType<typeof fetchBatchEventFileURL>>,
  TError = unknown
>(
  batchUid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof fetchBatchEventFileURL>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getFetchBatchEventFileURLQueryKey(batchUid);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof fetchBatchEventFileURL>>
  > = ({ signal }) => fetchBatchEventFileURL(batchUid, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!batchUid,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof fetchBatchEventFileURL>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type FetchBatchEventFileURLQueryResult = NonNullable<
  Awaited<ReturnType<typeof fetchBatchEventFileURL>>
>;
export type FetchBatchEventFileURLQueryError = unknown;

export const useFetchBatchEventFileURL = <
  TData = Awaited<ReturnType<typeof fetchBatchEventFileURL>>,
  TError = unknown
>(
  batchUid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof fetchBatchEventFileURL>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getFetchBatchEventFileURLQueryOptions(batchUid, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};
