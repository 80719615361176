/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Bondsmith Savings Exchange Bank Portal APIs
 * OpenAPI spec version: 0.1
 */
import { useMutation, useQuery } from 'react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { customInstance } from '../../../../mutator/instance';
import type {
  BondsmithPageApprovalResponse,
  ListApprovalsParams,
} from '../../models';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

export const listApprovals = (
  params?: ListApprovalsParams,
  signal?: AbortSignal
) => {
  return customInstance<BondsmithPageApprovalResponse>({
    url: `/operations/approvals`,
    method: 'GET',
    params,
    signal,
  });
};

export const getListApprovalsQueryKey = (params?: ListApprovalsParams) => {
  return [`/operations/approvals`, ...(params ? [params] : [])] as const;
};

export const getListApprovalsQueryOptions = <
  TData = Awaited<ReturnType<typeof listApprovals>>,
  TError = unknown
>(
  params?: ListApprovalsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof listApprovals>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListApprovalsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listApprovals>>> = ({
    signal,
  }) => listApprovals(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof listApprovals>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListApprovalsQueryResult = NonNullable<
  Awaited<ReturnType<typeof listApprovals>>
>;
export type ListApprovalsQueryError = unknown;

export const useListApprovals = <
  TData = Awaited<ReturnType<typeof listApprovals>>,
  TError = unknown
>(
  params?: ListApprovalsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof listApprovals>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListApprovalsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const acceptApproval = (approvalUid: string) => {
  return customInstance<void>({
    url: `/operations/approvals/${approvalUid}/accept`,
    method: 'POST',
  });
};

export const getAcceptApprovalMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof acceptApproval>>,
    TError,
    { approvalUid: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof acceptApproval>>,
  TError,
  { approvalUid: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof acceptApproval>>,
    { approvalUid: string }
  > = (props) => {
    const { approvalUid } = props ?? {};

    return acceptApproval(approvalUid);
  };

  return { mutationFn, ...mutationOptions };
};

export type AcceptApprovalMutationResult = NonNullable<
  Awaited<ReturnType<typeof acceptApproval>>
>;

export type AcceptApprovalMutationError = unknown;

export const useAcceptApproval = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof acceptApproval>>,
    TError,
    { approvalUid: string },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof acceptApproval>>,
  TError,
  { approvalUid: string },
  TContext
> => {
  const mutationOptions = getAcceptApprovalMutationOptions(options);

  return useMutation(mutationOptions);
};
export const rejectApproval = (approvalUid: string) => {
  return customInstance<void>({
    url: `/operations/approvals/${approvalUid}/reject`,
    method: 'POST',
  });
};

export const getRejectApprovalMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof rejectApproval>>,
    TError,
    { approvalUid: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof rejectApproval>>,
  TError,
  { approvalUid: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof rejectApproval>>,
    { approvalUid: string }
  > = (props) => {
    const { approvalUid } = props ?? {};

    return rejectApproval(approvalUid);
  };

  return { mutationFn, ...mutationOptions };
};

export type RejectApprovalMutationResult = NonNullable<
  Awaited<ReturnType<typeof rejectApproval>>
>;

export type RejectApprovalMutationError = unknown;

export const useRejectApproval = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof rejectApproval>>,
    TError,
    { approvalUid: string },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof rejectApproval>>,
  TError,
  { approvalUid: string },
  TContext
> => {
  const mutationOptions = getRejectApprovalMutationOptions(options);

  return useMutation(mutationOptions);
};
