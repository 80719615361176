/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Bondsmith Savings Exchange Operation Portal APIs
 * OpenAPI spec version: 0.1
 */

export type HolidayCalendarType =
  typeof HolidayCalendarType[keyof typeof HolidayCalendarType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const HolidayCalendarType = {
  LONDON: 'LONDON',
} as const;
