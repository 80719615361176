/**
 * Simplified omit function
 *
 * @param obj
 * @param props
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function omit<T extends Record<any, any>, K extends keyof T>(
  obj: T,
  props: readonly K[]
): Omit<T, K> {
  const newObject = { ...obj };

  // eslint-disable-next-line functional/no-loop-statement
  for (const prop of props) {
    delete newObject[prop];
  }

  return newObject;
}
