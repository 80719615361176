/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Bondsmith Savings Exchange Operation Portal APIs
 * OpenAPI spec version: 0.1
 */

export type RealAccountType =
  typeof RealAccountType[keyof typeof RealAccountType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RealAccountType = {
  HUB: 'HUB',
  EMONEY: 'EMONEY',
  OPERATIONAL: 'OPERATIONAL',
  FEE: 'FEE',
  SANDBOX: 'SANDBOX',
  FUNDING: 'FUNDING',
} as const;
