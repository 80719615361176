/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Bondsmith Savings Exchange Operation Portal APIs
 * OpenAPI spec version: 0.1
 */

export type InstructionDirection =
  typeof InstructionDirection[keyof typeof InstructionDirection];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InstructionDirection = {
  HUB_EMONEY: 'HUB_EMONEY',
  EMONEY_HUB: 'EMONEY_HUB',
} as const;
