import { Box, Divider, Typography, useTheme, styled } from '@mui/material';
import { useTranslation } from 'next-i18next';
import React from 'react';
import { Banks } from '../../icons/banks';
import { Deposits } from '../../icons/deposits';
import { Platforms } from '../../icons/platforms';
import { Products } from '../../icons/products';

interface OverviewProps {
  readonly totalDeposits?: number;
  readonly totalBanks?: number;
  readonly totalPlatforms?: number;
  readonly totalProducts?: number;
  readonly portal?: 'banks' | 'ops';
}

export const Overview: React.FC<OverviewProps> = ({
  totalDeposits,
  totalBanks,
  totalPlatforms,
  totalProducts,
  portal,
}) => {
  const { t } = useTranslation(['home', 'common']);

  const items = [
    portal === 'ops' && {
      logo: <Banks />,
      header: t('home:overview.totalBanks'),
      subHeader: t('common:number', {
        value: { amount: totalBanks },
      }),
      isTopChartHeader: false,
    },
    portal === 'ops' && {
      logo: <Platforms />,
      header: t('home:overview.totalPlatforms'),
      subHeader: t('common:number', {
        value: { amount: totalPlatforms },
      }),
      isTopChartHeader: false,
    },
    {
      logo: <Products />,
      header: t('home:overview.totalProducts'),
      subHeader: t('common:number', {
        value: { amount: totalProducts },
      }),
      isTopChartHeader: false,
    },
  ].filter(Boolean);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
        flexGrow: 1,
      }}>
      <Box>
        <OverviewComponents
          p={2}
          logo={<Deposits />}
          header={t('home:overview.totalDeposits')}
          subHeader={t('common:currency', {
            value: {
              amount: totalDeposits,
              currency: 'GBP',
            },
          })}
          isTopChartHeader={false}
        />
      </Box>
      {items.map(
        (props) =>
          props !== false && (
            <React.Fragment key={props.header}>
              <Divider />
              <OverviewComponents {...props} p={2} />
            </React.Fragment>
          )
      )}
    </Box>
  );
};

interface OverviewComponentsProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  readonly logo: any;
  readonly header: string;
  readonly subHeader: string;
  readonly isTopChartHeader: boolean;
  readonly p?: number;
}

export const OverviewComponents: React.FC<OverviewComponentsProps> = ({
  logo,
  subHeader,
  isTopChartHeader,
  header,
  p,
}) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: 'flex',
        // gridTemplateColumns: '1fr 2fr',
        width: '100%',
        p,
        columnGap: 2,
        justifySelf: 'flex-start',
      }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}>
        {logo}
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}>
        <Box>{header}</Box>
        {!isTopChartHeader && (
          <Box
            sx={{
              color: theme.palette.primary.main,
            }}>
            <Typography variant="h5">{subHeader}</Typography>
          </Box>
        )}
        {isTopChartHeader && (
          <SubHeader>
            <Typography variant="body2">{subHeader}</Typography>
          </SubHeader>
        )}
      </Box>
    </Box>
  );
};

const SubHeader = styled('div')({
  color: '#757575',
});
