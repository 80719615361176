import {
  notEmpty,
  stringPercentageNumber,
} from '@b7hio/core-lib/src/form/validators';
import { object, string, refine } from 'superstruct';

export const ProductRateChangeFormValidation = object({
  grossRate: refine(
    stringPercentageNumber(),
    'grossRate',
    (value) => value >= 0 && value <= 0.1
  ),
  startDate: notEmpty(string()),
  announcedAt: notEmpty(string()),
});
