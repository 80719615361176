/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Bondsmith Savings Exchange Operation Portal APIs
 * OpenAPI spec version: 0.1
 */

export type DomainObjectStatus =
  typeof DomainObjectStatus[keyof typeof DomainObjectStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DomainObjectStatus = {
  ACTIVE: 'ACTIVE',
  PENDING: 'PENDING',
  INTERNAL_REVIEW: 'INTERNAL_REVIEW',
  REJECTED: 'REJECTED',
  CLOSED: 'CLOSED',
  SOFT_CLOSED: 'SOFT_CLOSED',
} as const;
