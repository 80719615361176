/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Bondsmith Savings Exchange Bank Portal APIs
 * OpenAPI spec version: 0.1
 */
import { useMutation, useQuery } from 'react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { customInstance } from '../../../../mutator/instance';
import type { BanksListUsersParams, PortalUserResponse } from '../../models';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

/**
 * @summary List users
 */
export const banksListUsers = (
  params?: BanksListUsersParams,
  signal?: AbortSignal
) => {
  return customInstance<PortalUserResponse[]>({
    url: `/banks/user`,
    method: 'GET',
    params,
    signal,
  });
};

export const getBanksListUsersQueryKey = (params?: BanksListUsersParams) => {
  return [`/banks/user`, ...(params ? [params] : [])] as const;
};

export const getBanksListUsersQueryOptions = <
  TData = Awaited<ReturnType<typeof banksListUsers>>,
  TError = unknown
>(
  params?: BanksListUsersParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof banksListUsers>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getBanksListUsersQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof banksListUsers>>> = ({
    signal,
  }) => banksListUsers(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof banksListUsers>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type BanksListUsersQueryResult = NonNullable<
  Awaited<ReturnType<typeof banksListUsers>>
>;
export type BanksListUsersQueryError = unknown;

/**
 * @summary List users
 */
export const useBanksListUsers = <
  TData = Awaited<ReturnType<typeof banksListUsers>>,
  TError = unknown
>(
  params?: BanksListUsersParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof banksListUsers>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getBanksListUsersQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Fetch a user
 */
export const banksFetchUser = (userUid: string, signal?: AbortSignal) => {
  return customInstance<PortalUserResponse>({
    url: `/banks/user/${userUid}`,
    method: 'GET',
    signal,
  });
};

export const getBanksFetchUserQueryKey = (userUid: string) => {
  return [`/banks/user/${userUid}`] as const;
};

export const getBanksFetchUserQueryOptions = <
  TData = Awaited<ReturnType<typeof banksFetchUser>>,
  TError = unknown
>(
  userUid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof banksFetchUser>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getBanksFetchUserQueryKey(userUid);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof banksFetchUser>>> = ({
    signal,
  }) => banksFetchUser(userUid, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!userUid,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof banksFetchUser>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type BanksFetchUserQueryResult = NonNullable<
  Awaited<ReturnType<typeof banksFetchUser>>
>;
export type BanksFetchUserQueryError = unknown;

/**
 * @summary Fetch a user
 */
export const useBanksFetchUser = <
  TData = Awaited<ReturnType<typeof banksFetchUser>>,
  TError = unknown
>(
  userUid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof banksFetchUser>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getBanksFetchUserQueryOptions(userUid, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Delete a user
 */
export const banksDeleteUser = (userUid: string) => {
  return customInstance<void>({
    url: `/banks/user/${userUid}`,
    method: 'DELETE',
  });
};

export const getBanksDeleteUserMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof banksDeleteUser>>,
    TError,
    { userUid: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof banksDeleteUser>>,
  TError,
  { userUid: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof banksDeleteUser>>,
    { userUid: string }
  > = (props) => {
    const { userUid } = props ?? {};

    return banksDeleteUser(userUid);
  };

  return { mutationFn, ...mutationOptions };
};

export type BanksDeleteUserMutationResult = NonNullable<
  Awaited<ReturnType<typeof banksDeleteUser>>
>;

export type BanksDeleteUserMutationError = unknown;

/**
 * @summary Delete a user
 */
export const useBanksDeleteUser = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof banksDeleteUser>>,
    TError,
    { userUid: string },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof banksDeleteUser>>,
  TError,
  { userUid: string },
  TContext
> => {
  const mutationOptions = getBanksDeleteUserMutationOptions(options);

  return useMutation(mutationOptions);
};
