/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { BankProductUploadRequestBody } from '@b7hio/api-lib/src/bank-portal';
import { FeatureDialog } from '@b7hio/core-lib/src';
import { useSnackbar } from '@b7hio/core-lib/src';
import { FormFriendly } from '@b7hio/core-lib/src/form';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import FeedIcon from '@mui/icons-material/Feed';
import { Box, Button, styled, Alert, Typography } from '@mui/material';
import { useTranslation } from 'next-i18next';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { showErrors } from '@b7hio/core-lib/src/utils';

interface Props {
  readonly open: boolean;
  readonly onClose: (event: object, reason: string) => void;
  readonly onSubmit: (data: BankProductUploadRequestBody) => void;
  readonly onDownloadAllProducts: () => void;
}

export const ProductUploadAllDialog = ({
  open,
  onClose,
  onSubmit,
  onDownloadAllProducts,
}: Props) => {
  const { t } = useTranslation(['common', 'products']);
  return (
    <FeatureDialog
      id="ProductUploadAllDialog"
      open={open}
      title={t('products:uploadAllProducts.title')}
      onClose={(event) => {
        onClose(event, 'skip');
      }}>
      <ProductUploadAllForm
        onSubmit={onSubmit}
        onDownloadAllProducts={onDownloadAllProducts}
        onClose={onClose}
      />
    </FeatureDialog>
  );
};

interface ProductUploadAllFormProps {
  readonly onSubmit: (data: BankProductUploadRequestBody) => void;
  readonly onDownloadAllProducts: () => void;
  readonly onClose: (event: object, reason: string) => void;
}

const openFileBrowser = () => {
  document.getElementById('upload')!.click();
};

const ProductUploadAllForm = ({
  onSubmit,
  onDownloadAllProducts,
  onClose,
}: ProductUploadAllFormProps) => {
  const { t } = useTranslation(['common', 'products']);

  const [state, setState] = useState<'upload' | 'summary'>('upload');

  const [fileName, setFileName] = useState('');

  const { handleSubmit, formState, getValues, setValue } = useForm<
    FormFriendly<BankProductUploadRequestBody>
  >({
    mode: 'onBlur',
  });

  const { enqueueSnackbar } = useSnackbar();

  return (
    <form
      onSubmit={handleSubmit(
        async (values) => {
          try {
            await onSubmit(values as BankProductUploadRequestBody);
            setState('summary');
          } catch (error) {
            showErrors(
              error,
              enqueueSnackbar,
              'Something went wrong, try again later'
            );
          }
        },
        // eslint-disable-next-line no-console
        (errors) => console.log(errors, getValues())
      )}
      style={{ gridArea: 'form' }}>
      <FormContainer>
        {state === 'upload' && (
          <>
            <Alert severity="info">
              {t('products:uploadAllProducts.instructions')}
            </Alert>
            {fileName !== '' && (
              <Box sx={{ my: 1, display: 'flex', gap: 1 }}>
                <FeedIcon color="disabled" />
                <Typography variant="body1">{fileName}</Typography>
                <CheckCircleIcon color="success" />
              </Box>
            )}
            <Button
              sx={{ my: 1, minHeight: '100px' }}
              color="primary"
              variant="contained"
              disabled={formState.isSubmitting}
              onClick={onDownloadAllProducts}>
              {t('products:btn.downloadAllProducts')}
            </Button>
            <Button
              sx={{ my: 1, minHeight: '100px' }}
              color="primary"
              variant="outlined"
              disabled={formState.isSubmitting}
              onClick={openFileBrowser}>
              <input
                type="file"
                onChange={(event) => {
                  const file = event.target?.files?.[0] as Blob;
                  setValue('data', file as Blob);
                  setFileName(event.target?.files?.[0].name ?? '');
                }}
                id="upload"
                hidden
              />
              {t('products:btn.uploadAllProducts')}
            </Button>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-end',
                gap: 2,
              }}>
              <Button
                sx={{ mt: 2, mb: 2, minWidth: '150px' }}
                color="primary"
                variant="outlined"
                onClick={(e: object) => {
                  onClose(e, 'skip');
                }}>
                {t('products:uploadAllProducts.cancel')}
              </Button>
              <Button
                sx={{ mt: 2, mb: 2, minWidth: '150px' }}
                color="primary"
                variant="contained"
                disabled={
                  formState.isSubmitting || getValues().data === undefined
                }
                type="submit">
                {t('products:uploadAllProducts.submit')}
              </Button>
            </Box>
          </>
        )}
        {state === 'summary' && (
          <>
            <FormContainer>
              <Alert severity="success">
                {t('products:uploadAllProducts.success')}
              </Alert>
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'flex-end',
                  gap: 2,
                }}>
                <Button
                  sx={{ mt: 2, mb: 2 }}
                  color="primary"
                  variant="contained"
                  onClick={(e: object) => {
                    onClose(e, 'skip');
                  }}>
                  {t('products:uploadAllProducts.close')}
                </Button>
              </Box>
            </FormContainer>
          </>
        )}
      </FormContainer>
    </form>
  );
};

const FormContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  '> div': {
    marginBottom: theme.spacing(2),

    '&:last-of-type': {
      marginBottom: 0,
    },
  },
}));
