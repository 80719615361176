import { InstructionDirection } from '@b7hio/api-lib/src/ops-portal';
import { tsEnum, FormFriendly } from '@b7hio/core-lib/src/form';
import { DateTime } from 'luxon';
import type { Describe } from 'superstruct';
import { nullable, object, optional, string } from 'superstruct';

export interface TransactionListFilterValues {
  readonly instructionDirection?: InstructionDirection;
  readonly startDate?: DateTime;
  readonly endDate?: DateTime;
}

export const defaultValues = (): FormFriendly<TransactionListFilterValues> => ({
  instructionDirection: '',
  startDate: '',
  endDate: '',
});

export const transactionsListFilterValidation: Describe<TransactionListFilterValues> =
  object({
    paymentDirection: nullable(optional(tsEnum(InstructionDirection, true))),
    startDate: optional(string()),
    endDate: optional(string()),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  }) as any;
