import { BankProductRequestWithRateDetail } from '@b7hio/api-lib/src/bank-portal';
import {
  BankProductResponse,
  CoolOffPeriodType,
  CurrencyCode,
  ExoticType,
  HolidayCalendarType,
  InterestPayoutPeriodType,
  InternalAccountHolderType,
  InternalTaxWrapper,
  ProductType,
  RequestAccountHolderType,
} from '@b7hio/api-lib/src/ops-portal';

import {
  FormAutocomplete,
  FormDatePicker,
  FormTextField,
  MaskedTextField,
} from '@b7hio/core-lib/src/components';
import { FormFriendly, superstructResolver } from '@b7hio/core-lib/src/form';
import { omit } from '@b7hio/core-lib/src/utils';
import {
  Autocomplete,
  Button,
  Divider,
  styled,
  TextField as MuiTextField,
  TextField,
} from '@mui/material';
import { useUser } from 'banks-portal/src/hooks/useUser';
import { pipe } from 'fp-ts/lib/function';
import * as RA from 'fp-ts/ReadonlyArray';
import * as RR from 'fp-ts/ReadonlyRecord';
import React, { useEffect, useState } from 'react';
import type { FieldValues } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { UseFormSetError } from 'react-hook-form/dist/types/form';
import { useTranslation } from 'react-i18next';
import { create } from 'superstruct';
import { ProductDocuments } from '../../hooks/useProductInteractivity';
import {
  AddProductValidation,
  EditProductValidation,
} from './ProductForm.validation';

type BankProductResponseForUpdate = Omit<
  BankProductResponse,
  | 'uid'
  | 'bank'
  | 'createdAt'
  | 'updatedAt'
  | 'feeDetails'
  | 'rateDetails'
  | 'proposalUid'
  | 'currentState'
>;

interface Props<
  P,
  S extends FieldValues = P extends BankProductResponseForUpdate
    ? BankProductRequestWithRateDetail
    : BankProductRequestWithRateDetail
> {
  readonly product: P;
  readonly onSubmit: (
    values: S,
    files: ProductDocuments,
    setError: UseFormSetError<S>
  ) => Promise<void>;
}

export function BankProductForm<
  P extends BankProductResponseForUpdate | undefined
>({ onSubmit, product }: Props<P>) {
  const isEditing = Boolean(product);

  const [tandcUrl, setTandcFile] = useState<File | undefined>(undefined);
  const [brochureUrl, setBrochureFile] = useState<File | undefined>(undefined);

  const currentProduct =
    product != null
      ? {
          accountHolderTypes: convertAccountHolderTypes(
            product.accountHolderTypes
          ),
          bankUid: product.bankUid,
          currency: product.currency,
          depositRequirement: product.depositRequirement,
          exoticType: product.exoticType,
          externalId: product.externalId,
          holidayCalendar: product.holidayCalendar,
          interestFeature: product.interestFeature,
          maximumAvailable: product.maximumAvailable,
          name: product.name,
          periodFeature: product.periodFeature,
          productAvailability: product.productAvailability,
          productLiterature: {
            brochureUrl: product.productLiterature?.brochureUrl ?? null,
            tandcUrl: product.productLiterature?.tandcUrl ?? null,
          },
          productType: product.productType,
          rateDetail: {
            grossRate: product.grossRate,
            startDate: product.startDate,
          },
          startDate: product.startDate,
          stopDisplayAt: product.stopDisplayAt,
          taxWrappers: product.taxWrappers,
          isTracker: product.isTracker,
        }
      : undefined;

  const [, partnerUid] = useUser();
  const {
    handleSubmit,
    setError,
    formState,
    watch,
    control,
    setValue,
    getValues,
  } = useForm<FormFriendly<BankProductRequestWithRateDetail>>({
    mode: 'onSubmit',
    resolver: superstructResolver(
      isEditing ? EditProductValidation : AddProductValidation,
      { coerce: true }
    ),
    defaultValues:
      currentProduct ??
      createProductDefaultsWithBankUid(partnerUid ?? '', '23:45:00'),
  });

  const { isSubmitting } = formState;
  const { t } = useTranslation(['common', 'products']);

  const productType = watch('productType');

  const taxWrapperOptions = [
    {
      label: t('products:addProduct.taxWrapperOptions.ALL_RETAIL'),
      value: [
        InternalTaxWrapper.ISA,
        InternalTaxWrapper.SIPP,
        InternalTaxWrapper.NONE,
      ],
    },
    {
      label: t('products:addProduct.taxWrapperOptions.PENSION_ONLY'),
      value: [InternalTaxWrapper.SIPP, InternalTaxWrapper.SSAS],
    },
    {
      label: t('products:addProduct.taxWrapperOptions.ISA_ONLY'),
      value: [InternalTaxWrapper.ISA],
    },
    {
      label: t('products:addProduct.taxWrapperOptions.NONE'),
      value: [InternalTaxWrapper.NONE],
    },
  ];

  const maturityOptions = pipe(
    productType === ProductType.INSTANT || productType === ProductType.NOTICE
      ? omit(InterestPayoutPeriodType, ['AT_MATURITY'])
      : InterestPayoutPeriodType,
    RR.keys,
    RA.map((v) => ({
      label: t(`products:addProduct.interestPayoutPeriodType.${v}`),
      value: v,
    }))
  );

  const booleanOptions = [
    {
      label: 'Yes',
      value: 'true',
    },
    {
      label: 'No',
      value: 'false',
    },
  ];

  useEffect(() => {
    if (productType === ProductType.NOTICE) {
      setValue('periodFeature.termPeriod', '');
    }
    if (productType === ProductType.TERM) {
      setValue('periodFeature.noticePeriod', '');
    }
  }, [productType]);

  return (
    <form
      onSubmit={handleSubmit(
        async (values) => {
          if (product != null) {
            const output = create(values, EditProductValidation);
            // @ts-expect-error TODO
            await onSubmit(output, setError);
          } else {
            const output = create(values, AddProductValidation);

            const productDocs: ProductDocuments = {
              tandcUrl,
              brochureUrl,
            };
            // @ts-expect-error TODO
            await onSubmit(output, productDocs, setError);
          }
        },
        // eslint-disable-next-line no-console
        (errors) => console.log(errors, getValues())
      )}
      style={{ gridArea: 'form' }}>
      <FormContainer>
        {!isEditing && (
          <>
            <FormTextField
              name="name"
              label={t('products:addProduct.name')}
              t={t}
              control={control}
              data-testid="productName"
            />
          </>
        )}
        <FormTextField
          control={control}
          name="externalId"
          label={t('products:addProduct.externalId')}
          t={t}
        />
        <FormAutocomplete
          name="currency"
          data-testid="currency"
          label={t('products:addProduct.currency')}
          options={Object.values(CurrencyCode).map((v) => {
            return {
              label: t(`products:addProduct.currencyCodes.${v}`),
              value: v,
            };
          })}
          t={t}
          control={control}
        />

        {!isEditing && (
          <Autocomplete
            multiple
            renderInput={(params) => (
              <TextField
                {...params}
                label={t('products:addProduct.taxWrappers')}
              />
            )}
            data-testid="taxWrappers"
            options={taxWrapperOptions.map((v) => {
              return {
                label: v.label,
                value: v.value as readonly InternalTaxWrapper[],
              };
            })}
            onChange={(event, newValue) => {
              const totalWrappers: InternalTaxWrapper[] = [];
              newValue.forEach((item) => {
                item.value.forEach((wrapper) => {
                  if (!totalWrappers.includes(wrapper)) {
                    totalWrappers.push(wrapper);
                  }
                });
              });
              setValue('taxWrappers', totalWrappers);
            }}
          />
        )}
        <FormAutocomplete
          // TODO: translation for options
          options={Object.values(ProductType).map((v) => {
            return {
              label: t(`products:productTypes.${v}`),
              value: v,
            };
          })}
          name="productType"
          label={t('products:addProduct.type')}
          t={t}
          control={control}
          data-testid="productType"
        />
        {productType === ProductType.NOTICE && (
          <FormTextField
            name="periodFeature.noticePeriod"
            label={t('products:addProduct.noticePeriod')}
            control={control}
            t={t}
          />
        )}
        {productType === ProductType.TERM && (
          <FormTextField
            name="periodFeature.termPeriod"
            label={t('products:addProduct.termPeriod')}
            control={control}
            t={t}
          />
        )}
        <FormAutocomplete
          name="exoticType"
          label={t('products:addProduct.exoticType')}
          options={Object.values(ExoticType).map((v) => {
            return {
              label: t(`products:addProduct.exoticTypes.${v}`),
              value: v,
            };
          })}
          control={control}
          t={t}
        />
        <FormAutocomplete
          multiple
          name="accountHolderTypes"
          label={t('products:addProduct.accountHolderTypes')}
          options={Object.values(RequestAccountHolderType).map((v) => {
            return {
              label: t(`products:addProduct.accountHolderTypesValues.${v}`),
              value: v,
            };
          })}
          control={control}
          t={t}
        />
        <FormTextField
          name="depositRequirement.min"
          label={t('products:addProduct.minDeposit')}
          t={t}
          control={control}
          currencyFormat
        />
        <FormTextField
          name="depositRequirement.max"
          label={t('products:addProduct.maxDeposit')}
          t={t}
          control={control}
          currencyFormat
        />
        <FormAutocomplete
          name="interestFeature.payoutPeriod"
          label={t('products:addProduct.interestPaymentPeriod')}
          // TODO: translation for options
          options={maturityOptions}
          control={control}
          t={t}
        />
        {!isEditing && (
          <>
            <MaskedTextField
              label={t('products:addProduct.grossRate')}
              name="rateDetail.grossRate"
              defaultValue={'%'}
              t={t}
              inputProps={{
                mask: 'NUM%',
                blocks: {
                  NUM: {
                    // nested masks are available!
                    mask: Number,
                    scale: 2,
                    padFractionalZeros: true,
                    normalizeZeros: true,
                    radix: '.',
                  },
                },
              }}
              control={control}
            />
          </>
        )}
        <FormAutocomplete
          name="isTracker"
          label={t('products:addProduct.isTracker')}
          options={booleanOptions}
          control={control}
          t={t}
        />
        <Divider sx={{ pb: 2, mb: 2 }} />
        {!isEditing && (
          <FormAutocomplete
            name="holidayCalendar"
            label={t('products:addProduct.holidayCalendar')}
            // TODO: translation for options
            options={Object.values(HolidayCalendarType).map((v) => {
              return {
                label: t(`products:addProduct.holidayCalendarTypes.${v}`),
                value: v,
              };
            })}
            control={control}
            t={t}
          />
        )}
        {productType === ProductType.TERM && (
          <FormDatePicker
            name="startDate"
            control={control}
            label={t('products:addProduct.startDate')}
            t={t}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            getErrorPath={(type) => t(`common:validation.date.${type}` as any)}
          />
        )}
        <FormDatePicker
          name="productAvailability.availableFrom"
          control={control}
          label={t('products:addProduct.availableFrom')}
          t={t}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          getErrorPath={(type) => t(`common:validation.date.${type}` as any)}
        />
        {productType === ProductType.TERM && (
          <FormDatePicker
            name="productAvailability.availableUntil"
            control={control}
            label={t('products:addProduct.availableUntil')}
            t={t}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            getErrorPath={(type) => t(`common:validation.date.${type}` as any)}
          />
        )}
        <FormAutocomplete
          name="periodFeature.coolOffPeriod"
          label={t('products:addProduct.coolOffPeriod')}
          // TODO: translation for options
          options={Object.values(CoolOffPeriodType).map((v) => {
            return {
              label: t(`products:addProduct.coolOffPeriodTypes.${v}`),
              value: v,
            };
          })}
          control={control}
          t={t}
        />

        <FormTextField
          name="maximumAvailable"
          label={t('products:addProduct.maximumAvailable')}
          control={control}
          t={t}
          getErrorPath={(type) => t(`common:formErrors.${type}` as any)}
          currencyFormat
        />
        <MuiTextField
          type="file"
          label={t('products:addProduct.termsAndConditions')}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            const file = event.target?.files?.[0];
            if (file != null && file.name !== '') {
              setTandcFile(file);
            }
          }}
        />
        <MuiTextField
          type="file"
          label={t('products:addProduct.productBrochure')}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            const file = event.target?.files?.[0];
            if (file != null && file.name !== '') {
              setBrochureFile(file);
            }
          }}
        />
        <Button
          sx={{ mt: 2, mb: 2 }}
          color="primary"
          variant="contained"
          type="submit"
          disabled={isSubmitting}>
          {t(
            isEditing
              ? 'products:editProduct.submit'
              : 'products:addProduct.submit'
          )}
        </Button>
      </FormContainer>
    </form>
  );
}

const FormContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  '> div': {
    marginBottom: theme.spacing(2),

    '&:last-of-type': {
      marginBottom: 0,
    },
  },
}));

function createProductDefaultsWithBankUid(
  bankUid: string,
  interestCutOffTime: string
) {
  return {
    bankUid,
    name: '',
    externalId: '',
    currency: '',
    productType: '',
    interestFeature: {
      payoutPeriod: '',
      interestCutOffTime,
    },
    rateDetail: {
      grossRate: '',
      startDate: '',
    },
    holidayCalendar: '',
    periodFeature: {
      termPeriod: '',
      noticePeriod: '',
      coolOffPeriod: '',
    },
    exoticType: '',
    accountHolderTypes: [],
    productAvailability: { availableFrom: '', availableUntil: '' },
    depositRequirement: { min: '', max: '' },
    productLiterature: {
      infoUrl: '',
      tandcUrl: '',
      brochureUrl: '',
    },
    taxWrappers: [],
    maximumAvailable: 1,
    isTracker: false,
  } as FormFriendly<BankProductRequestWithRateDetail>;
}

function convertAccountHolderType(
  value: InternalAccountHolderType
): RequestAccountHolderType {
  if (value === InternalAccountHolderType.COMPANY) {
    return RequestAccountHolderType.CORPORATE;
  }
  return RequestAccountHolderType[value];
}

function convertAccountHolderTypes(
  values: InternalAccountHolderType[]
): RequestAccountHolderType[] {
  return values.map(convertAccountHolderType);
}
