import { BankProductResponse as BankPortalBankProductResponse } from '@b7hio/api-lib/src/bank-portal';
import {
  BankProductResponse,
  RateDetailUpdateRequest,
} from '@b7hio/api-lib/src/ops-portal';
import { FeatureDialog } from '@b7hio/core-lib/src/components';
import { useTranslation } from 'next-i18next';
import React from 'react';
import { ProductRateChangeForm } from '../ProductRateChangeForm';

interface Props {
  readonly open: boolean;
  readonly product: BankProductResponse | BankPortalBankProductResponse;
  readonly onClose: (event: object, reason: string) => void;
  readonly onSubmit: (values: RateDetailUpdateRequest) => Promise<void>;
}

export const ProductRateChangeDialog = ({
  open,
  product,
  onClose,
  onSubmit,
}: Props): JSX.Element => {
  const { t } = useTranslation(['products']);
  return (
    <FeatureDialog
      id="ProductRateChangeDialog"
      title={t('products:rateChange.title')}
      open={open}
      onClose={onClose}>
      <ProductRateChangeForm onSubmit={onSubmit} product={product} />
    </FeatureDialog>
  );
};
