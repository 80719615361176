import { DomainObjectStatus, ProductType } from '@b7hio/api-lib/src/ops-portal';
import { FormAutocomplete } from '@b7hio/core-lib/src';
import { Box } from '@mui/material';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormBankList } from '../../../..';
import type { ProductListFilterValues } from '../../../..';

interface Props {
  readonly portal: 'banks' | 'ops';
}

export const ProductListFilter = ({ portal }: Props): JSX.Element => {
  const { t } = useTranslation(['common', 'products']);
  const { control } = useFormContext<ProductListFilterValues>();
  const productTypes = Object.values(ProductType).map((type) => ({
    value: type,
    label: t(`products:productTypes.${type}`),
  }));

  const statuses = Object.values(DomainObjectStatus).map((state) => ({
    value: state,
    label: t(`products:productStates.${state}`),
  }));

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: {
          xs: 'column',
          sm: 'row',
        },
        gap: 1,
      }}>
      {portal === 'ops' && (
        <Box sx={{ flex: 1, minWidth: '300px' }}>
          <FormBankList
            name="bankUid"
            t={t}
            control={control}
            label={t('products:filterBar.bankName')}
            size="small"
          />
        </Box>
      )}
      <FormAutocomplete
        sx={{ flex: 1, minWidth: '200px' }}
        name="productType"
        label={t('products:filterBar.productType')}
        test-id="type"
        t={t}
        control={control}
        options={productTypes}
        size="small"
      />
      <FormAutocomplete
        sx={{ flex: 1, minWidth: '200px' }}
        name="currentState"
        label={t('products:filterBar.status')}
        test-id="status"
        t={t}
        control={control}
        options={statuses}
        size="small"
      />
    </Box>
  );
};
