/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Bondsmith Savings Exchange Operation Portal APIs
 * OpenAPI spec version: 0.1
 */

export type InterestPayoutPeriodType =
  typeof InterestPayoutPeriodType[keyof typeof InterestPayoutPeriodType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InterestPayoutPeriodType = {
  END_OF_DAY: 'END_OF_DAY',
  END_OF_MONTH: 'END_OF_MONTH',
  END_OF_CALENDAR_MONTH: 'END_OF_CALENDAR_MONTH',
  END_OF_8_MONTH: 'END_OF_8_MONTH',
  AT_MATURITY: 'AT_MATURITY',
  AT_MATURITY_AND_ANNIVERSARY: 'AT_MATURITY_AND_ANNIVERSARY',
  ANNUALLY: 'ANNUALLY',
} as const;
