/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Bondsmith Savings Exchange Operation Portal APIs
 * OpenAPI spec version: 0.1
 */

export type CurrencyCode = typeof CurrencyCode[keyof typeof CurrencyCode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CurrencyCode = {
  GBP: 'GBP',
  EUR: 'EUR',
  USD: 'USD',
} as const;
