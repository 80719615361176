/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Bondsmith Savings Exchange Operation Portal APIs
 * OpenAPI spec version: 0.1
 */

export type CoolOffPeriodType =
  typeof CoolOffPeriodType[keyof typeof CoolOffPeriodType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CoolOffPeriodType = {
  NONE: 'NONE',
  TWO_WEEKS: 'TWO_WEEKS',
  THIRTY_DAYS: 'THIRTY_DAYS',
} as const;
