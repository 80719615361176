/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Bondsmith Savings Exchange Operation Portal APIs
 * OpenAPI spec version: 0.1
 */
import { useMutation, useQuery } from 'react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { customInstance } from '../../../../mutator/instance';
import type {
  PlatformFeeStructureRequest,
  PlatformFeeStructureResponses,
} from '../../models';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

export const insertPlatformFeeStructure = (
  platformFeeStructureRequest: PlatformFeeStructureRequest
) => {
  return customInstance<void>({
    url: `/operations/fee-structure`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: platformFeeStructureRequest,
  });
};

export const getInsertPlatformFeeStructureMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof insertPlatformFeeStructure>>,
    TError,
    { data: PlatformFeeStructureRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof insertPlatformFeeStructure>>,
  TError,
  { data: PlatformFeeStructureRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof insertPlatformFeeStructure>>,
    { data: PlatformFeeStructureRequest }
  > = (props) => {
    const { data } = props ?? {};

    return insertPlatformFeeStructure(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type InsertPlatformFeeStructureMutationResult = NonNullable<
  Awaited<ReturnType<typeof insertPlatformFeeStructure>>
>;
export type InsertPlatformFeeStructureMutationBody =
  PlatformFeeStructureRequest;
export type InsertPlatformFeeStructureMutationError = unknown;

export const useInsertPlatformFeeStructure = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof insertPlatformFeeStructure>>,
    TError,
    { data: PlatformFeeStructureRequest },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof insertPlatformFeeStructure>>,
  TError,
  { data: PlatformFeeStructureRequest },
  TContext
> => {
  const mutationOptions = getInsertPlatformFeeStructureMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getPlatformFeeStructures = (
  platformProductUid: string,
  signal?: AbortSignal
) => {
  return customInstance<PlatformFeeStructureResponses>({
    url: `/operations/fee-structure/platform/product/${platformProductUid}`,
    method: 'GET',
    signal,
  });
};

export const getGetPlatformFeeStructuresQueryKey = (
  platformProductUid: string
) => {
  return [
    `/operations/fee-structure/platform/product/${platformProductUid}`,
  ] as const;
};

export const getGetPlatformFeeStructuresQueryOptions = <
  TData = Awaited<ReturnType<typeof getPlatformFeeStructures>>,
  TError = unknown
>(
  platformProductUid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPlatformFeeStructures>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetPlatformFeeStructuresQueryKey(platformProductUid);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPlatformFeeStructures>>
  > = ({ signal }) => getPlatformFeeStructures(platformProductUid, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!platformProductUid,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getPlatformFeeStructures>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetPlatformFeeStructuresQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPlatformFeeStructures>>
>;
export type GetPlatformFeeStructuresQueryError = unknown;

export const useGetPlatformFeeStructures = <
  TData = Awaited<ReturnType<typeof getPlatformFeeStructures>>,
  TError = unknown
>(
  platformProductUid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPlatformFeeStructures>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPlatformFeeStructuresQueryOptions(
    platformProductUid,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};
