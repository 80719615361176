/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Bondsmith Savings Exchange Operation Portal APIs
 * OpenAPI spec version: 0.1
 */

export type ConnectorType = typeof ConnectorType[keyof typeof ConnectorType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ConnectorType = {
  CLEARBANK: 'CLEARBANK',
  HSBC: 'HSBC',
  HSBC_INTL: 'HSBC_INTL',
} as const;
