/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Bondsmith Savings Exchange Operation Portal APIs
 * OpenAPI spec version: 0.1
 */

export type InterestAccrualPeriodType =
  typeof InterestAccrualPeriodType[keyof typeof InterestAccrualPeriodType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InterestAccrualPeriodType = {
  DAILY: 'DAILY',
} as const;
