import { Box, Button, styled, Tooltip, Typography } from '@mui/material';
import React from 'react';
import '@b7hio/core-lib/src/theme';
import { IconName } from '@fortawesome/fontawesome-common-types';
import { useTranslation } from 'next-i18next';

interface TitleProps extends ButtonProps {
  readonly title: string;
  readonly accountNumber?: string;
  readonly sortCode?: string;
}

interface ButtonProps {
  readonly buttonText?: string;
  readonly onClickButton?: () => void;
  readonly buttonIcon?: IconName;
  readonly pendingProducts?: boolean;
  readonly fscsBeneficiariesText?: string;
  readonly fscsBeneficiariesOnClick?: () => void;
}

interface PageHeaderProps extends TitleProps, ButtonProps {
  readonly actionButton?: boolean;
}

interface MultiButtonPageHeaderProps extends TitleProps {
  readonly buttons?: readonly ButtonProps[];
}

export const PageHeader: React.FC<PageHeaderProps> = ({
  actionButton,
  buttonText,
  title,
  onClickButton,
  buttonIcon,
  pendingProducts,
  accountNumber,
  sortCode,
  fscsBeneficiariesText,
  fscsBeneficiariesOnClick,
}) => {
  return (
    <MultiButtonPageHeader
      title={title}
      buttons={
        buttonText
          ? [
              {
                buttonText: buttonText,
                onClickButton: onClickButton,
              },
              pendingProducts
                ? [
                    {
                      buttonText: buttonText,
                      onClickButton: onClickButton,
                      pendingProducts: pendingProducts,
                    },
                  ]
                : [],
              fscsBeneficiariesText
                ? {
                    buttonText: fscsBeneficiariesText,
                    onClickButton: fscsBeneficiariesOnClick,
                  }
                : [],
            ].flat()
          : undefined
      }
      accountNumber={accountNumber}
      sortCode={sortCode}
    />
  );
};

export const MultiButtonPageHeader: React.FC<MultiButtonPageHeaderProps> = ({
  title,
  buttons,
  accountNumber,
  sortCode,
}) => {
  const { t } = useTranslation(['common']);
  return (
    <Container>
      <Typography variant="h4" color="white" gutterBottom>
        {title}
      </Typography>
      <Box sx={{ display: 'flex', gap: 2 }}>
        {buttons ? (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            {buttons.map((v, i) => (
              <Tooltip
                title={
                  v.pendingProducts
                    ? 'Product bulk upload is disabled because some products are pending review'
                    : ''
                }
                key={v.buttonText}>
                <span>
                  <StyledButton
                    color="pageHeader"
                    variant="outlined"
                    disabled={v.pendingProducts}
                    sx={{
                      boxShadow: 1,
                      ':disabled': {
                        boxShadow: 0,
                      },
                    }}
                    onClick={v.onClickButton}>
                    {v.buttonText}
                  </StyledButton>
                </span>
              </Tooltip>
            ))}
          </Box>
        ) : null}
        {accountNumber && sortCode && (
          <Box sx={{ display: 'flex', flexDirection: 'column', p: 1 }}>
            <Typography
              variant="h5"
              color="white"
              fontWeight="bold"
              gutterBottom>
              {t('common:header.virtualAccount')}
            </Typography>
            <Typography variant="h6" color="white">
              {t('common:header.accountNumber') + ': ' + accountNumber}
            </Typography>
            <Typography variant="h6" color="white">
              {t('common:header.sortCode') + ': ' + sortCode}
            </Typography>
          </Box>
        )}
      </Box>
    </Container>
  );
};

const amountToSteal = 7;

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  background: theme.palette.pageHeader.background,
  margin: theme.spacing(-3),
  padding: theme.spacing(3),
  minHeight: theme.spacing(16),
  paddingTop: theme.spacing(8),
  boxShadow: `inset 1px -6px 10px -1px rgb(0 0 0 / 20%)`,
  alignItems: 'center',
  paddingBottom: theme.spacing(amountToSteal + 6),
  marginBottom: `calc(${theme.spacing(amountToSteal * -1)} - 1px)`,

  [theme.breakpoints.up('md')]: {
    paddingTop: theme.spacing(8),
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  ':disabled': {
    color: theme.palette.clientForm.secondary,
    borderColor: theme.palette.clientForm.secondary,
  },
}));
