/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Bondsmith Savings Exchange Operation Portal APIs
 * OpenAPI spec version: 0.1
 */
import { useMutation, useQuery } from 'react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { customInstance } from '../../../../mutator/instance';
import type {
  BondsmithPagePlatformProductListingResponse,
  ListPlatformProductsForBankProductParams,
  PlatformProductDefaultsRequest,
  PlatformProductDefaultsResponse,
  PlatformProductGrantAccessRequest,
  PlatformProductGrantAccessWithConfigRequest,
  PlatformProductWithRatesResponse,
} from '../../models';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

export const grantAccess = (
  platformProductGrantAccessRequest: PlatformProductGrantAccessRequest
) => {
  return customInstance<string>({
    url: `/operations/own/access`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: platformProductGrantAccessRequest,
  });
};

export const getGrantAccessMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof grantAccess>>,
    TError,
    { data: PlatformProductGrantAccessRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof grantAccess>>,
  TError,
  { data: PlatformProductGrantAccessRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof grantAccess>>,
    { data: PlatformProductGrantAccessRequest }
  > = (props) => {
    const { data } = props ?? {};

    return grantAccess(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type GrantAccessMutationResult = NonNullable<
  Awaited<ReturnType<typeof grantAccess>>
>;
export type GrantAccessMutationBody = PlatformProductGrantAccessRequest;
export type GrantAccessMutationError = unknown;

export const useGrantAccess = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof grantAccess>>,
    TError,
    { data: PlatformProductGrantAccessRequest },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof grantAccess>>,
  TError,
  { data: PlatformProductGrantAccessRequest },
  TContext
> => {
  const mutationOptions = getGrantAccessMutationOptions(options);

  return useMutation(mutationOptions);
};
export const grantAccessWithConfig = (
  platformProductGrantAccessWithConfigRequest: PlatformProductGrantAccessWithConfigRequest
) => {
  return customInstance<string>({
    url: `/operations/own/access/access-and-config`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: platformProductGrantAccessWithConfigRequest,
  });
};

export const getGrantAccessWithConfigMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof grantAccessWithConfig>>,
    TError,
    { data: PlatformProductGrantAccessWithConfigRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof grantAccessWithConfig>>,
  TError,
  { data: PlatformProductGrantAccessWithConfigRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof grantAccessWithConfig>>,
    { data: PlatformProductGrantAccessWithConfigRequest }
  > = (props) => {
    const { data } = props ?? {};

    return grantAccessWithConfig(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type GrantAccessWithConfigMutationResult = NonNullable<
  Awaited<ReturnType<typeof grantAccessWithConfig>>
>;
export type GrantAccessWithConfigMutationBody =
  PlatformProductGrantAccessWithConfigRequest;
export type GrantAccessWithConfigMutationError = unknown;

export const useGrantAccessWithConfig = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof grantAccessWithConfig>>,
    TError,
    { data: PlatformProductGrantAccessWithConfigRequest },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof grantAccessWithConfig>>,
  TError,
  { data: PlatformProductGrantAccessWithConfigRequest },
  TContext
> => {
  const mutationOptions = getGrantAccessWithConfigMutationOptions(options);

  return useMutation(mutationOptions);
};
export const findDefaultsForPlatformProduct = (
  platformUid: string,
  bankUid: string,
  signal?: AbortSignal
) => {
  return customInstance<PlatformProductDefaultsResponse>({
    url: `/operations/own/access/defaults/platform/${platformUid}/bank/${bankUid}`,
    method: 'GET',
    signal,
  });
};

export const getFindDefaultsForPlatformProductQueryKey = (
  platformUid: string,
  bankUid: string
) => {
  return [
    `/operations/own/access/defaults/platform/${platformUid}/bank/${bankUid}`,
  ] as const;
};

export const getFindDefaultsForPlatformProductQueryOptions = <
  TData = Awaited<ReturnType<typeof findDefaultsForPlatformProduct>>,
  TError = unknown
>(
  platformUid: string,
  bankUid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof findDefaultsForPlatformProduct>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getFindDefaultsForPlatformProductQueryKey(platformUid, bankUid);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof findDefaultsForPlatformProduct>>
  > = ({ signal }) =>
    findDefaultsForPlatformProduct(platformUid, bankUid, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!(platformUid && bankUid),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof findDefaultsForPlatformProduct>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type FindDefaultsForPlatformProductQueryResult = NonNullable<
  Awaited<ReturnType<typeof findDefaultsForPlatformProduct>>
>;
export type FindDefaultsForPlatformProductQueryError = unknown;

export const useFindDefaultsForPlatformProduct = <
  TData = Awaited<ReturnType<typeof findDefaultsForPlatformProduct>>,
  TError = unknown
>(
  platformUid: string,
  bankUid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof findDefaultsForPlatformProduct>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getFindDefaultsForPlatformProductQueryOptions(
    platformUid,
    bankUid,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const upsertDefaultsForPlatformProduct = (
  platformUid: string,
  bankUid: string,
  platformProductDefaultsRequest: PlatformProductDefaultsRequest
) => {
  return customInstance<void>({
    url: `/operations/own/access/defaults/platform/${platformUid}/bank/${bankUid}`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: platformProductDefaultsRequest,
  });
};

export const getUpsertDefaultsForPlatformProductMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof upsertDefaultsForPlatformProduct>>,
    TError,
    {
      platformUid: string;
      bankUid: string;
      data: PlatformProductDefaultsRequest;
    },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof upsertDefaultsForPlatformProduct>>,
  TError,
  {
    platformUid: string;
    bankUid: string;
    data: PlatformProductDefaultsRequest;
  },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof upsertDefaultsForPlatformProduct>>,
    {
      platformUid: string;
      bankUid: string;
      data: PlatformProductDefaultsRequest;
    }
  > = (props) => {
    const { platformUid, bankUid, data } = props ?? {};

    return upsertDefaultsForPlatformProduct(platformUid, bankUid, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpsertDefaultsForPlatformProductMutationResult = NonNullable<
  Awaited<ReturnType<typeof upsertDefaultsForPlatformProduct>>
>;
export type UpsertDefaultsForPlatformProductMutationBody =
  PlatformProductDefaultsRequest;
export type UpsertDefaultsForPlatformProductMutationError = unknown;

export const useUpsertDefaultsForPlatformProduct = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof upsertDefaultsForPlatformProduct>>,
    TError,
    {
      platformUid: string;
      bankUid: string;
      data: PlatformProductDefaultsRequest;
    },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof upsertDefaultsForPlatformProduct>>,
  TError,
  {
    platformUid: string;
    bankUid: string;
    data: PlatformProductDefaultsRequest;
  },
  TContext
> => {
  const mutationOptions =
    getUpsertDefaultsForPlatformProductMutationOptions(options);

  return useMutation(mutationOptions);
};
export const listPlatformProductsForProduct = (
  productUid: string,
  signal?: AbortSignal
) => {
  return customInstance<PlatformProductWithRatesResponse[]>({
    url: `/operations/own/access/platform-products-with-rates/${productUid}`,
    method: 'GET',
    signal,
  });
};

export const getListPlatformProductsForProductQueryKey = (
  productUid: string
) => {
  return [
    `/operations/own/access/platform-products-with-rates/${productUid}`,
  ] as const;
};

export const getListPlatformProductsForProductQueryOptions = <
  TData = Awaited<ReturnType<typeof listPlatformProductsForProduct>>,
  TError = unknown
>(
  productUid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof listPlatformProductsForProduct>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getListPlatformProductsForProductQueryKey(productUid);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof listPlatformProductsForProduct>>
  > = ({ signal }) => listPlatformProductsForProduct(productUid, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!productUid,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof listPlatformProductsForProduct>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListPlatformProductsForProductQueryResult = NonNullable<
  Awaited<ReturnType<typeof listPlatformProductsForProduct>>
>;
export type ListPlatformProductsForProductQueryError = unknown;

export const useListPlatformProductsForProduct = <
  TData = Awaited<ReturnType<typeof listPlatformProductsForProduct>>,
  TError = unknown
>(
  productUid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof listPlatformProductsForProduct>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListPlatformProductsForProductQueryOptions(
    productUid,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const listPlatformProductsForBankProduct = (
  params?: ListPlatformProductsForBankProductParams,
  signal?: AbortSignal
) => {
  return customInstance<BondsmithPagePlatformProductListingResponse>({
    url: `/operations/own/access/platform/product`,
    method: 'GET',
    params,
    signal,
  });
};

export const getListPlatformProductsForBankProductQueryKey = (
  params?: ListPlatformProductsForBankProductParams
) => {
  return [
    `/operations/own/access/platform/product`,
    ...(params ? [params] : []),
  ] as const;
};

export const getListPlatformProductsForBankProductQueryOptions = <
  TData = Awaited<ReturnType<typeof listPlatformProductsForBankProduct>>,
  TError = unknown
>(
  params?: ListPlatformProductsForBankProductParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof listPlatformProductsForBankProduct>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getListPlatformProductsForBankProductQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof listPlatformProductsForBankProduct>>
  > = ({ signal }) => listPlatformProductsForBankProduct(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof listPlatformProductsForBankProduct>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListPlatformProductsForBankProductQueryResult = NonNullable<
  Awaited<ReturnType<typeof listPlatformProductsForBankProduct>>
>;
export type ListPlatformProductsForBankProductQueryError = unknown;

export const useListPlatformProductsForBankProduct = <
  TData = Awaited<ReturnType<typeof listPlatformProductsForBankProduct>>,
  TError = unknown
>(
  params?: ListPlatformProductsForBankProductParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof listPlatformProductsForBankProduct>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListPlatformProductsForBankProductQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const revokeAccess = (
  platformUid: string,
  platformProductUid: string
) => {
  return customInstance<void>({
    url: `/operations/own/access/platform/${platformUid}/platform-product/${platformProductUid}`,
    method: 'DELETE',
  });
};

export const getRevokeAccessMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof revokeAccess>>,
    TError,
    { platformUid: string; platformProductUid: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof revokeAccess>>,
  TError,
  { platformUid: string; platformProductUid: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof revokeAccess>>,
    { platformUid: string; platformProductUid: string }
  > = (props) => {
    const { platformUid, platformProductUid } = props ?? {};

    return revokeAccess(platformUid, platformProductUid);
  };

  return { mutationFn, ...mutationOptions };
};

export type RevokeAccessMutationResult = NonNullable<
  Awaited<ReturnType<typeof revokeAccess>>
>;

export type RevokeAccessMutationError = unknown;

export const useRevokeAccess = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof revokeAccess>>,
    TError,
    { platformUid: string; platformProductUid: string },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof revokeAccess>>,
  TError,
  { platformUid: string; platformProductUid: string },
  TContext
> => {
  const mutationOptions = getRevokeAccessMutationOptions(options);

  return useMutation(mutationOptions);
};
