import {
  PortalUserResponse,
  PortalUserType,
} from '@b7hio/api-lib/src/ops-portal';
import { EventContextProvider, PortalType } from '@b7hio/core-lib/src';
import DeleteRounded from '@mui/icons-material/DeleteRounded'
import EditRounded from '@mui/icons-material/EditRounded';
import { Box, styled, Tooltip } from '@mui/material';
import type { GridColDef, GridRowParams } from '@mui/x-data-grid-pro';
import {
  DataGridPro as MuiDataGrid,
  GridActionsCellItem,
} from '@mui/x-data-grid-pro';
import { absurd } from 'fp-ts/function';
import { useTranslation } from 'next-i18next';
import React, { useEffect, useMemo } from 'react';
import { Subject } from 'rxjs';
import { UserListEvents } from './UserList.events';

interface Props {
  readonly data: readonly PortalUserResponse[];
  readonly isLoading: boolean;
  readonly onRequestUpdate: (user: PortalUserResponse) => void;
  readonly onRequestDelete: (user: PortalUserResponse) => void;
  readonly portal: PortalType;
}

export const UserList: React.FC<Props> = ({
  isLoading,
  data,
  onRequestDelete,
  onRequestUpdate,
  portal,
}) => {
  const subject = useMemo(() => new Subject<UserListEvents>(), []);
  const opsColumns = useUserOpsColumns(subject);
  const banksColumns = useUserBanksColumns(subject);
  const rowData = data || [];

  useEffect(() => {
    const subscription = subject.subscribe((action) => {
      switch (action.action) {
        case 'update':
          onRequestUpdate(action.payload);
          break;
        case 'delete':
          onRequestDelete(action.payload);
          break;
        default:
          absurd(action.action);
          break;
      }
    });

    return () => subscription.unsubscribe();
  }, [subject]);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <EventContextProvider subject={subject}>
        <DataGrid
          getRowId={(row) => row.uid}
          pagination
          columns={portal === 'ops' ? opsColumns : banksColumns}
          loading={isLoading}
          rows={rowData}
          disableColumnMenu
          disableSelectionOnClick
        />
      </EventContextProvider>
    </Box>
  );
};

function useUserOpsColumns(subject: Subject<UserListEvents>): GridColDef[] {
  const { t } = useTranslation(['users']);
  return useMemo(
    () => [
      {
        field: 'email',
        headerName: t('users:columns.email'),
        flex: 1,
      },
      {
        field: 'givenName',
        headerName: t('users:columns.givenName'),
        flex: 1,
      },
      {
        field: 'familyName',
        headerName: t('users:columns.familyName'),
        flex: 1,
      },
      {
        field: 'userType',
        headerName: t('users:columns.userType'),
        flex: 1,
        valueFormatter: ({ value }) => t(`users:userTypes.${value}` as any),
      },
      {
        field: 'partnerName',
        headerName: t('users:columns.partnerUid'),
        flex: 1,
      },
      {
        field: 'userRoles',
        headerName: t('users:columns.userRoles'),
        flex: 1,
        valueFormatter: ({ api, id }) => {
          const row = id ? api.getRow(id) : [];
          return (row ?? []).userRoles.join(', ');
        },
      },
      {
        field: 'actions',
        type: 'actions',
        headerName: t('users:columns.actions'),
        flex: 1,
        getActions: (params: GridRowParams) => [
          <Tooltip title={t('users:actions.update') as string}>
            <GridActionsCellItem
              test-id="Update"
              icon={<EditRounded />}
              onClick={() =>
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                subject.next({ action: 'update', payload: params.row as any })
              }
              label={t('users:actions.update')}
            />
          </Tooltip>,
          <GridActionsCellItem
            showInMenu
            icon={<DeleteRounded />}
            onClick={() =>
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              subject.next({ action: 'delete', payload: params.row as any })
            }
            label={t('users:actions.delete')}
          />,
        ],
      },
    ],
    []
  );
}

function useUserBanksColumns(subject: Subject<UserListEvents>): GridColDef[] {
  const { t } = useTranslation(['users']);
  return useMemo(
    () => [
      {
        field: 'email',
        headerName: t('users:columns.email'),
        flex: 1,
      },
      {
        field: 'givenName',
        headerName: t('users:columns.givenName'),
        flex: 1,
      },
      {
        field: 'familyName',
        headerName: t('users:columns.familyName'),
        flex: 1,
      },
      // These columns are hidden for now while roles are irrelevant and lastLogin functionality hasn't been implemented
      // {
      //   field: 'userRoles',
      //   headerName: t('users:columns.userRoles'),
      //   flex: 1,
      //   valueFormatter: ({ api, id }) => {
      //     const row = id ? api.getRow(id) : [];
      //     return (row ?? []).userRoles.join(', ');
      //   },
      // },
      // {
      //   // TODO: add lastLoginDate prop to types
      //   field: ' ',
      //   headerName: t('users:columns.lastLogin'),
      //   flex: 1,
      // },
      {
        field: 'actions',
        type: 'actions',
        headerName: t('users:columns.actions'),
        flex: 1,
        getActions: (params: GridRowParams) => [
          <Tooltip title={t('users:actions.update') as string}>
            <GridActionsCellItem
              test-id="Update"
              icon={<EditRounded />}
              onClick={() =>
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                subject.next({ action: 'update', payload: params.row as any })
              }
              label={t('users:actions.update')}
            />
          </Tooltip>,
          <GridActionsCellItem
            showInMenu
            icon={<DeleteRounded />}
            onClick={() =>
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              subject.next({ action: 'delete', payload: params.row as any })
            }
            label={t('users:actions.delete')}
          />,
        ],
      },
    ],
    []
  );
}
const DataGrid = styled(MuiDataGrid)(({ theme }) => ({
  background: theme.palette.background.paper,
  boxShadow: theme.shadows[1],
}));
