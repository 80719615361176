import { PortalUserResponse } from '@b7hio/api-lib/src/bank-portal';
import {
  PartnerType,
  PortalUserCreateRequest,
  PortalUserType,
  PortalUserUpdateRequest,
  UserRole,
} from '@b7hio/api-lib/src/ops-portal';
import {
  FormAutocomplete,
  FormTextField,
  PortalType,
} from '@b7hio/core-lib/src';
import { FormFriendly } from '@b7hio/core-lib/src/form';
import { Button } from '@mui/material';
import { styled } from '@mui/system';
import React, { useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormBankList, FormPlatformList } from '../../../../components';

interface Props {
  readonly isUpdating?: boolean;
  readonly portal: PortalType;
}

type RolesType = {
  [K in PortalUserType]: UserRole[];
};

const roles: RolesType = {
  [PortalUserType.BANK]: [
    UserRole.BANK_ADMIN,
    UserRole.BANK_INPUTTER,
    UserRole.BANK_AUTHORISER,
    UserRole.BANK_READ_ONLY,
  ],
  [PortalUserType.OPERATIONS]: [
    UserRole.BONDSMITH_OPS_USER,
    UserRole.BONDSMITH_COMPLIANCE_USER,
    UserRole.BONDSMITH_OPS_READ_ONLY,
    UserRole.BONDSMITH_SUPER_ADMIN,
  ],
  [PortalUserType.DISTRIBUTOR]: [
    UserRole.DISTRIBUTOR_PORTAL_USER,
    UserRole.DISTRIBUTOR_PORTAL_ADMIN,
    UserRole.DISTRIBUTOR_PORTAL_COMPLIANCE,
  ],
};

export const UserForm = ({ isUpdating, portal }: Props): JSX.Element => {
  const {
    control,
    watch,
    setValue,
    formState: { isSubmitting },
  } = useFormContext<
    FormFriendly<
      PortalUserCreateRequest | PortalUserUpdateRequest | PortalUserResponse
    >
  >();
  const { t } = useTranslation(['common', 'users']);
  const didMountRef = useRef(false);
  const userType = watch('userType') as PortalUserType | undefined;

  function isPortalUserType(value: unknown): value is PortalUserType {
    return (
      typeof value === 'string' &&
      Object.values(PortalUserType).includes(value as PortalUserType)
    );
  }

  const userTypes = Object.values(PortalUserType).map((type) => ({
    value: type,
    label: t(`common:portalUserType.${type}`),
  }));

  const userRoles = Object.values(UserRole).map((role) => ({
    value: role,
    label: t(`common:userRole.${role}`),
  }));

  useEffect(() => {
    if (!didMountRef.current) {
      didMountRef.current = true;
      return;
    }

    setValue('userRoles', []);
    setValue('partnerUid', '');
  }, [userType]);

  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (isSubmitting) {
      setSubmitting(true);
    } else {
      if (submitting) {
        setSubmitting(false);
        setValue('userRoles', []);
        setValue('userType', '');
        setValue('email', '');
        setValue('partnerUid', '');
        setValue('givenName', '');
        setValue('familyName', '');
      }
    }
  }, [isSubmitting]);

  return (
    <FormContainer>
      <FormTextField
        name="email"
        t={t}
        label={t('users:user.email')}
        control={control}
      />
      <FormTextField
        name="givenName"
        t={t}
        label={t('users:user.firstName')}
        control={control}
      />
      <FormTextField
        name="familyName"
        t={t}
        label={t('users:user.lastName')}
        control={control}
      />
      {portal === 'ops' && (
        <>
          <FormAutocomplete
            name="userType"
            data-testid="userType"
            label={t('users:user.userType')}
            options={userTypes}
            t={t}
            control={control}
          />
          {userType === PortalUserType.DISTRIBUTOR && (
            <FormPlatformList
              name="partnerUid"
              t={t}
              control={control}
              label={t('users:user.partnerUid')}
            />
          )}
          {userType === PartnerType.BANK && (
            <FormBankList
              name="partnerUid"
              data-testid="partnerUid"
              t={t}
              control={control}
              label={t('users:user.partnerUid')}
            />
          )}
          <FormAutocomplete
            name="userRoles"
            label={t('users:user.userRoles')}
            data-testid="userRoles"
            options={userRoles}
            multiple
            t={t}
            control={control}
            disabled={!userType}
            filterSelectedOptions
            filterOptions={(options) => {
              if (isPortalUserType(userType)) {
                return options.filter((option) =>
                  roles[userType].includes(option.value as UserRole)
                );
              }
              return options;
            }}
          />
        </>
      )}
      <Button
        sx={{ mt: 2, mb: 2 }}
        color="primary"
        variant="contained"
        type="submit"
        disabled={isSubmitting}>
        {t(isUpdating ? 'users:updateUser' : 'users:createUser')}
      </Button>
    </FormContainer>
  );
};

const FormContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  '> div': {
    marginBottom: theme.spacing(2),

    '&:last-of-type': {
      marginBottom: 0,
    },
  },
}));
