/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Bondsmith Savings Exchange Bank Portal APIs
 * OpenAPI spec version: 0.1
 */
import { useQuery } from 'react-query';
import type {
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { customInstance } from '../../../../mutator/instance';
import type {
  BankStatisticsResponse,
  InsuredBalanceStatisticsResponse,
  ProductDistributionResponse,
} from '../../models';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

export const getBankStatistics = (signal?: AbortSignal) => {
  return customInstance<BankStatisticsResponse>({
    url: `/banks/statistics`,
    method: 'GET',
    signal,
  });
};

export const getGetBankStatisticsQueryKey = () => {
  return [`/banks/statistics`] as const;
};

export const getGetBankStatisticsQueryOptions = <
  TData = Awaited<ReturnType<typeof getBankStatistics>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getBankStatistics>>,
    TError,
    TData
  >;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetBankStatisticsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getBankStatistics>>> =
    ({ signal }) => getBankStatistics(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getBankStatistics>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetBankStatisticsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getBankStatistics>>
>;
export type GetBankStatisticsQueryError = unknown;

export const useGetBankStatistics = <
  TData = Awaited<ReturnType<typeof getBankStatistics>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getBankStatistics>>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetBankStatisticsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getBankProductDistributions = (signal?: AbortSignal) => {
  return customInstance<ProductDistributionResponse[]>({
    url: `/banks/statistics/chart/product/statistics`,
    method: 'GET',
    signal,
  });
};

export const getGetBankProductDistributionsQueryKey = () => {
  return [`/banks/statistics/chart/product/statistics`] as const;
};

export const getGetBankProductDistributionsQueryOptions = <
  TData = Awaited<ReturnType<typeof getBankProductDistributions>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getBankProductDistributions>>,
    TError,
    TData
  >;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetBankProductDistributionsQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getBankProductDistributions>>
  > = ({ signal }) => getBankProductDistributions(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getBankProductDistributions>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetBankProductDistributionsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getBankProductDistributions>>
>;
export type GetBankProductDistributionsQueryError = unknown;

export const useGetBankProductDistributions = <
  TData = Awaited<ReturnType<typeof getBankProductDistributions>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getBankProductDistributions>>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetBankProductDistributionsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getInsuredBalanceStatistics = (signal?: AbortSignal) => {
  return customInstance<InsuredBalanceStatisticsResponse>({
    url: `/banks/statistics/insured-balance-statistics`,
    method: 'GET',
    signal,
  });
};

export const getGetInsuredBalanceStatisticsQueryKey = () => {
  return [`/banks/statistics/insured-balance-statistics`] as const;
};

export const getGetInsuredBalanceStatisticsQueryOptions = <
  TData = Awaited<ReturnType<typeof getInsuredBalanceStatistics>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getInsuredBalanceStatistics>>,
    TError,
    TData
  >;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetInsuredBalanceStatisticsQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getInsuredBalanceStatistics>>
  > = ({ signal }) => getInsuredBalanceStatistics(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getInsuredBalanceStatistics>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetInsuredBalanceStatisticsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getInsuredBalanceStatistics>>
>;
export type GetInsuredBalanceStatisticsQueryError = unknown;

export const useGetInsuredBalanceStatistics = <
  TData = Awaited<ReturnType<typeof getInsuredBalanceStatistics>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getInsuredBalanceStatistics>>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetInsuredBalanceStatisticsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};
