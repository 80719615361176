import { Storage } from 'aws-amplify';
import { PartnerBankFscsBrochureRequest } from '@b7hio/api-lib/src/bank-portal';

export interface FscsDocument {
  readonly fscsFile: File | undefined;
}

export async function uploadToS3(
  bankId: string,
  file: FscsDocument,
  randomId: string
) {
  if (file.fscsFile != null) {
    await Storage.put(
      getKeyPrefix(bankId, randomId) + '/' + file.fscsFile.name,
      file.fscsFile,
      getOptions(file.fscsFile)
    );
  }
}

export function getFscsRequest(
  bankId: string,
  file: FscsDocument,
  randomId: string
): PartnerBankFscsBrochureRequest {
  const assetsUrl = process.env.NEXT_PUBLIC_RESOURCE_URL || '';
  const bucketAndKeyPrefix = `${assetsUrl}/portal/${getKeyPrefix(bankId, randomId)}`;
  return {
    fscsBrochureUrl: file.fscsFile
      ? encodeURI(`${bucketAndKeyPrefix}/${file.fscsFile.name}`)
      : null,
  } as PartnerBankFscsBrochureRequest;
}

function getKeyPrefix(bankUid: string, randomId: string): string {
  return 'bank/' + bankUid + '/product-documents/' + randomId;
}

function getOptions(file: File) {
  return {
    acl: 'public-read',
    contentType: file.type,
    contentDisposition: 'attachment',
  };
}
