import { BankProductResponse as BankPortalBankProductResponse } from '@b7hio/api-lib/src/bank-portal';
import { showErrors } from '@b7hio/core-lib/src/utils';
import {
  BankProductRequestWithRateAndAccountDetails,
  BankProductResponse,
  BankProductUpdateWithAccountDetailRequest,
  ProductLiteratureRequest,
  RateDetailUpdateRequest,
  useProposeProduct,
  useProposeUpdateProduct,
  useProposeUpdateRate,
} from '@b7hio/api-lib/src/ops-portal';
import { useSnackbar } from '@b7hio/core-lib/src';
import { Storage } from 'aws-amplify';
import { useTranslation } from 'next-i18next';
import { v4 as uuidV4 } from 'uuid';

export interface ProductDocuments {
  readonly tandcUrl: File | undefined;
  readonly brochureUrl: File | undefined;
}

export function useProductInteractivity(
  onFinish: () => Promise<void>,
  target?: BankProductResponse | BankPortalBankProductResponse
) {
  const { t } = useTranslation(['common', 'shell', 'products']);
  const { mutateAsync: proposeProduct } = useProposeProduct();
  const { mutateAsync: proposeUpdateProduct } = useProposeUpdateProduct();
  const { mutateAsync: proposeUpdateRate } = useProposeUpdateRate();

  const { enqueueSnackbar } = useSnackbar();

  const handleCreate = async (
    values: BankProductRequestWithRateAndAccountDetails,
    files: ProductDocuments
  ) => {
    let productAdded = false;
    const randomId = uuidV4();

    try {
      await proposeProduct({
        bankUid: values.bankUid,
        data: {
          externalId: values.externalId,
          name: values.name,
          bankUid: values.bankUid,
          productType: values.productType,
          exoticType: values.exoticType,
          currency: values.currency,
          accountHolderTypes: values.accountHolderTypes,
          depositRequirement: {
            min: values.depositRequirement.min,
            max: values.depositRequirement.max,
          },
          interestFeature: {
            payoutPeriod: values.interestFeature.payoutPeriod,
            interestCutOffTime: values.interestFeature.interestCutOffTime,
          },
          periodFeature: {
            noticePeriod: values.periodFeature.noticePeriod,
            termPeriod: values.periodFeature.termPeriod,
            coolOffPeriod: values.periodFeature.coolOffPeriod,
          },
          productLiterature: getProductLiteratureRequest(
            files,
            values.bankUid,
            randomId
          ),
          accountDetails: values.accountDetails,
          holidayCalendar: values.holidayCalendar,
          productAvailability: {
            availableFrom: values.productAvailability.availableFrom,
            availableUntil: values.productAvailability.availableUntil,
          },
          rateDetail: {
            grossRate: parseFloat(values.rateDetail.grossRate.toFixed(4)),
            startDate:
              values.startDate || values.productAvailability.availableFrom,
          },
          taxWrappers: values.taxWrappers,
          maximumAvailable: values.maximumAvailable,
          startDate: values.startDate,
          stopDisplayAt: values.stopDisplayAt,
          isCompositeProduct: values.isCompositeProduct
            ? JSON.parse(values.isCompositeProduct.toString())
            : false,
          isTracker: values.isTracker
            ? JSON.parse(values.isTracker.toString())
            : false,
        },
      });
      productAdded = true;

      await onFinish();

      enqueueSnackbar(t('products:feedback.addProductSuccess'), 'success');
    } catch (error) {
      showErrors(
        error,
        enqueueSnackbar,
        t('products:feedback.addProductFailed')
      );
    }
    if (productAdded) {
      await uploadDocumentsToS3(values.bankUid, files, randomId);
    }
  };

  const handleProposeProductUpdate = async (
    values: BankProductUpdateWithAccountDetailRequest,
    files: ProductDocuments
  ) => {
    if (!target) {
      return;
    }

    let productUpdated = false;
    const randomId = uuidV4();

    try {
      const productLiterature = getProductLiteratureRequest(
        files,
        target.bankUid,
        randomId
      );
      await proposeUpdateProduct({
        productUid: target.productUid,
        bankUid: target.bankUid,
        data: {
          externalId: values.externalId,
          name: values.name,
          productType: values.productType,
          exoticType: values.exoticType,
          currency: values.currency,
          accountHolderTypes: values.accountHolderTypes,
          depositRequirement:
            values.depositRequirement?.min && values.depositRequirement?.max
              ? {
                  min: values.depositRequirement.min,
                  max: values.depositRequirement.max,
                }
              : undefined,
          interestFeature:
            values.interestFeature?.payoutPeriod &&
            values.interestFeature?.interestCutOffTime
              ? {
                  payoutPeriod: values.interestFeature.payoutPeriod,
                  interestCutOffTime: values.interestFeature.interestCutOffTime,
                }
              : undefined,
          periodFeature:
            values.periodFeature?.noticePeriod &&
            values.periodFeature?.termPeriod &&
            values.periodFeature?.coolOffPeriod
              ? {
                  noticePeriod: values.periodFeature.noticePeriod,
                  termPeriod: values.periodFeature.termPeriod,
                  coolOffPeriod: values.periodFeature.coolOffPeriod,
                }
              : undefined,
          accountDetails:
            values.accountDetails?.sortCode &&
            values.accountDetails?.accountNumber
              ? {
                  sortCode: values.accountDetails.sortCode,
                  accountNumber: values.accountDetails.accountNumber,
                }
              : undefined,
          holidayCalendar: values.holidayCalendar,
          productAvailability:
            values.productAvailability?.availableFrom &&
            values.productAvailability?.availableUntil
              ? {
                  availableFrom: values.productAvailability.availableFrom,
                  availableUntil: values.productAvailability.availableUntil,
                }
              : undefined,
          maximumAvailable: values.maximumAvailable,
          startDate: values.startDate,
          changeEffectiveFrom: values.changeEffectiveFrom,
          stopDisplayAt: values.stopDisplayAt,
          taxWrappers: values.taxWrappers,
          productLiterature: {
            tandcUrl: productLiterature?.tandcUrl ?? undefined,
            brochureUrl: productLiterature?.brochureUrl ?? undefined,
          },
          isTracker: values.isTracker
            ? JSON.parse(values.isTracker.toString())
            : false,
        },
      });
      productUpdated = true;
      await onFinish();
      enqueueSnackbar(t('products:feedback.updateProductSuccess'), 'success');
    } catch (error) {
      showErrors(
        error,
        enqueueSnackbar,
        t('products:feedback.updateProductFailed')
      );
    }

    if (productUpdated) {
      await uploadDocumentsToS3(target.bankUid, files, randomId);
    }
  };

  const handleProposeUpdateRate = async (values: RateDetailUpdateRequest) => {
    if (!target) {
      return;
    }
    const formattedData = {
      grossRate: parseFloat(values.grossRate.toFixed(4)),
      announcedAt: values.announcedAt,
      startDate: values.startDate,
    };

    try {
      await proposeUpdateRate({
        bankUid: target.bankUid,
        productUid: target.productUid,
        data: formattedData,
      });
      await onFinish();
      enqueueSnackbar(
        t('products:feedback.updateProductRateSuccess'),
        'success'
      );
    } catch (error) {
      showErrors(
        error,
        enqueueSnackbar,
        t('products:feedback.updateProductRateFailed')
      );
    }
  };

  return {
    handleCreate,
    handleProposeProductUpdate,
    handleProposeUpdateRate,
  };
}

export async function uploadDocumentsToS3(
  bankUid: string,
  files: ProductDocuments,
  randomId: string
) {
  if (files.tandcUrl) {
    try {
      await Storage.put(
        getKeyPrefix(bankUid, randomId) + '/' + files.tandcUrl.name,
        files.tandcUrl,
        getOptions(files.tandcUrl)
      );
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(`Error uploading file ${files.tandcUrl.name}:${error}`);
    }
  }
  if (files.brochureUrl) {
    try {
      await Storage.put(
        getKeyPrefix(bankUid, randomId) + '/' + files.brochureUrl.name,
        files.brochureUrl,
        getOptions(files.brochureUrl)
      );
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(
        'Error uploading file ',
        files.brochureUrl.name + ': ' + error
      );
    }
  }
}

export function getProductLiteratureRequest(
  files: ProductDocuments,
  bankUid: string,
  randomId: string
): ProductLiteratureRequest {
  const assetsUrl = process.env.NEXT_PUBLIC_RESOURCE_URL || '';
  const bucketAndKeyPrefix = `${assetsUrl}/portal/${getKeyPrefix(
    bankUid,
    randomId
  )}`;
  return {
    tandcUrl: files.tandcUrl
      ? encodeURI(`${bucketAndKeyPrefix}/${files.tandcUrl.name}`)
      : null,
    brochureUrl: files.brochureUrl
      ? encodeURI(`${bucketAndKeyPrefix}/${files.brochureUrl.name}`)
      : null,
  } as ProductLiteratureRequest;
}

function getKeyPrefix(bankUid: string, randomId: string): string {
  return 'bank/' + bankUid + '/product-documents/' + randomId;
}

function getOptions(file: File) {
  return {
    acl: 'public-read',
    contentType: file.type,
    contentDisposition: 'attachment',
  };
}
