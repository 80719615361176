/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Bondsmith Savings Exchange Operation Portal APIs
 * OpenAPI spec version: 0.1
 */

export type FeeType = typeof FeeType[keyof typeof FeeType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FeeType = {
  INTEREST: 'INTEREST',
  OFF_PLATFORM: 'OFF_PLATFORM',
  CUSTOMER: 'CUSTOMER',
} as const;
