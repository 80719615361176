/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Bondsmith Savings Exchange Operation Portal APIs
 * OpenAPI spec version: 0.1
 */
import { useMutation, useQuery } from 'react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { customInstance } from '../../../../mutator/instance';
import type {
  BondsmithPageBondsmithFeeResponse,
  CurrentBondsmithFeeResponse,
  GetBondsmithProductAccountsByParams,
  UpdateFeeDetailRequest,
} from '../../models';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

export const getBondsmithProductAccountsBy = (
  params?: GetBondsmithProductAccountsByParams,
  signal?: AbortSignal
) => {
  return customInstance<BondsmithPageBondsmithFeeResponse>({
    url: `/operations/fee`,
    method: 'GET',
    params,
    signal,
  });
};

export const getGetBondsmithProductAccountsByQueryKey = (
  params?: GetBondsmithProductAccountsByParams
) => {
  return [`/operations/fee`, ...(params ? [params] : [])] as const;
};

export const getGetBondsmithProductAccountsByQueryOptions = <
  TData = Awaited<ReturnType<typeof getBondsmithProductAccountsBy>>,
  TError = unknown
>(
  params?: GetBondsmithProductAccountsByParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getBondsmithProductAccountsBy>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetBondsmithProductAccountsByQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getBondsmithProductAccountsBy>>
  > = ({ signal }) => getBondsmithProductAccountsBy(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getBondsmithProductAccountsBy>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetBondsmithProductAccountsByQueryResult = NonNullable<
  Awaited<ReturnType<typeof getBondsmithProductAccountsBy>>
>;
export type GetBondsmithProductAccountsByQueryError = unknown;

export const useGetBondsmithProductAccountsBy = <
  TData = Awaited<ReturnType<typeof getBondsmithProductAccountsBy>>,
  TError = unknown
>(
  params?: GetBondsmithProductAccountsByParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getBondsmithProductAccountsBy>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetBondsmithProductAccountsByQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const updateFee = (updateFeeDetailRequest: UpdateFeeDetailRequest) => {
  return customInstance<void>({
    url: `/operations/fee`,
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    data: updateFeeDetailRequest,
  });
};

export const getUpdateFeeMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateFee>>,
    TError,
    { data: UpdateFeeDetailRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateFee>>,
  TError,
  { data: UpdateFeeDetailRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateFee>>,
    { data: UpdateFeeDetailRequest }
  > = (props) => {
    const { data } = props ?? {};

    return updateFee(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateFeeMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateFee>>
>;
export type UpdateFeeMutationBody = UpdateFeeDetailRequest;
export type UpdateFeeMutationError = unknown;

export const useUpdateFee = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateFee>>,
    TError,
    { data: UpdateFeeDetailRequest },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateFee>>,
  TError,
  { data: UpdateFeeDetailRequest },
  TContext
> => {
  const mutationOptions = getUpdateFeeMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getCurrentFee = (
  platformProductUid: string,
  signal?: AbortSignal
) => {
  return customInstance<CurrentBondsmithFeeResponse>({
    url: `/operations/fee/platform-product/${platformProductUid}`,
    method: 'GET',
    signal,
  });
};

export const getGetCurrentFeeQueryKey = (platformProductUid: string) => {
  return [`/operations/fee/platform-product/${platformProductUid}`] as const;
};

export const getGetCurrentFeeQueryOptions = <
  TData = Awaited<ReturnType<typeof getCurrentFee>>,
  TError = unknown
>(
  platformProductUid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getCurrentFee>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetCurrentFeeQueryKey(platformProductUid);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCurrentFee>>> = ({
    signal,
  }) => getCurrentFee(platformProductUid, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!platformProductUid,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getCurrentFee>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCurrentFeeQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCurrentFee>>
>;
export type GetCurrentFeeQueryError = unknown;

export const useGetCurrentFee = <
  TData = Awaited<ReturnType<typeof getCurrentFee>>,
  TError = unknown
>(
  platformProductUid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getCurrentFee>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCurrentFeeQueryOptions(
    platformProductUid,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const withdrawOrderFee = (productAccountUid: string) => {
  return customInstance<void>({
    url: `/operations/fee/withdrawal/product-account/${productAccountUid}`,
    method: 'POST',
  });
};

export const getWithdrawOrderFeeMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof withdrawOrderFee>>,
    TError,
    { productAccountUid: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof withdrawOrderFee>>,
  TError,
  { productAccountUid: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof withdrawOrderFee>>,
    { productAccountUid: string }
  > = (props) => {
    const { productAccountUid } = props ?? {};

    return withdrawOrderFee(productAccountUid);
  };

  return { mutationFn, ...mutationOptions };
};

export type WithdrawOrderFeeMutationResult = NonNullable<
  Awaited<ReturnType<typeof withdrawOrderFee>>
>;

export type WithdrawOrderFeeMutationError = unknown;

export const useWithdrawOrderFee = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof withdrawOrderFee>>,
    TError,
    { productAccountUid: string },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof withdrawOrderFee>>,
  TError,
  { productAccountUid: string },
  TContext
> => {
  const mutationOptions = getWithdrawOrderFeeMutationOptions(options);

  return useMutation(mutationOptions);
};
export const withdrawOrderFee1 = (productUid: string, platformUid: string) => {
  return customInstance<void>({
    url: `/operations/fee/withdrawal/product/${productUid}/platform/${platformUid}`,
    method: 'POST',
  });
};

export const getWithdrawOrderFee1MutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof withdrawOrderFee1>>,
    TError,
    { productUid: string; platformUid: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof withdrawOrderFee1>>,
  TError,
  { productUid: string; platformUid: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof withdrawOrderFee1>>,
    { productUid: string; platformUid: string }
  > = (props) => {
    const { productUid, platformUid } = props ?? {};

    return withdrawOrderFee1(productUid, platformUid);
  };

  return { mutationFn, ...mutationOptions };
};

export type WithdrawOrderFee1MutationResult = NonNullable<
  Awaited<ReturnType<typeof withdrawOrderFee1>>
>;

export type WithdrawOrderFee1MutationError = unknown;

export const useWithdrawOrderFee1 = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof withdrawOrderFee1>>,
    TError,
    { productUid: string; platformUid: string },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof withdrawOrderFee1>>,
  TError,
  { productUid: string; platformUid: string },
  TContext
> => {
  const mutationOptions = getWithdrawOrderFee1MutationOptions(options);

  return useMutation(mutationOptions);
};
export const withdrawOrderFee2 = (
  productUid: string,
  platformUid: string,
  amount: number
) => {
  return customInstance<void>({
    url: `/operations/fee/withdrawal/product/${productUid}/platform/${platformUid}/amount/${amount}`,
    method: 'POST',
  });
};

export const getWithdrawOrderFee2MutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof withdrawOrderFee2>>,
    TError,
    { productUid: string; platformUid: string; amount: number },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof withdrawOrderFee2>>,
  TError,
  { productUid: string; platformUid: string; amount: number },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof withdrawOrderFee2>>,
    { productUid: string; platformUid: string; amount: number }
  > = (props) => {
    const { productUid, platformUid, amount } = props ?? {};

    return withdrawOrderFee2(productUid, platformUid, amount);
  };

  return { mutationFn, ...mutationOptions };
};

export type WithdrawOrderFee2MutationResult = NonNullable<
  Awaited<ReturnType<typeof withdrawOrderFee2>>
>;

export type WithdrawOrderFee2MutationError = unknown;

export const useWithdrawOrderFee2 = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof withdrawOrderFee2>>,
    TError,
    { productUid: string; platformUid: string; amount: number },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof withdrawOrderFee2>>,
  TError,
  { productUid: string; platformUid: string; amount: number },
  TContext
> => {
  const mutationOptions = getWithdrawOrderFee2MutationOptions(options);

  return useMutation(mutationOptions);
};
