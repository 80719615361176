import {
  AccountOwnerDetailResponse,
  VirtualAccountTransactionResponse,
} from '@b7hio/api-lib/src/ops-portal';
import { Paper, styled } from '@mui/material';
import { DataGridPro as MuiDataGrid } from '@mui/x-data-grid-pro';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { VirtualTransactionListFilter } from '../VirtualTransactionListFilter/VirtualTransactionListFilter';

interface Props {
  readonly data: readonly VirtualAccountTransactionResponse[];
  readonly isLoading: boolean;
  readonly banksList: readonly AccountOwnerDetailResponse[];
  readonly customerList: readonly AccountOwnerDetailResponse[];
  readonly platformList: readonly AccountOwnerDetailResponse[];
}

export const VirtualAccountTransactionList = ({
  data,
  isLoading,
  banksList,
  customerList,
  platformList,
}: Props): JSX.Element => {
  const { t } = useTranslation(['common', 'transactions']);

  return (
    <ListContainer>
      <Toolbar>
        <VirtualTransactionListFilter
          banksList={banksList}
          customerList={customerList}
          platformList={platformList}
        />
      </Toolbar>
      <GridContainer>
        <DataGrid
          getRowId={(row) => row.transactionId}
          pagination
          className="transactionList"
          loading={isLoading}
          disableColumnMenu
          disableSelectionOnClick
          columns={[
            {
              field: 'amount',
              headerName: t('transactions:list.transactionAmount'),
              type: 'number',
              valueFormatter: ({ value }) =>
                t('common:currency', {
                  value: {
                    currency: 'GBP',
                    amount: value,
                  },
                }),
              flex: 1,
              headerAlign: 'left',
            },
            {
              field: 'paymentDirection',
              headerName: t('transactions:list.transactionDirection'),
              flex: 1,
              valueFormatter: ({ value }) =>
                t(`transactions:paymentDirections.${value}` as any),
            },
            {
              field: 'accountOwnerName',
              headerName: t('transactions:list.nameFrom'),
              flex: 1,
            },
            {
              field: 'transactionId',
              headerName: t('transactions:list.transactionId'),
              flex: 1,
            },
            {
              field: 'reference',
              headerName: t('transactions:list.reference'),
              flex: 1,
            },
            {
              field: 'transactionTime',
              headerName: t('transactions:list.time'),
              flex: 1,
              type: 'dateTime',
              valueFormatter: ({ value }) =>
                t('common:dateTime', {
                  value: new Date(value as string),
                }),
            },
          ]}
          rows={data}
        />
      </GridContainer>
    </ListContainer>
  );
};

const Toolbar = styled('div')(({ theme }) => ({
  display: 'grid',
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  borderBottom: `1px solid ${theme.palette.divider}`,
  gridTemplateColumns: `1fr`,
  gridGap: theme.spacing(1),

  [theme.breakpoints.up('md')]: {
    gridTemplateColumns: `1fr 2fr 3fr`,
  },
}));

const ListContainer = styled(Paper)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const GridContainer = styled('div')(() => ({
  display: 'flex',
  height: 'auto !important',
  flexGrow: 1,
  '.MuiDataGrid-root': {
    border: 'none',
  },
}));

const DataGrid = styled(MuiDataGrid)`
  flex-grow: 1;
`;
