/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Bondsmith Savings Exchange Operation Portal APIs
 * OpenAPI spec version: 0.1
 */

export type InternalAccountHolderType =
  typeof InternalAccountHolderType[keyof typeof InternalAccountHolderType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InternalAccountHolderType = {
  INDIVIDUAL: 'INDIVIDUAL',
  JOINT: 'JOINT',
  CORPORATE: 'CORPORATE',
  COMPANY: 'COMPANY',
  SME: 'SME',
  CHARITY: 'CHARITY',
  TRUST: 'TRUST',
} as const;
