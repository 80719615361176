import {
  AccountTransactionPaginatedRecord,
  BalanceChangeSubjectType,
  BondsmithPageAccountTransactionPaginatedRecord,
  InstructionDirection,
} from '@b7hio/api-lib/src/ops-portal';
import { Button, Paper, styled } from '@mui/material';
import type { GridSortModel } from '@mui/x-data-grid-pro';
import { DataGridPro as MuiDataGrid } from '@mui/x-data-grid-pro';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TransactionListFilter } from '../TransactionListFilter';

interface Props {
  readonly data?: BondsmithPageAccountTransactionPaginatedRecord;
  readonly isLoading: boolean;
  readonly filtersNotRequired: boolean;
  readonly pageNumber: number;
  readonly setPageNumber: any;
  readonly sortModel?: GridSortModel;
  readonly setSortModel: any;
  readonly resultsPerPage: number;
}

export const TransactionList: React.FC<Props> = ({
  data,
  isLoading,
  filtersNotRequired,
  pageNumber,
  setPageNumber,
  sortModel,
  setSortModel,
  resultsPerPage,
}) => {
  const { t } = useTranslation(['common', 'transactions', 'shell']);

  const [rowCountState, setRowCountState] = useState(data?.totalSize || 0);

  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      data?.totalSize !== undefined ? data?.totalSize : prevRowCountState
    );
  }, [data?.totalSize, setRowCountState]);

  return (
    <ListContainer>
      {!filtersNotRequired && (
        <Toolbar>
          <TransactionListFilter />
        </Toolbar>
      )}
      <GridContainer>
        <DataGrid
          getRowId={(row) => row.accountTransactionUid}
          pagination
          className="transactionList"
          loading={isLoading}
          disableColumnMenu
          disableSelectionOnClick
          rows={data?.content ?? []}
          paginationMode="server"
          page={pageNumber}
          rowCount={rowCountState}
          pageSize={resultsPerPage}
          rowsPerPageOptions={[]}
          onPageChange={(newPageNumber) => setPageNumber(newPageNumber)}
          sortingMode="server"
          sortModel={sortModel}
          onSortModelChange={setSortModel}
          columns={[
            {
              field: 'transactionAmount',
              headerName: t('transactions:list.transactionAmount'),
              flex: 1,
              type: 'number',
              valueFormatter: ({ value }) =>
                t('common:currency', {
                  value: {
                    currency: 'GBP',
                    amount: value,
                  },
                }),
            },
            {
              field: 'balanceChangeSubjectType',
              flex: 1,
              headerName: t('transactions:list.balanceChangeSubjectType'),
              valueGetter: ({ row }) =>
                row.balanceChangeSubjectType === 'ORDER'
                  ? t(
                      `transactions:paymentDirections.${
                        row.transactionDirection as InstructionDirection
                      }`
                    )
                  : t(
                      `transactions:balanceChangeSubjectType.${
                        row.balanceChangeSubjectType as BalanceChangeSubjectType
                      }`
                    ),
            },
            {
              field: 'platformTradingName',
              headerName: t('transactions:list.platformName'),
              flex: 1,
              renderCell: ({ row }) => {
                const data = row as AccountTransactionPaginatedRecord;
                return (
                  <Button
                    data-testid="bankName"
                    component="a"
                    sx={{ justifyContent: 'flex-start', overflow: 'hidden' }}
                    href={`/banks/${data.bankUid}`}>
                    {data.platformTradingName
                      ? data.platformTradingName
                      : data.platformLegalName}
                  </Button>
                );
              },
            },
            {
              field: 'bankTradingName',
              headerName: t('transactions:list.bankName'),
              flex: 1,
            },
            {
              field: 'productMeta.productName',
              headerName: t('transactions:list.productName'),
              valueGetter: ({ row }) =>
                `${row.productName} (${row.productType})`,
              flex: 1,
            },
            {
              field: 'createdAt',
              headerName: t('transactions:list.createdAt'),
              flex: 1,
              type: 'dateTime',
              valueFormatter: ({ value }) =>
                t('common:dateTime', {
                  value: new Date(value as string),
                }),
            },
          ]}
        />
      </GridContainer>
    </ListContainer>
  );
};

const Toolbar = styled('div')(({ theme }) => ({
  display: 'grid',
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  borderBottom: `1px solid ${theme.palette.divider}`,
  gridTemplateColumns: `1fr`,
  gridGap: theme.spacing(1),

  [theme.breakpoints.up('md')]: {
    gridTemplateColumns: `1fr 2fr 3fr`,
  },
}));

const ListContainer = styled(Paper)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const GridContainer = styled('div')(() => ({
  display: 'flex',
  height: 'auto !important',
  flexGrow: 1,
  '.MuiDataGrid-root': {
    border: 'none',
  },
}));

const DataGrid = styled(MuiDataGrid)`
  flex-grow: 1;
`;
