import {
  PortalUserCreateRequest,
  PortalUserResponse,
  PortalUserType,
  PortalUserUpdateRequest,
  UserRole,
} from '@b7hio/api-lib/src/ops-portal';
import {
  FormFriendly,
  notEmpty,
  requiredWhen,
  tsEnum,
} from '@b7hio/core-lib/src/form';
import type { Infer } from 'superstruct';
import { array, object, optional, string } from 'superstruct';

const UserValidation = object({
  email: notEmpty(string()),
  userType: tsEnum(PortalUserType),
  givenName: optional(string()),
  familyName: optional(string()),
  partnerUid: requiredWhen(
    string(),
    (_, ctx) => ctx.branch[0].userType !== PortalUserType.OPERATIONS
  ),
  userRoles: optional(array(tsEnum(UserRole))),
});

export const CreateUserValidation = UserValidation;
export type CreateUserFields = Infer<typeof CreateUserValidation>;

export const UpdateUserValidation = UserValidation;
export type UpdateUserFields = Infer<typeof UpdateUserValidation>;

export const defaultUserFormValues: FormFriendly<
  PortalUserCreateRequest | PortalUserUpdateRequest | PortalUserResponse
> = {
  email: '',
  partnerUid: '',
  userType: '',
  userRoles: [],
  givenName: '',
  familyName: '',
};

export const BankUserValidation = object({
  email: notEmpty(string()),
  userType: optional(tsEnum(PortalUserType)),
  partnerUid: string(),
  userRoles: notEmpty(array(tsEnum(UserRole))),
  givenName: notEmpty(string()),
  familyName: notEmpty(string()),
});

export const UpdateBankUserValidation = BankUserValidation;
export type UpdateBankUserFields = Infer<typeof UpdateBankUserValidation>;
