import { ProductType } from '@b7hio/api-lib/src/ops-portal';
import { tsEnum, FormFriendly } from '@b7hio/core-lib/src/form';
import type { Describe } from 'superstruct';
import { nullable, object, optional, string } from 'superstruct';

export interface ProductListFilterValues {
  readonly name?: string;
  readonly productType?: ProductType;
  readonly bankUid?: string;
  readonly platformUid?: string;
  readonly currentState?: string;
}

export const initialValues = {
  name: '',
  productType: undefined,
  bankUid: '',
  platformUid: '',
  currentState: ''
};

export const defaultValues = (): FormFriendly<ProductListFilterValues> => initialValues;

export const ProductListFilterValidation: Describe<ProductListFilterValues> =
  object({
    name: optional(string()),
    productType: nullable(optional(tsEnum(ProductType, true))),
    bankUid: nullable(optional(string())),
    platformUid: nullable(optional(string())),
    currentState: nullable(optional(string())),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  }) as any;
