/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Bondsmith Savings Exchange Operation Portal APIs
 * OpenAPI spec version: 0.1
 */

export type BatchEventType = typeof BatchEventType[keyof typeof BatchEventType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BatchEventType = {
  ORDER: 'ORDER',
  PLATFORM_ORDER: 'PLATFORM_ORDER',
  UPDATE_PLATFORM_CUSTOMERS: 'UPDATE_PLATFORM_CUSTOMERS',
  BANK_NOTICE_INSTANT_ORDER: 'BANK_NOTICE_INSTANT_ORDER',
  BANK_TERM_ORDER: 'BANK_TERM_ORDER',
  ORDER_CONFIRMATION: 'ORDER_CONFIRMATION',
  ORDER_COMPLETION: 'ORDER_COMPLETION',
  PRODUCT: 'PRODUCT',
  INSTRUCTION: 'INSTRUCTION',
  INTEREST: 'INTEREST',
  POSITION: 'POSITION',
  RECONCILIATION: 'RECONCILIATION',
  CONFIRM_CUSTOMER_UPDATES: 'CONFIRM_CUSTOMER_UPDATES',
  FAILED_ORDER: 'FAILED_ORDER',
  FAILED_CUSTOMER_UPDATE: 'FAILED_CUSTOMER_UPDATE',
  ORDER_ANALYTICS: 'ORDER_ANALYTICS',
  HOLDINGS_ANALYTICS: 'HOLDINGS_ANALYTICS',
  MULTI_BANK_BALANCES: 'MULTI_BANK_BALANCES',
  MULTI_BANK_INTEREST_RATE_ADJUSTMENTS: 'MULTI_BANK_INTEREST_RATE_ADJUSTMENTS',
} as const;
