export function showErrors(
  error: any,
  enqueueSnackbar: any,
  defaultErrorMsg: string
): void {
  if (error.response?.data?.errorDetails) {
    error.response.data.errorDetails.forEach(
      (err: { readonly message: string }) => {
        enqueueSnackbar(err.message ? err.message : defaultErrorMsg, 'error');
      }
    );
  } else {
    const errorMsg = error.response?.data?.message ?? undefined;
    enqueueSnackbar(errorMsg ? errorMsg : defaultErrorMsg, 'error');
  }
}
