/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Bondsmith Savings Exchange Operation Portal APIs
 * OpenAPI spec version: 0.1
 */

export type PortalUserType = typeof PortalUserType[keyof typeof PortalUserType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PortalUserType = {
  BANK: 'BANK',
  OPERATIONS: 'OPERATIONS',
  DISTRIBUTOR: 'DISTRIBUTOR',
} as const;
