/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Bondsmith Savings Exchange Operation Portal APIs
 * OpenAPI spec version: 0.1
 */

export type FeePaginatedProperty =
  typeof FeePaginatedProperty[keyof typeof FeePaginatedProperty];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FeePaginatedProperty = {
  FEE_ACCOUNT_BALANCE: 'FEE_ACCOUNT_BALANCE',
  PRODUCT_NAME: 'PRODUCT_NAME',
  PLATFORM_CUSTOMER_ID: 'PLATFORM_CUSTOMER_ID',
  PLATFORM_NAME: 'PLATFORM_NAME',
  GROSS_RATE: 'GROSS_RATE',
  REQUESTED_AMOUNT: 'REQUESTED_AMOUNT',
  COMPLETED_AMOUNT: 'COMPLETED_AMOUNT',
} as const;
