import { PartnerType, RealAccountType } from '@b7hio/api-lib/src/ops-portal';
import { tsEnum, FormFriendly } from '@b7hio/core-lib/src/form';
import type { Describe } from 'superstruct';
import { nullable, object, optional, string } from 'superstruct';

export interface VirtualTransactionListFilterValues {
  readonly partnerType?: PartnerType | undefined;
  readonly platform?: string | undefined;
  readonly customer?: string | undefined;
  readonly bank?: string;
  readonly accountId?: string;
  readonly virtualAccountId?: string;
  readonly realAccountType?: RealAccountType;
}

export const initialValues = {
  partnerType: undefined,
  platform: '',
  customer: '',
  bank: '',
  accountId: '',
  virtualAccountId: '',
  realAccountType: RealAccountType.HUB,
};

export const defaultValues =
  (): FormFriendly<VirtualTransactionListFilterValues> => (initialValues);

export const virtualTransactionsListFilterValidation: Describe<VirtualTransactionListFilterValues> =
  object({
    partnerType: nullable(optional(tsEnum(PartnerType, true))),
    platform: nullable(optional(string())),
    customer: nullable(optional(string())),
    bank: nullable(optional(string())),
    accountId: nullable(string()),
    virtualAccountId: nullable(string()),
    realAccountType: tsEnum(RealAccountType),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  }) as any;
