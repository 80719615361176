/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Bondsmith Savings Exchange Operation Portal APIs
 * OpenAPI spec version: 0.1
 */

export type ExoticType = typeof ExoticType[keyof typeof ExoticType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ExoticType = {
  SHARIA: 'SHARIA',
  NONE: 'NONE',
} as const;
