/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Bondsmith Savings Exchange Operation Portal APIs
 * OpenAPI spec version: 0.1
 */

export type InternalDataSourceMatchResultType =
  typeof InternalDataSourceMatchResultType[keyof typeof InternalDataSourceMatchResultType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InternalDataSourceMatchResultType = {
  NO_RESULTS: 'NO_RESULTS',
  MATCH: 'MATCH',
  MULTIPLE_RESULTS: 'MULTIPLE_RESULTS',
} as const;
