/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Bondsmith Savings Exchange Operation Portal APIs
 * OpenAPI spec version: 0.1
 */

export type RequestAccountHolderType =
  typeof RequestAccountHolderType[keyof typeof RequestAccountHolderType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RequestAccountHolderType = {
  INDIVIDUAL: 'INDIVIDUAL',
  JOINT: 'JOINT',
  CORPORATE: 'CORPORATE',
  SME: 'SME',
  CHARITY: 'CHARITY',
  TRUST: 'TRUST',
} as const;
