import {
  getFscsBrochure,
  updateFscsBrochureForBank,
} from '@b7hio/api-lib/src/bank-portal';
import { FeatureDialog, useSnackbar } from '@b7hio/core-lib/src';
import { showErrors } from '@b7hio/core-lib/src/utils';
import {
  FscsDocument,
  getFscsRequest,
  uploadToS3,
} from '@b7hio/ops-ui-lib/src/features/products/hooks/useFscsInteractivity';
import { Alert, Box, Button, styled } from '@mui/material';
import { useUser } from 'banks-portal/src/hooks/useUser';
import { useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { v4 as uuidV4 } from 'uuid';

interface Props {
  readonly open: boolean;
  readonly onClose: (event: object, reason: string) => void;
}

export const FSCSUploadDialogBank = ({ open, onClose }: Props) => {
  const [downloadUrl, setDownloadUrl] = useState<string>('');
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation(['common', 'products']);
  const [, bankId] = useUser();

  const onDownloadBrochure = async () => {
    try {
      const brochureUrl = await getFscsBrochure();
      setDownloadUrl(brochureUrl.fscsBrochureUrl);
    } catch (error) {
      showErrors(error, enqueueSnackbar, t('products:fscsFile.downloadError'));
    }
  };

  const onSubmit = async (document: FscsDocument) => {
    const randomId = uuidV4();

    if (bankId) {
      try {
        await uploadToS3(bankId, document, randomId);
        const request = getFscsRequest(bankId, document, randomId);
        await updateFscsBrochureForBank(request);
      } catch (error) {
        showErrors(error, enqueueSnackbar, t('products:fscsFile.uploadError'));
      }
    }
  };

  return (
    <FeatureDialog
      id="FSCSUploadDialog"
      open={open}
      title={t('products:fscsFile.title')}
      onClose={(event) => {
        onClose(event, 'skip');
      }}>
      <FscsFileUploadForm
        onSubmit={onSubmit}
        onDownloadBrochure={onDownloadBrochure}
        onClose={onClose}
        downloadUrl={downloadUrl}
      />
    </FeatureDialog>
  );
};

interface FscsFileUploadFormProps {
  readonly onSubmit: (file: FscsDocument) => Promise<void>;
  readonly onDownloadBrochure: () => void;
  readonly onClose: (event: object, reason: string) => void;
  readonly downloadUrl: string;
}

const openFileBrowser = () => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  document.getElementById('upload-brochure')!.click();
};

const FscsFileUploadForm = ({
  onSubmit,
  onDownloadBrochure,
  onClose,
  downloadUrl,
}: FscsFileUploadFormProps) => {
  useEffect(() => {
    onDownloadBrochure();
  }, []);

  const { t } = useTranslation(['common', 'products']);
  const [state, setState] = useState<'upload-brochure' | 'summary'>(
    'upload-brochure'
  );
  const [fscsFile, setFscsFile] = useState<File | undefined>(undefined);

  const { handleSubmit, formState, getValues, setValue } = useForm({
    mode: 'onBlur',
  });

  return (
    <form
      onSubmit={handleSubmit(
        async () => {
          const document: FscsDocument = { fscsFile: fscsFile };
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          if (fscsFile) {
            await onSubmit(document);
            setState('summary');
          }
        },
        (errors) => console.log(errors, getValues())
      )}>
      <FormContainer>
        {state === 'upload-brochure' && (
          <>
            <Alert severity="info">{t('products:fscsFile.instructions')}</Alert>
            <Button
              sx={{ my: 1, minHeight: '100px' }}
              color="primary"
              variant="contained"
              href={downloadUrl}>
              {t('products:btn.downloadFscs')}
            </Button>
            <Button
              sx={{ my: 1, minHeight: '100px' }}
              color="primary"
              variant="outlined"
              onClick={openFileBrowser}>
              <input
                type="file"
                id="upload-brochure"
                onChange={(event) => {
                  const file = event.target?.files?.[0];
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  if (file && file.name !== '') {
                    setFscsFile(file);
                    setValue('data', file as Blob);
                  }
                }}
                hidden
              />
              {t('products:btn.fscsBrochure')}
            </Button>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-end',
                gap: 2,
              }}>
              <Button
                sx={{ mt: 2, mb: 2, minWidth: '150px' }}
                color="primary"
                variant="outlined"
                onClick={(e: object) => {
                  onClose(e, 'skip');
                }}>
                {t('products:fscsFile.cancel')}
              </Button>
              <Button
                sx={{ mt: 2, mb: 2, minWidth: '150px' }}
                color="primary"
                variant="contained"
                disabled={formState.isSubmitting || fscsFile === undefined}
                type="submit">
                {t('products:fscsFile.submit')}
              </Button>
            </Box>
          </>
        )}
        {state === 'summary' && (
          <>
            <FormContainer>
              <Alert severity="success">{t('products:fscsFile.success')}</Alert>
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'flex-end',
                  gap: 2,
                }}>
                <Button
                  sx={{ mt: 2, mb: 2 }}
                  color="primary"
                  variant="contained"
                  onClick={(e: object) => {
                    onClose(e, 'skip');
                  }}>
                  {t('products:fscsFile.close')}
                </Button>
              </Box>
            </FormContainer>
          </>
        )}
      </FormContainer>
    </form>
  );
};

const FormContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  '> div': {
    marginBottom: theme.spacing(2),

    '&:last-of-type': {
      marginBottom: 0,
    },
  },
}));
