/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Bondsmith Savings Exchange Operation Portal APIs
 * OpenAPI spec version: 0.1
 */

export type PartnerType = typeof PartnerType[keyof typeof PartnerType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PartnerType = {
  BONDSMITH: 'BONDSMITH',
  BANK: 'BANK',
  PLATFORM: 'PLATFORM',
  ADVISER: 'ADVISER',
  CUSTOMER: 'CUSTOMER',
  BANK_PRODUCT: 'BANK_PRODUCT',
  CASH_ACCOUNT: 'CASH_ACCOUNT',
} as const;
