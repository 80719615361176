/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Bondsmith Savings Exchange Operation Portal APIs
 * OpenAPI spec version: 0.1
 */
import { useMutation, useQuery } from 'react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { customInstance } from '../../../../mutator/instance';
import type {
  BondsmithPagePartnerPlatformPaginatedResponse,
  ListAccountsForBanksParams,
  ListAccountsForPlatformsParams,
  ListAllOwnAccountsParams,
  ListOwnBankAccountsParams,
  ListOwnPlatformAccountsParams,
  ListPlatforms1Params,
  NominatedAccountRequest,
  NominatedAccountResponse,
  NominatedAccountWithNameRequest,
  PartnerPlatformRequest,
  PartnerPlatformResponse,
  PartnerResponse,
  PartnerType,
  PlatformMailTemplateRequest,
  RealAccountType,
  SetClockParams,
  UKExchangePaymentAccountResponse,
  UKVerifiedPaymentAccountResponse,
  UKVirtualPaymentPartnerAccountRequest,
} from '../../models';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

export const sendKycDocumentRequestEmail = (customerUid: string) => {
  return customInstance<void>({
    url: `/operations/own/partner/mail/customer/${customerUid}/kyc-docs-request`,
    method: 'POST',
  });
};

export const getSendKycDocumentRequestEmailMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof sendKycDocumentRequestEmail>>,
    TError,
    { customerUid: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof sendKycDocumentRequestEmail>>,
  TError,
  { customerUid: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof sendKycDocumentRequestEmail>>,
    { customerUid: string }
  > = (props) => {
    const { customerUid } = props ?? {};

    return sendKycDocumentRequestEmail(customerUid);
  };

  return { mutationFn, ...mutationOptions };
};

export type SendKycDocumentRequestEmailMutationResult = NonNullable<
  Awaited<ReturnType<typeof sendKycDocumentRequestEmail>>
>;

export type SendKycDocumentRequestEmailMutationError = unknown;

export const useSendKycDocumentRequestEmail = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof sendKycDocumentRequestEmail>>,
    TError,
    { customerUid: string },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof sendKycDocumentRequestEmail>>,
  TError,
  { customerUid: string },
  TContext
> => {
  const mutationOptions =
    getSendKycDocumentRequestEmailMutationOptions(options);

  return useMutation(mutationOptions);
};
export const insertCustomPlatformEmailTemplate = (
  platformUid: string,
  platformMailTemplateRequest: PlatformMailTemplateRequest
) => {
  return customInstance<void>({
    url: `/operations/own/partner/mail/platform/${platformUid}/template`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: platformMailTemplateRequest,
  });
};

export const getInsertCustomPlatformEmailTemplateMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof insertCustomPlatformEmailTemplate>>,
    TError,
    { platformUid: string; data: PlatformMailTemplateRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof insertCustomPlatformEmailTemplate>>,
  TError,
  { platformUid: string; data: PlatformMailTemplateRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof insertCustomPlatformEmailTemplate>>,
    { platformUid: string; data: PlatformMailTemplateRequest }
  > = (props) => {
    const { platformUid, data } = props ?? {};

    return insertCustomPlatformEmailTemplate(platformUid, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type InsertCustomPlatformEmailTemplateMutationResult = NonNullable<
  Awaited<ReturnType<typeof insertCustomPlatformEmailTemplate>>
>;
export type InsertCustomPlatformEmailTemplateMutationBody =
  PlatformMailTemplateRequest;
export type InsertCustomPlatformEmailTemplateMutationError = unknown;

export const useInsertCustomPlatformEmailTemplate = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof insertCustomPlatformEmailTemplate>>,
    TError,
    { platformUid: string; data: PlatformMailTemplateRequest },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof insertCustomPlatformEmailTemplate>>,
  TError,
  { platformUid: string; data: PlatformMailTemplateRequest },
  TContext
> => {
  const mutationOptions =
    getInsertCustomPlatformEmailTemplateMutationOptions(options);

  return useMutation(mutationOptions);
};
export const fetchNominatedAccount = (
  accountOwnerUid: string,
  partnerType: PartnerType,
  signal?: AbortSignal
) => {
  return customInstance<NominatedAccountResponse>({
    url: `/operations/own/setup/account/nominated/owner/${accountOwnerUid}/type/${partnerType}`,
    method: 'GET',
    signal,
  });
};

export const getFetchNominatedAccountQueryKey = (
  accountOwnerUid: string,
  partnerType: PartnerType
) => {
  return [
    `/operations/own/setup/account/nominated/owner/${accountOwnerUid}/type/${partnerType}`,
  ] as const;
};

export const getFetchNominatedAccountQueryOptions = <
  TData = Awaited<ReturnType<typeof fetchNominatedAccount>>,
  TError = unknown
>(
  accountOwnerUid: string,
  partnerType: PartnerType,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof fetchNominatedAccount>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getFetchNominatedAccountQueryKey(accountOwnerUid, partnerType);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof fetchNominatedAccount>>
  > = ({ signal }) =>
    fetchNominatedAccount(accountOwnerUid, partnerType, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!(accountOwnerUid && partnerType),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof fetchNominatedAccount>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type FetchNominatedAccountQueryResult = NonNullable<
  Awaited<ReturnType<typeof fetchNominatedAccount>>
>;
export type FetchNominatedAccountQueryError = unknown;

export const useFetchNominatedAccount = <
  TData = Awaited<ReturnType<typeof fetchNominatedAccount>>,
  TError = unknown
>(
  accountOwnerUid: string,
  partnerType: PartnerType,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof fetchNominatedAccount>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getFetchNominatedAccountQueryOptions(
    accountOwnerUid,
    partnerType,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getCashAccountForCustomer = (
  customerUid: string,
  signal?: AbortSignal
) => {
  return customInstance<string>({
    url: `/operations/own/setup/accountOwner/${customerUid}`,
    method: 'GET',
    signal,
  });
};

export const getGetCashAccountForCustomerQueryKey = (customerUid: string) => {
  return [`/operations/own/setup/accountOwner/${customerUid}`] as const;
};

export const getGetCashAccountForCustomerQueryOptions = <
  TData = Awaited<ReturnType<typeof getCashAccountForCustomer>>,
  TError = unknown
>(
  customerUid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getCashAccountForCustomer>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetCashAccountForCustomerQueryKey(customerUid);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getCashAccountForCustomer>>
  > = ({ signal }) => getCashAccountForCustomer(customerUid, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!customerUid,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getCashAccountForCustomer>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetCashAccountForCustomerQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCashAccountForCustomer>>
>;
export type GetCashAccountForCustomerQueryError = unknown;

export const useGetCashAccountForCustomer = <
  TData = Awaited<ReturnType<typeof getCashAccountForCustomer>>,
  TError = unknown
>(
  customerUid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getCashAccountForCustomer>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCashAccountForCustomerQueryOptions(
    customerUid,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const listAccountsForBanks = (
  params?: ListAccountsForBanksParams,
  signal?: AbortSignal
) => {
  return customInstance<UKVerifiedPaymentAccountResponse[]>({
    url: `/operations/own/setup/accounts/banks`,
    method: 'GET',
    params,
    signal,
  });
};

export const getListAccountsForBanksQueryKey = (
  params?: ListAccountsForBanksParams
) => {
  return [
    `/operations/own/setup/accounts/banks`,
    ...(params ? [params] : []),
  ] as const;
};

export const getListAccountsForBanksQueryOptions = <
  TData = Awaited<ReturnType<typeof listAccountsForBanks>>,
  TError = unknown
>(
  params?: ListAccountsForBanksParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof listAccountsForBanks>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getListAccountsForBanksQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof listAccountsForBanks>>
  > = ({ signal }) => listAccountsForBanks(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof listAccountsForBanks>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListAccountsForBanksQueryResult = NonNullable<
  Awaited<ReturnType<typeof listAccountsForBanks>>
>;
export type ListAccountsForBanksQueryError = unknown;

export const useListAccountsForBanks = <
  TData = Awaited<ReturnType<typeof listAccountsForBanks>>,
  TError = unknown
>(
  params?: ListAccountsForBanksParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof listAccountsForBanks>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListAccountsForBanksQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const newAccountForBank = (
  bankUid: string,
  nominatedAccountRequest: NominatedAccountRequest
) => {
  return customInstance<string>({
    url: `/operations/own/setup/accounts/banks/${bankUid}`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: nominatedAccountRequest,
  });
};

export const getNewAccountForBankMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof newAccountForBank>>,
    TError,
    { bankUid: string; data: NominatedAccountRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof newAccountForBank>>,
  TError,
  { bankUid: string; data: NominatedAccountRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof newAccountForBank>>,
    { bankUid: string; data: NominatedAccountRequest }
  > = (props) => {
    const { bankUid, data } = props ?? {};

    return newAccountForBank(bankUid, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type NewAccountForBankMutationResult = NonNullable<
  Awaited<ReturnType<typeof newAccountForBank>>
>;
export type NewAccountForBankMutationBody = NominatedAccountRequest;
export type NewAccountForBankMutationError = unknown;

export const useNewAccountForBank = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof newAccountForBank>>,
    TError,
    { bankUid: string; data: NominatedAccountRequest },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof newAccountForBank>>,
  TError,
  { bankUid: string; data: NominatedAccountRequest },
  TContext
> => {
  const mutationOptions = getNewAccountForBankMutationOptions(options);

  return useMutation(mutationOptions);
};
export const newAccountForCustomer = (
  customerUid: string,
  cashAccountUid: string,
  nominatedAccountWithNameRequest: NominatedAccountWithNameRequest
) => {
  return customInstance<void>({
    url: `/operations/own/setup/accounts/customers/${customerUid}/account/${cashAccountUid}`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: nominatedAccountWithNameRequest,
  });
};

export const getNewAccountForCustomerMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof newAccountForCustomer>>,
    TError,
    {
      customerUid: string;
      cashAccountUid: string;
      data: NominatedAccountWithNameRequest;
    },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof newAccountForCustomer>>,
  TError,
  {
    customerUid: string;
    cashAccountUid: string;
    data: NominatedAccountWithNameRequest;
  },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof newAccountForCustomer>>,
    {
      customerUid: string;
      cashAccountUid: string;
      data: NominatedAccountWithNameRequest;
    }
  > = (props) => {
    const { customerUid, cashAccountUid, data } = props ?? {};

    return newAccountForCustomer(customerUid, cashAccountUid, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type NewAccountForCustomerMutationResult = NonNullable<
  Awaited<ReturnType<typeof newAccountForCustomer>>
>;
export type NewAccountForCustomerMutationBody = NominatedAccountWithNameRequest;
export type NewAccountForCustomerMutationError = unknown;

export const useNewAccountForCustomer = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof newAccountForCustomer>>,
    TError,
    {
      customerUid: string;
      cashAccountUid: string;
      data: NominatedAccountWithNameRequest;
    },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof newAccountForCustomer>>,
  TError,
  {
    customerUid: string;
    cashAccountUid: string;
    data: NominatedAccountWithNameRequest;
  },
  TContext
> => {
  const mutationOptions = getNewAccountForCustomerMutationOptions(options);

  return useMutation(mutationOptions);
};
export const listAllOwnAccounts = (
  params?: ListAllOwnAccountsParams,
  signal?: AbortSignal
) => {
  return customInstance<UKExchangePaymentAccountResponse[]>({
    url: `/operations/own/setup/accounts/own`,
    method: 'GET',
    params,
    signal,
  });
};

export const getListAllOwnAccountsQueryKey = (
  params?: ListAllOwnAccountsParams
) => {
  return [
    `/operations/own/setup/accounts/own`,
    ...(params ? [params] : []),
  ] as const;
};

export const getListAllOwnAccountsQueryOptions = <
  TData = Awaited<ReturnType<typeof listAllOwnAccounts>>,
  TError = unknown
>(
  params?: ListAllOwnAccountsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof listAllOwnAccounts>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getListAllOwnAccountsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listAllOwnAccounts>>> =
    ({ signal }) => listAllOwnAccounts(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof listAllOwnAccounts>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListAllOwnAccountsQueryResult = NonNullable<
  Awaited<ReturnType<typeof listAllOwnAccounts>>
>;
export type ListAllOwnAccountsQueryError = unknown;

export const useListAllOwnAccounts = <
  TData = Awaited<ReturnType<typeof listAllOwnAccounts>>,
  TError = unknown
>(
  params?: ListAllOwnAccountsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof listAllOwnAccounts>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListAllOwnAccountsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const listOwnBankAccounts = (
  params?: ListOwnBankAccountsParams,
  signal?: AbortSignal
) => {
  return customInstance<UKExchangePaymentAccountResponse[]>({
    url: `/operations/own/setup/accounts/own/bank`,
    method: 'GET',
    params,
    signal,
  });
};

export const getListOwnBankAccountsQueryKey = (
  params?: ListOwnBankAccountsParams
) => {
  return [
    `/operations/own/setup/accounts/own/bank`,
    ...(params ? [params] : []),
  ] as const;
};

export const getListOwnBankAccountsQueryOptions = <
  TData = Awaited<ReturnType<typeof listOwnBankAccounts>>,
  TError = unknown
>(
  params?: ListOwnBankAccountsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof listOwnBankAccounts>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getListOwnBankAccountsQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof listOwnBankAccounts>>
  > = ({ signal }) => listOwnBankAccounts(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof listOwnBankAccounts>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListOwnBankAccountsQueryResult = NonNullable<
  Awaited<ReturnType<typeof listOwnBankAccounts>>
>;
export type ListOwnBankAccountsQueryError = unknown;

export const useListOwnBankAccounts = <
  TData = Awaited<ReturnType<typeof listOwnBankAccounts>>,
  TError = unknown
>(
  params?: ListOwnBankAccountsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof listOwnBankAccounts>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListOwnBankAccountsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const newOwnBankAccount = (
  uKVirtualPaymentPartnerAccountRequest: UKVirtualPaymentPartnerAccountRequest
) => {
  return customInstance<string>({
    url: `/operations/own/setup/accounts/own/bank`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: uKVirtualPaymentPartnerAccountRequest,
  });
};

export const getNewOwnBankAccountMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof newOwnBankAccount>>,
    TError,
    { data: UKVirtualPaymentPartnerAccountRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof newOwnBankAccount>>,
  TError,
  { data: UKVirtualPaymentPartnerAccountRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof newOwnBankAccount>>,
    { data: UKVirtualPaymentPartnerAccountRequest }
  > = (props) => {
    const { data } = props ?? {};

    return newOwnBankAccount(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type NewOwnBankAccountMutationResult = NonNullable<
  Awaited<ReturnType<typeof newOwnBankAccount>>
>;
export type NewOwnBankAccountMutationBody =
  UKVirtualPaymentPartnerAccountRequest;
export type NewOwnBankAccountMutationError = unknown;

export const useNewOwnBankAccount = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof newOwnBankAccount>>,
    TError,
    { data: UKVirtualPaymentPartnerAccountRequest },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof newOwnBankAccount>>,
  TError,
  { data: UKVirtualPaymentPartnerAccountRequest },
  TContext
> => {
  const mutationOptions = getNewOwnBankAccountMutationOptions(options);

  return useMutation(mutationOptions);
};
export const listOwnPlatformAccounts = (
  params?: ListOwnPlatformAccountsParams,
  signal?: AbortSignal
) => {
  return customInstance<UKExchangePaymentAccountResponse[]>({
    url: `/operations/own/setup/accounts/own/platform`,
    method: 'GET',
    params,
    signal,
  });
};

export const getListOwnPlatformAccountsQueryKey = (
  params?: ListOwnPlatformAccountsParams
) => {
  return [
    `/operations/own/setup/accounts/own/platform`,
    ...(params ? [params] : []),
  ] as const;
};

export const getListOwnPlatformAccountsQueryOptions = <
  TData = Awaited<ReturnType<typeof listOwnPlatformAccounts>>,
  TError = unknown
>(
  params?: ListOwnPlatformAccountsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof listOwnPlatformAccounts>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getListOwnPlatformAccountsQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof listOwnPlatformAccounts>>
  > = ({ signal }) => listOwnPlatformAccounts(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof listOwnPlatformAccounts>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListOwnPlatformAccountsQueryResult = NonNullable<
  Awaited<ReturnType<typeof listOwnPlatformAccounts>>
>;
export type ListOwnPlatformAccountsQueryError = unknown;

export const useListOwnPlatformAccounts = <
  TData = Awaited<ReturnType<typeof listOwnPlatformAccounts>>,
  TError = unknown
>(
  params?: ListOwnPlatformAccountsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof listOwnPlatformAccounts>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListOwnPlatformAccountsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const newOwnPlatformAccount = (
  uKVirtualPaymentPartnerAccountRequest: UKVirtualPaymentPartnerAccountRequest
) => {
  return customInstance<string>({
    url: `/operations/own/setup/accounts/own/platform`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: uKVirtualPaymentPartnerAccountRequest,
  });
};

export const getNewOwnPlatformAccountMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof newOwnPlatformAccount>>,
    TError,
    { data: UKVirtualPaymentPartnerAccountRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof newOwnPlatformAccount>>,
  TError,
  { data: UKVirtualPaymentPartnerAccountRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof newOwnPlatformAccount>>,
    { data: UKVirtualPaymentPartnerAccountRequest }
  > = (props) => {
    const { data } = props ?? {};

    return newOwnPlatformAccount(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type NewOwnPlatformAccountMutationResult = NonNullable<
  Awaited<ReturnType<typeof newOwnPlatformAccount>>
>;
export type NewOwnPlatformAccountMutationBody =
  UKVirtualPaymentPartnerAccountRequest;
export type NewOwnPlatformAccountMutationError = unknown;

export const useNewOwnPlatformAccount = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof newOwnPlatformAccount>>,
    TError,
    { data: UKVirtualPaymentPartnerAccountRequest },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof newOwnPlatformAccount>>,
  TError,
  { data: UKVirtualPaymentPartnerAccountRequest },
  TContext
> => {
  const mutationOptions = getNewOwnPlatformAccountMutationOptions(options);

  return useMutation(mutationOptions);
};
export const whitelistPartner = (
  partnerType: PartnerType,
  partnerUid: string
) => {
  return customInstance<void>({
    url: `/operations/own/setup/accounts/partners/${partnerType}/${partnerUid}`,
    method: 'POST',
  });
};

export const getWhitelistPartnerMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof whitelistPartner>>,
    TError,
    { partnerType: PartnerType; partnerUid: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof whitelistPartner>>,
  TError,
  { partnerType: PartnerType; partnerUid: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof whitelistPartner>>,
    { partnerType: PartnerType; partnerUid: string }
  > = (props) => {
    const { partnerType, partnerUid } = props ?? {};

    return whitelistPartner(partnerType, partnerUid);
  };

  return { mutationFn, ...mutationOptions };
};

export type WhitelistPartnerMutationResult = NonNullable<
  Awaited<ReturnType<typeof whitelistPartner>>
>;

export type WhitelistPartnerMutationError = unknown;

export const useWhitelistPartner = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof whitelistPartner>>,
    TError,
    { partnerType: PartnerType; partnerUid: string },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof whitelistPartner>>,
  TError,
  { partnerType: PartnerType; partnerUid: string },
  TContext
> => {
  const mutationOptions = getWhitelistPartnerMutationOptions(options);

  return useMutation(mutationOptions);
};
export const listAccountsForPlatforms = (
  params?: ListAccountsForPlatformsParams,
  signal?: AbortSignal
) => {
  return customInstance<UKVerifiedPaymentAccountResponse[]>({
    url: `/operations/own/setup/accounts/platforms`,
    method: 'GET',
    params,
    signal,
  });
};

export const getListAccountsForPlatformsQueryKey = (
  params?: ListAccountsForPlatformsParams
) => {
  return [
    `/operations/own/setup/accounts/platforms`,
    ...(params ? [params] : []),
  ] as const;
};

export const getListAccountsForPlatformsQueryOptions = <
  TData = Awaited<ReturnType<typeof listAccountsForPlatforms>>,
  TError = unknown
>(
  params?: ListAccountsForPlatformsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof listAccountsForPlatforms>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getListAccountsForPlatformsQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof listAccountsForPlatforms>>
  > = ({ signal }) => listAccountsForPlatforms(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof listAccountsForPlatforms>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListAccountsForPlatformsQueryResult = NonNullable<
  Awaited<ReturnType<typeof listAccountsForPlatforms>>
>;
export type ListAccountsForPlatformsQueryError = unknown;

export const useListAccountsForPlatforms = <
  TData = Awaited<ReturnType<typeof listAccountsForPlatforms>>,
  TError = unknown
>(
  params?: ListAccountsForPlatformsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof listAccountsForPlatforms>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListAccountsForPlatformsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const newAccountForPlatform = (
  platformUid: string,
  nominatedAccountRequest: NominatedAccountRequest
) => {
  return customInstance<void>({
    url: `/operations/own/setup/accounts/platforms/${platformUid}`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: nominatedAccountRequest,
  });
};

export const getNewAccountForPlatformMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof newAccountForPlatform>>,
    TError,
    { platformUid: string; data: NominatedAccountRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof newAccountForPlatform>>,
  TError,
  { platformUid: string; data: NominatedAccountRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof newAccountForPlatform>>,
    { platformUid: string; data: NominatedAccountRequest }
  > = (props) => {
    const { platformUid, data } = props ?? {};

    return newAccountForPlatform(platformUid, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type NewAccountForPlatformMutationResult = NonNullable<
  Awaited<ReturnType<typeof newAccountForPlatform>>
>;
export type NewAccountForPlatformMutationBody = NominatedAccountRequest;
export type NewAccountForPlatformMutationError = unknown;

export const useNewAccountForPlatform = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof newAccountForPlatform>>,
    TError,
    { platformUid: string; data: NominatedAccountRequest },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof newAccountForPlatform>>,
  TError,
  { platformUid: string; data: NominatedAccountRequest },
  TContext
> => {
  const mutationOptions = getNewAccountForPlatformMutationOptions(options);

  return useMutation(mutationOptions);
};
export const selectPartnerAccounts = (
  accountType: RealAccountType,
  signal?: AbortSignal
) => {
  return customInstance<PartnerResponse[]>({
    url: `/operations/own/setup/accounts/select/${accountType}`,
    method: 'GET',
    signal,
  });
};

export const getSelectPartnerAccountsQueryKey = (
  accountType: RealAccountType
) => {
  return [`/operations/own/setup/accounts/select/${accountType}`] as const;
};

export const getSelectPartnerAccountsQueryOptions = <
  TData = Awaited<ReturnType<typeof selectPartnerAccounts>>,
  TError = unknown
>(
  accountType: RealAccountType,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof selectPartnerAccounts>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getSelectPartnerAccountsQueryKey(accountType);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof selectPartnerAccounts>>
  > = ({ signal }) => selectPartnerAccounts(accountType, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!accountType,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof selectPartnerAccounts>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SelectPartnerAccountsQueryResult = NonNullable<
  Awaited<ReturnType<typeof selectPartnerAccounts>>
>;
export type SelectPartnerAccountsQueryError = unknown;

export const useSelectPartnerAccounts = <
  TData = Awaited<ReturnType<typeof selectPartnerAccounts>>,
  TError = unknown
>(
  accountType: RealAccountType,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof selectPartnerAccounts>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSelectPartnerAccountsQueryOptions(
    accountType,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const fetchPartnerAccounts = (
  partnerUids: string[],
  signal?: AbortSignal
) => {
  return customInstance<PartnerResponse[]>({
    url: `/operations/own/setup/accounts/${partnerUids}`,
    method: 'GET',
    signal,
  });
};

export const getFetchPartnerAccountsQueryKey = (partnerUids: string[]) => {
  return [`/operations/own/setup/accounts/${partnerUids}`] as const;
};

export const getFetchPartnerAccountsQueryOptions = <
  TData = Awaited<ReturnType<typeof fetchPartnerAccounts>>,
  TError = unknown
>(
  partnerUids: string[],
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof fetchPartnerAccounts>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getFetchPartnerAccountsQueryKey(partnerUids);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof fetchPartnerAccounts>>
  > = ({ signal }) => fetchPartnerAccounts(partnerUids, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!partnerUids,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof fetchPartnerAccounts>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type FetchPartnerAccountsQueryResult = NonNullable<
  Awaited<ReturnType<typeof fetchPartnerAccounts>>
>;
export type FetchPartnerAccountsQueryError = unknown;

export const useFetchPartnerAccounts = <
  TData = Awaited<ReturnType<typeof fetchPartnerAccounts>>,
  TError = unknown
>(
  partnerUids: string[],
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof fetchPartnerAccounts>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getFetchPartnerAccountsQueryOptions(
    partnerUids,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const setClock = (params: SetClockParams) => {
  return customInstance<void>({
    url: `/operations/own/setup/clock`,
    method: 'POST',
    params,
  });
};

export const getSetClockMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof setClock>>,
    TError,
    { params: SetClockParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof setClock>>,
  TError,
  { params: SetClockParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof setClock>>,
    { params: SetClockParams }
  > = (props) => {
    const { params } = props ?? {};

    return setClock(params);
  };

  return { mutationFn, ...mutationOptions };
};

export type SetClockMutationResult = NonNullable<
  Awaited<ReturnType<typeof setClock>>
>;

export type SetClockMutationError = unknown;

export const useSetClock = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof setClock>>,
    TError,
    { params: SetClockParams },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof setClock>>,
  TError,
  { params: SetClockParams },
  TContext
> => {
  const mutationOptions = getSetClockMutationOptions(options);

  return useMutation(mutationOptions);
};
export const listPlatforms = (signal?: AbortSignal) => {
  return customInstance<PartnerPlatformResponse[]>({
    url: `/operations/own/setup/platforms`,
    method: 'GET',
    signal,
  });
};

export const getListPlatformsQueryKey = () => {
  return [`/operations/own/setup/platforms`] as const;
};

export const getListPlatformsQueryOptions = <
  TData = Awaited<ReturnType<typeof listPlatforms>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof listPlatforms>>,
    TError,
    TData
  >;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListPlatformsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listPlatforms>>> = ({
    signal,
  }) => listPlatforms(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof listPlatforms>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListPlatformsQueryResult = NonNullable<
  Awaited<ReturnType<typeof listPlatforms>>
>;
export type ListPlatformsQueryError = unknown;

export const useListPlatforms = <
  TData = Awaited<ReturnType<typeof listPlatforms>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof listPlatforms>>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListPlatformsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const createPlatform = (
  partnerPlatformRequest: PartnerPlatformRequest
) => {
  return customInstance<string>({
    url: `/operations/own/setup/platforms`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: partnerPlatformRequest,
  });
};

export const getCreatePlatformMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createPlatform>>,
    TError,
    { data: PartnerPlatformRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createPlatform>>,
  TError,
  { data: PartnerPlatformRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createPlatform>>,
    { data: PartnerPlatformRequest }
  > = (props) => {
    const { data } = props ?? {};

    return createPlatform(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreatePlatformMutationResult = NonNullable<
  Awaited<ReturnType<typeof createPlatform>>
>;
export type CreatePlatformMutationBody = PartnerPlatformRequest;
export type CreatePlatformMutationError = unknown;

export const useCreatePlatform = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createPlatform>>,
    TError,
    { data: PartnerPlatformRequest },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof createPlatform>>,
  TError,
  { data: PartnerPlatformRequest },
  TContext
> => {
  const mutationOptions = getCreatePlatformMutationOptions(options);

  return useMutation(mutationOptions);
};
export const listPlatforms1 = (
  params?: ListPlatforms1Params,
  signal?: AbortSignal
) => {
  return customInstance<BondsmithPagePartnerPlatformPaginatedResponse>({
    url: `/operations/own/setup/platforms/paginated`,
    method: 'GET',
    params,
    signal,
  });
};

export const getListPlatforms1QueryKey = (params?: ListPlatforms1Params) => {
  return [
    `/operations/own/setup/platforms/paginated`,
    ...(params ? [params] : []),
  ] as const;
};

export const getListPlatforms1QueryOptions = <
  TData = Awaited<ReturnType<typeof listPlatforms1>>,
  TError = unknown
>(
  params?: ListPlatforms1Params,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof listPlatforms1>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListPlatforms1QueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listPlatforms1>>> = ({
    signal,
  }) => listPlatforms1(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof listPlatforms1>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListPlatforms1QueryResult = NonNullable<
  Awaited<ReturnType<typeof listPlatforms1>>
>;
export type ListPlatforms1QueryError = unknown;

export const useListPlatforms1 = <
  TData = Awaited<ReturnType<typeof listPlatforms1>>,
  TError = unknown
>(
  params?: ListPlatforms1Params,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof listPlatforms1>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListPlatforms1QueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const fetchPlatform = (platformUid: string, signal?: AbortSignal) => {
  return customInstance<PartnerPlatformResponse>({
    url: `/operations/own/setup/platforms/${platformUid}`,
    method: 'GET',
    signal,
  });
};

export const getFetchPlatformQueryKey = (platformUid: string) => {
  return [`/operations/own/setup/platforms/${platformUid}`] as const;
};

export const getFetchPlatformQueryOptions = <
  TData = Awaited<ReturnType<typeof fetchPlatform>>,
  TError = unknown
>(
  platformUid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof fetchPlatform>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getFetchPlatformQueryKey(platformUid);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof fetchPlatform>>> = ({
    signal,
  }) => fetchPlatform(platformUid, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!platformUid,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof fetchPlatform>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type FetchPlatformQueryResult = NonNullable<
  Awaited<ReturnType<typeof fetchPlatform>>
>;
export type FetchPlatformQueryError = unknown;

export const useFetchPlatform = <
  TData = Awaited<ReturnType<typeof fetchPlatform>>,
  TError = unknown
>(
  platformUid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof fetchPlatform>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getFetchPlatformQueryOptions(platformUid, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};
