import React from 'react';

export function Deposits() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="72"
      height="73"
      viewBox="0 0 72 73">
      <g data-name="Group 3319" transform="translate(-.498)">
        <g data-name="Group 2995">
          <g data-name="Ellipse 28-4">
            <ellipse
              cx="36"
              cy="36.5"
              fill="#ebedff"
              data-name="Ellipse 37"
              rx="36"
              ry="36.5"
              transform="translate(.498)"></ellipse>
          </g>
        </g>
        <g data-name="Group 3103" transform="translate(19.032 23.555)">
          <path
            fill="#3b49d3"
            d="M586.3 127.843h-37.805a.948.948 0 01-.948-.948v-19.274a.948.948 0 01.948-.948H586.3a.948.948 0 01.948.948v19.274a.948.948 0 01-.948.948zm-36.857-1.9h35.909v-17.374h-35.909z"
            data-name="Path 2603"
            transform="translate(-547.547 -106.673)"></path>
        </g>
        <g data-name="Group 3104" transform="translate(14.152 28.335)">
          <path
            fill="#ebedff"
            d="M0 0H37.805V19.273H0z"
            data-name="Rectangle 1724"
            transform="translate(.948 .948)"></path>
          <path
            fill="#3b49d3"
            d="M579.866 134.145h-37.8a.948.948 0 01-.948-.948v-19.273a.947.947 0 01.948-.948h37.8a.947.947 0 01.948.948V133.2a.948.948 0 01-.948.945zm-36.857-1.9h35.909v-17.373h-35.909z"
            data-name="Path 2604"
            transform="translate(-541.113 -112.976)"></path>
        </g>
        <g data-name="Group 3105" transform="translate(28.112 20.181)">
          <path
            fill="#3b49d3"
            d="M584.312 104.12h-23.844a.948.948 0 010-1.9h23.844a.948.948 0 110 1.9z"
            data-name="Path 2605"
            transform="translate(-559.52 -102.224)"></path>
        </g>
        <g data-name="Group 3106" transform="translate(17.67 44.307)">
          <path
            fill="#3b49d3"
            d="M554.624 135.932H546.7a.948.948 0 010-1.9h7.924a.948.948 0 010 1.9z"
            data-name="Path 2606"
            transform="translate(-545.752 -134.036)"></path>
        </g>
        <g data-name="Group 3107" transform="translate(17.67 40.56)">
          <path
            fill="#3b49d3"
            d="M549.27 130.991h-2.57a.948.948 0 010-1.9h2.57a.948.948 0 110 1.9z"
            data-name="Path 2607"
            transform="translate(-545.752 -129.095)"></path>
        </g>
        <g data-name="Group 3108" transform="translate(26.739 31.656)">
          <path
            fill="#3b49d3"
            d="M564.973 131.881a7.264 7.264 0 117.263-7.263 7.272 7.272 0 01-7.263 7.263zm0-12.631a5.368 5.368 0 105.367 5.368 5.374 5.374 0 00-5.367-5.368z"
            data-name="Path 2608"
            transform="translate(-557.709 -117.354)"></path>
        </g>
        <g data-name="Group 3109" transform="translate(40.798 31.656)">
          <path
            fill="#3b49d3"
            d="M584.69 119.25h-7.5a.948.948 0 110-1.9h7.5a.948.948 0 010 1.9z"
            data-name="Path 2609"
            transform="translate(-576.247 -117.354)"></path>
        </g>
        <g data-name="Group 3110" transform="translate(45.402 34.995)">
          <path
            fill="#3b49d3"
            d="M585.835 123.653h-2.569a.948.948 0 010-1.9h2.569a.948.948 0 010 1.9z"
            data-name="Path 2610"
            transform="translate(-582.318 -121.757)"></path>
        </g>
        <g data-name="Group 3112" transform="translate(31.767 35.389)">
          <g fill="#3b49d3" data-name="Group 3111">
            <path
              d="M565.039 128.044a2.806 2.806 0 001.42.4c.809 0 1.283-.428 1.283-1.047 0-.573-.328-.9-1.156-1.218-1-.355-1.618-.874-1.618-1.738a1.765 1.765 0 011.982-1.665 2.783 2.783 0 011.355.3l-.219.645a2.443 2.443 0 00-1.164-.29c-.837 0-1.155.5-1.155.918 0 .573.373.855 1.219 1.182 1.037.4 1.565.9 1.565 1.8 0 .946-.7 1.765-2.147 1.765a3.172 3.172 0 01-1.564-.391z"
              data-name="Path 2611"
              transform="translate(-564.46 -122.398)"></path>
            <path
              d="M566.283 129.358a3.554 3.554 0 01-1.775-.454.379.379 0 01-.152-.425l.2-.664a.379.379 0 01.228-.245.383.383 0 01.334.032 2.428 2.428 0 001.22.344c.272 0 .9-.065.9-.667 0-.281-.069-.541-.913-.865-1.249-.443-1.862-1.129-1.862-2.091a2.147 2.147 0 012.362-2.045 3.114 3.114 0 011.543.35.379.379 0 01.171.451l-.219.646a.38.38 0 01-.541.211 2.068 2.068 0 00-.982-.244c-.571 0-.775.278-.775.539s.068.476.976.829a2.314 2.314 0 011.807 2.154 2.221 2.221 0 01-2.522 2.144zm.8-3.437a1.46 1.46 0 01.912 1.357 1.379 1.379 0 01-.173.686 1.278 1.278 0 00.224-.749c.005-.515-.167-.915-.958-1.295zm-1.722-2.173a1.187 1.187 0 00-.141.574c0 .385.133.87 1.107 1.279a1.488 1.488 0 01-1.065-1.37 1.229 1.229 0 01.104-.483z"
              data-name="Path 2612"
              transform="translate(-564.34 -122.277)"></path>
          </g>
        </g>
        <g data-name="Group 3113" transform="translate(17.67 31.656)">
          <path
            fill="#3b49d3"
            d="M553.125 119.25H546.7a.948.948 0 010-1.9h6.424a.948.948 0 010 1.9z"
            data-name="Path 2613"
            transform="translate(-545.752 -117.354)"></path>
        </g>
        <g data-name="Group 3114" transform="translate(47.222 43.232)">
          <path
            fill="#3b49d3"
            d="M586.04 135.263a1.323 1.323 0 111.323-1.323 1.324 1.324 0 01-1.323 1.323zm0-1.9a.573.573 0 10.573.573.573.573 0 00-.573-.569z"
            data-name="Path 2614"
            transform="translate(-584.717 -132.618)"></path>
        </g>
      </g>
    </svg>
  );
}
