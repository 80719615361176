import { FeatureDialog } from '@b7hio/core-lib/src';
import { useTranslation } from 'next-i18next';
import { ProductDocuments } from '../../hooks/useProductInteractivity';
import { BankProductForm, ProductForm } from '../ProductForm';
import { BankProductRequestWithRateDetail } from '../../../../../../api-lib/src/gen/bank-portal-api';

interface CommonProps {
  readonly open: boolean;
  readonly onClose: (event: object, reason: string) => void;
}

type BanksProps = CommonProps & {
  readonly onSubmit: (
    values: BankProductRequestWithRateDetail,
    files: ProductDocuments
  ) => Promise<void>;
};

export const BanksProductCreateDialog = ({
  open,
  onClose,
  onSubmit,
}: BanksProps): JSX.Element => {
  const { t } = useTranslation(['products']);
  return (
    <FeatureDialog
      id="ProductCreateDialog"
      open={open}
      title={t('products:addProduct.title')}
      onClose={onClose}>
      <BankProductForm onSubmit={onSubmit} product={undefined} />
    </FeatureDialog>
  );
};

type OpsProps = CommonProps & {
  readonly onSubmit: (
    values: BankProductRequestWithRateDetail,
    files: ProductDocuments
  ) => Promise<void>;
};

export const OpsProductCreateDialog = ({
  open,
  onClose,
  onSubmit,
}: OpsProps): JSX.Element => {
  const { t } = useTranslation(['products']);
  return (
    <FeatureDialog
      id="ProductCreateDialog"
      open={open}
      title={t('products:addProduct.title')}
      onClose={onClose}>
      <ProductForm onSubmit={onSubmit} product={undefined} />
    </FeatureDialog>
  );
};
