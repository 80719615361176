import { InterestAccrualPeriodType } from '@b7hio/api-lib/src/ops-portal';

export enum MappedAccrualPeriodType {
  DAILY = 'DAILY',
  MONTHLY = 'MONTHLY',
  ANNUALLY = 'ANNUALLY',
  PRODUCT_TERM = 'PRODUCT_TERM',
}

// TODO: This whole file is a temporary hack until we sort out the product_term accrual type in core,
// so use and extend with caution!

/**
 * Map the given accrual type from the BE version to the equivalent FE version.
 * If a conversion cannot be made, return undefined.
 *
 * Most types convert one-to-one, the exception is the FE only 'PRODUCT_TERM' type, which
 * is a stand-in for all of the InterestAccrualPeriodType.EVERY_X_MONTH BE periods.
 *
 * @param type The input BE type to convert
 */
export const mapAccrualPeriodsForFE = (type: InterestAccrualPeriodType) => {
  if (type == InterestAccrualPeriodType.DAILY) {
    return MappedAccrualPeriodType.DAILY;
  } else {
    return undefined;
  }
};
