import {
  BankProductResponse,
  BankProductUpdateWithAccountDetailRequest,
  CoolOffPeriodType,
  CurrencyCode,
  ExoticType,
  HolidayCalendarType,
  InterestPayoutPeriodType,
  InternalTaxWrapper,
  listAccountsForBanks,
  ProductType,
  RequestAccountHolderType,
  UKVerifiedPaymentAccountResponse,
} from '@b7hio/api-lib/src/ops-portal';
import {
  FormAutocomplete,
  FormDatePicker,
  FormTextField,
  FormTimePicker,
  MaskedTextField,
} from '@b7hio/core-lib/src/components';
import { FormFriendly, superstructResolver } from '@b7hio/core-lib/src/form';
import {
  Autocomplete,
  Button,
  Chip,
  Divider,
  styled,
  TextField as MuiTextField,
  TextField,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { UseFormSetError } from 'react-hook-form/dist/types/form';
import { useTranslation } from 'react-i18next';
import { create } from 'superstruct';
import { ProductDocuments } from '../../hooks/useProductInteractivity';
import {
  AddProductValidationWithAccountDetails,
  EditProductValidationWithAccountDetails,
} from './ProductForm.validation';
import { FormDateTimePicker } from '@b7hio/core-lib/src/components/Form/FormDateTimePicker';

type BankProductResponseForUpdate = Omit<
  BankProductResponse,
  | 'productUid'
  | 'bank'
  | 'createdAt'
  | 'updatedAt'
  | 'feeDetails'
  | 'rateDetails'
  | 'proposalUid'
  | 'currentState'
>;

type Props<P, S = BankProductUpdateWithAccountDetailRequest> = {
  readonly product: P;
  readonly onSubmit: (
    values: S,
    files: ProductDocuments,
    setError: UseFormSetError<S>
  ) => Promise<void>;
};

export function UpdateProductForm<P extends BankProductResponseForUpdate>({
  onSubmit,
  product,
}: Props<P>) {
  const updateProductDefaults: FormFriendly<BankProductUpdateWithAccountDetailRequest> =
    {
      externalId: product.externalId,
      name: product.name,
      productType: product.productType,
      exoticType: product.exoticType,
      currency: product.currency,
      accountHolderTypes: product.accountHolderTypes,
      depositRequirement: product.depositRequirement,
      interestFeature: product.interestFeature,
      periodFeature: product.periodFeature,
      maximumAvailable: product.maximumAvailable,
      accountDetails: product.accountDetails,
      holidayCalendar: product.holidayCalendar,
      productAvailability: product.productAvailability,
      changeEffectiveFrom: '',
      taxWrappers: product.taxWrappers,
      stopDisplayAt: product.stopDisplayAt,
      productLiterature: {
        tandcUrl: product.productLiterature?.tandcUrl,
        brochureUrl: product.productLiterature?.brochureUrl,
      },
      startDate: product.startDate ? product.startDate : '',
      isTracker: product.isTracker ? product.isTracker.toString() : 'false',
    };

  const accountNumRef = useRef<string | undefined>(undefined);
  const sortcodeRef = useRef<string | undefined>(undefined);

  const {
    handleSubmit,
    setError,
    formState,
    watch,
    control,
    setValue,
    getValues,
  } = useForm<FormFriendly<BankProductUpdateWithAccountDetailRequest>>({
    mode: 'onSubmit',
    resolver: superstructResolver(
      EditProductValidationWithAccountDetails(accountNumRef, sortcodeRef),
      { coerce: true }
    ),
    defaultValues: updateProductDefaults,
  });

  const accountNumInput = watch('accountDetails.accountNumber');
  const sortcodeInput = watch('accountDetails.sortCode');

  const { isSubmitting } = formState;
  const { t } = useTranslation(['common', 'products']);

  const productType = watch('productType');
  const fixedTaxWrapperOptions = product.taxWrappers;
  const [taxWrapperOptions, setTaxWrapperOptions] = useState([
    ...fixedTaxWrapperOptions,
  ]);

  const [tandcUrl, setTandcFile] = useState<File | undefined>(undefined);
  const [brochureUrl, setBrochureFile] = useState<File | undefined>(undefined);
  const [infoUrl, setFscsInfoFile] = useState<File | undefined>(undefined);

  const [paymentAccounts, setPaymentAccounts] = useState<
    readonly UKVerifiedPaymentAccountResponse[]
  >([]);
  const [manualPayment, setManualPayment] = useState<boolean>(false);
  const [selectedPaymentAccount, setSelectedPaymentAccount] = useState<{
    readonly sortCode: string;
    readonly accountNumber: string;
  } | null>();

  const fetchPaymentAccounts = async () => {
    setPaymentAccounts(
      await listAccountsForBanks({ accountOwnerUid: product.bankUid })
    );
  };

  const handleSelectPaymentAccount = (
    sortCode: string | null,
    accountNumber: string | null
  ) => {
    setSelectedPaymentAccount(
      sortCode && accountNumber ? { sortCode, accountNumber } : null
    );
    setValue('accountDetails.sortCode', sortCode ?? '');
    setValue('accountDetails.accountNumber', accountNumber ?? '');
  };

  const booleanOptions = [
    {
      label: 'Yes',
      value: 'true',
    },
    {
      label: 'No',
      value: 'false',
    },
  ];

  useEffect(() => {
    if (productType === ProductType.NOTICE) {
      setValue('periodFeature.termPeriod', undefined);
    }
    if (productType === ProductType.TERM) {
      setValue('periodFeature.noticePeriod', undefined);
    }
  }, [productType]);

  useEffect(() => {
    if (product) {
      setManualPayment(true);
      fetchPaymentAccounts();
    }
  }, [product]);

  useEffect(() => {
    accountNumRef.current = accountNumInput;
    sortcodeRef.current = sortcodeInput;
  }, [accountNumInput, sortcodeInput]);

  return (
    <form
      onSubmit={handleSubmit(
        async (values) => {
          if (product) {
            const output = create(
              values,
              EditProductValidationWithAccountDetails(
                accountNumRef,
                sortcodeRef
              )
            );

            const productDocs: ProductDocuments = {
              tandcUrl: tandcUrl,
              brochureUrl: brochureUrl,
            };
            // @ts-expect-error TODO
            await onSubmit(output, productDocs, setError);
          } else {
            const output = create(
              values,
              AddProductValidationWithAccountDetails(accountNumRef, sortcodeRef)
            );
            // @ts-expect-error TODO
            await onSubmit(output, setError);
          }
        },
        // eslint-disable-next-line no-console
        (errors) => console.log(errors, getValues())
      )}
      style={{ gridArea: 'form' }}>
      <FormContainer>
        <FormTextField
          name="name"
          label={t('products:addProduct.name')}
          t={t}
          control={control}
          data-testid="productName"
        />
        <FormTextField
          control={control}
          name="externalId"
          label={t('products:addProduct.externalId')}
          t={t}
        />
        <FormAutocomplete
          name="currency"
          data-testid="currency"
          label={t('products:addProduct.currency')}
          options={Object.values(CurrencyCode).map((v) => {
            return {
              label: t(`products:addProduct.currencyCodes.${v}`),
              value: v,
            };
          })}
          t={t}
          control={control}
        />
        <Autocomplete
          multiple
          id="taxWrappers"
          value={taxWrapperOptions}
          onChange={(event, newValue) => {
            setTaxWrapperOptions([
              ...fixedTaxWrapperOptions,
              ...newValue.filter(
                (option) => !fixedTaxWrapperOptions.includes(option)
              ),
            ]);
            setValue('taxWrappers', newValue);
          }}
          options={Object.values(InternalTaxWrapper)}
          getOptionLabel={(option) => option}
          renderTags={(tagValue, getTagProps) => {
            return tagValue.map((option, index) => (
              <Chip
                label={option}
                {...getTagProps({ index })}
                disabled={fixedTaxWrapperOptions.includes(option)}
              />
            ));
          }}
          style={{ width: '100%' }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t('products:addProduct.taxWrappers')}
            />
          )}
        />
        <FormAutocomplete
          // TODO: translation for options
          options={Object.values(ProductType).map((v) => {
            return {
              label: t(`products:productTypes.${v}`),
              value: v,
            };
          })}
          name="productType"
          label={t('products:addProduct.type')}
          t={t}
          control={control}
          data-testid="productType"
        />
        {productType === ProductType.TERM && (
          <FormTextField
            name="periodFeature.termPeriod"
            label={t('products:addProduct.termPeriod')}
            control={control}
            t={t}
          />
        )}
        <FormAutocomplete
          name="exoticType"
          label={t('products:addProduct.exoticType')}
          options={Object.values(ExoticType).map((v) => {
            return {
              label: t(`products:addProduct.exoticTypes.${v}`),
              value: v,
            };
          })}
          control={control}
          t={t}
        />
        <FormAutocomplete
          multiple
          name="accountHolderTypes"
          label={t('products:addProduct.accountHolderTypes')}
          options={Object.values(RequestAccountHolderType).map((v) => {
            return {
              label: t(`products:addProduct.accountHolderTypesValues.${v}`),
              value: v,
            };
          })}
          control={control}
          t={t}
        />
        <FormTextField
          name="depositRequirement.min"
          label={t('products:addProduct.minDeposit')}
          t={t}
          control={control}
          currencyFormat
        />
        <FormTextField
          name="depositRequirement.max"
          label={t('products:addProduct.maxDeposit')}
          t={t}
          control={control}
          currencyFormat
        />
        {productType === ProductType.NOTICE && (
          <FormTextField
            name="periodFeature.noticePeriod"
            label={t('products:addProduct.noticePeriod')}
            control={control}
            t={t}
          />
        )}
        <FormAutocomplete
          name="interestFeature.payoutPeriod"
          label={t('products:addProduct.interestPaymentPeriod')}
          // TODO: translation for options
          options={Object.values(InterestPayoutPeriodType).map((v) => {
            return {
              label: t(`products:payoutPeriod.${v}`),
              value: v,
            };
          })}
          control={control}
          t={t}
        />
        <FormTimePicker
          name="interestFeature.interestCutOffTime"
          control={control}
          label={t('products:addProduct.interestCutOffTime')}
          t={t}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          getErrorPath={(type) => t(`common:validation.time.${type}` as any)}
        />
        <Divider sx={{ mb: 2 }} />
        {!manualPayment && (
          <Autocomplete
            renderInput={(params) => (
              <TextField
                {...params}
                label={t('products:addProduct.bankAccount')}
              />
            )}
            options={paymentAccounts.map((v) => {
              return {
                label: t('products:addProduct.bankAccountLabel', {
                  sortCode: v.sortCode,
                  accountNumber: v.accountNumber,
                }),
                account: {
                  sortCode: v.sortCode,
                  accountNumber: v.accountNumber,
                },
              };
            })}
            onChange={(event, newValue) => {
              handleSelectPaymentAccount(
                newValue?.account.sortCode ?? null,
                newValue?.account.accountNumber ?? null
              );
            }}
          />
        )}
        {(selectedPaymentAccount || manualPayment) && (
          <MaskedTextField
            label={t('products:addProduct.sortCode')}
            name="accountDetails.sortCode"
            t={t}
            inputProps={{
              mask: '00-00-00',
            }}
            control={control}
          />
        )}
        {(selectedPaymentAccount || manualPayment) && (
          <FormTextField
            name="accountDetails.accountNumber"
            label={t('products:addProduct.accountNumber')}
            control={control}
            disabled={!manualPayment}
            t={t}
            data-testid="accountDetails.accountNumber"
          />
        )}
        <Button
          color="primary"
          variant="outlined"
          onClick={() => setManualPayment(!manualPayment)}
          disabled={isSubmitting}>
          {manualPayment
            ? t('products:addProduct.choosePaymentBtn')
            : t('products:addProduct.manualPaymentBtn')}
        </Button>
        <Divider sx={{ pb: 2, mb: 2 }} />

        <FormAutocomplete
          name="holidayCalendar"
          label={t('products:addProduct.holidayCalendar')}
          // TODO: translation for options
          options={Object.values(HolidayCalendarType).map((v) => {
            return {
              label: t(`products:addProduct.holidayCalendarTypes.${v}`),
              value: v,
            };
          })}
          control={control}
          t={t}
        />
        <FormDatePicker
          name="productAvailability.availableFrom"
          control={control}
          label={t('products:addProduct.availableFrom')}
          t={t}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          getErrorPath={(type) => t(`common:validation.date.${type}` as any)}
        />
        {productType === ProductType.TERM && (
          <FormDatePicker
            name="productAvailability.availableUntil"
            control={control}
            label={t('products:addProduct.availableUntil')}
            t={t}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            getErrorPath={(type) => t(`common:validation.date.${type}` as any)}
          />
        )}
        <FormDateTimePicker
          name="stopDisplayAt"
          control={control}
          label={t('products:addProduct.stopDisplayAt')}
          t={t}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          getErrorPath={(type) => t(`common:validation.date.${type}` as any)}
        />
        <FormAutocomplete
          name="periodFeature.coolOffPeriod"
          label={t('products:addProduct.coolOffPeriod')}
          // TODO: translation for options
          options={Object.values(CoolOffPeriodType).map((v) => {
            return {
              label: t(`products:addProduct.coolOffPeriodTypes.${v}`),
              value: v,
            };
          })}
          control={control}
          t={t}
        />
        <FormTextField
          name="maximumAvailable"
          label={t('products:addProduct.maximumAvailable')}
          control={control}
          t={t}
          currencyFormat
        />
        <TextField
          name="isCompositeProduct"
          label={t('products:addProduct.isCompositeProduct')}
          value={product.compositeProduct ? 'true' : 'false'}
          disabled
        />
        <FormAutocomplete
          name="isTracker"
          label={t('products:addProduct.isTracker')}
          options={booleanOptions}
          control={control}
          t={t}
        />
        <MuiTextField
          type="file"
          label={t('products:addProduct.termsAndConditions')}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            const file = event.target?.files?.[0];
            if (file && file.name !== '') {
              setTandcFile(file);
            }
          }}
        />
        <MuiTextField
          type="file"
          label={t('products:addProduct.productBrochure')}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            const file = event.target?.files?.[0];
            if (file && file.name !== '') {
              setBrochureFile(file);
            }
          }}
        />
        <MuiTextField
          type="file"
          label={t('products:addProduct.FSCS')}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            const file = event.target?.files?.[0];
            if (file && file.name !== '') {
              setFscsInfoFile(file);
            }
          }}
        />
        <Divider sx={{ pb: 2, mb: 2 }} />
        <FormDatePicker
          name="changeEffectiveFrom"
          control={control}
          label={t('products:addProduct.changeEffectiveFrom')}
          t={t}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          getErrorPath={(type) => t(`common:validation.date.${type}` as any)}
        />
        <Button
          sx={{ mt: 2, mb: 2 }}
          color="primary"
          variant="contained"
          type="submit"
          disabled={isSubmitting}>
          {t('products:editProduct.submit')}
        </Button>
      </FormContainer>
    </form>
  );
}

const FormContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  '> div': {
    marginBottom: theme.spacing(2),

    '&:last-of-type': {
      marginBottom: 0,
    },
  },
}));
