import {
  BondsmithPageListableBankProductPaginatedResponse,
  ListableBankProductPaginatedResponse,
  ListableBankProductResponse,
} from '@b7hio/api-lib/src/ops-portal';
import { EventContextProvider } from '@b7hio/core-lib/src';
import { FormFriendly, superstructResolver } from '@b7hio/core-lib/src/form';
import {
  getSessionValue,
  ProductListFilter,
  ProductListFilterValidation,
  ProductListFilterValues,
} from '@b7hio/ops-ui-lib/src';
import CopyAllRounded from '@mui/icons-material/CopyAllRounded';
import DeleteRounded from '@mui/icons-material/DeleteRounded';
import EditRounded from '@mui/icons-material/EditRounded';
import VisibilityRounded from '@mui/icons-material/VisibilityRounded';
import CancelIcon from '@mui/icons-material/Cancel';
import {
  Box,
  Button,
  FormControlLabel,
  FormGroup,
  Paper,
  Switch,
  styled,
} from '@mui/material';
import type {
  GridColDef,
  GridRowParams,
  GridSortModel,
} from '@mui/x-data-grid-pro';
import {
  DataGridPro as MuiDataGrid,
  GridActionsCellItem,
} from '@mui/x-data-grid-pro';
import { useTranslation } from 'next-i18next';
import React, { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Subject } from 'rxjs';
import { create } from 'superstruct';
import { useDebouncedCallback } from 'use-debounce';
import * as uuid from 'uuid';
import { initialValues } from '../ProductListFilter/ProductListFilter.validation';
import { ProductListEvents } from './ProductList.events';

interface Props {
  readonly data?: BondsmithPageListableBankProductPaginatedResponse;
  readonly isLoading: boolean;
  readonly onRequestUpdate: (product: ListableBankProductResponse) => void;
  readonly onRequestUpdateRate: (product: ListableBankProductResponse) => void;
  readonly onRequestModifyAccess: (
    product: ListableBankProductResponse
  ) => void;
  readonly onRequestDelete: (product: ListableBankProductResponse) => void;
  readonly onRequestSoftClose: (product: ListableBankProductResponse) => void;
  readonly onRequestDuplicate: (product: ListableBankProductResponse) => void;
  readonly onFilterChange: (value: ProductListFilterValues) => void;
  readonly noList?: boolean;
  readonly page: 'viewPage' | 'productsList';
  readonly portal: 'banks' | 'ops';
  readonly pageNumber: number;
  readonly setPageNumber: (pageNum: number) => void;
  readonly sortModel?: GridSortModel;
  readonly setSortModel: (newModel: GridSortModel) => void;
  readonly resultsPerPage: number;
  readonly onRequestView?: (product: ListableBankProductResponse) => void;
}

export const ProductList = ({
  data,
  isLoading,
  onRequestUpdate,
  onRequestUpdateRate,
  onRequestModifyAccess,
  onRequestDelete,
  onRequestSoftClose,
  onRequestDuplicate,
  onFilterChange,
  noList,
  portal,
  page,
  pageNumber = 0,
  setPageNumber,
  sortModel,
  setSortModel,
  resultsPerPage,
  onRequestView,
}: Props): JSX.Element => {
  const subject = useMemo(() => new Subject<ProductListEvents>(), []);
  const columns = useProductColumns(subject, portal, page);

  useEffect(() => {
    const subscription = subject.subscribe(({ action, payload }) => {
      switch (action) {
        case 'update':
          onRequestUpdate(payload);
          break;
        case 'update-rate':
          onRequestUpdateRate(payload);
          break;
        case 'modify-access':
          onRequestModifyAccess(payload);
          break;
        case 'delete':
          onRequestDelete(payload);
          break;
        case 'soft-close':
          onRequestSoftClose(payload);
          break;
        case 'duplicate':
          onRequestDuplicate(payload);
          break;
        case 'view':
          onRequestView ? onRequestView(payload) : undefined;
        default:
          break;
      }
    });

    return () => subscription.unsubscribe();
  }, [
    subject,
    onRequestUpdateRate,
    onRequestUpdate,
    onRequestDelete,
    onRequestDuplicate,
  ]);

  const methods = useForm<FormFriendly<ProductListFilterValues>>({
    mode: 'onChange',
    resolver: superstructResolver(ProductListFilterValidation, {
      coerce: true,
    }),
    defaultValues: getSessionValue<ProductListFilterValues>(
      'products_list_filter',
      initialValues
    ),
  });

  const submitForm = useDebouncedCallback(
    async (values: FormFriendly<ProductListFilterValues>) => {
      await onFilterChange(create(values, ProductListFilterValidation));
    },
    200
  );

  const rows = data?.content?.map((item) => {
    const id = uuid.v4();
    return {
      ...item,
      rowId: id,
    };
  });

  useEffect(() => {
    const subscription = methods.watch(submitForm);
    return () => subscription.unsubscribe();
  }, [methods.watch, onFilterChange, submitForm]);

  const ListComponent = noList ? React.Fragment : ProductsListContainer;

  const [rowCountState, setRowCountState] = useState(data?.totalSize || 0);

  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      data?.totalSize !== undefined ? data?.totalSize : prevRowCountState
    );
  }, [data?.totalSize, setRowCountState]);

  return (
    <ListComponent>
      <ProductsListToolbar>
        <FormProvider {...methods}>
          <ProductListFilter portal={portal} />
        </FormProvider>
      </ProductsListToolbar>
      <Box sx={{ gridArea: 'spacer' }} />
      <ProductsGridContainer>
        <EventContextProvider subject={subject}>
          <DataGrid
            getRowId={(row) => row.rowId}
            pagination
            pageSize={resultsPerPage}
            columns={columns}
            loading={isLoading}
            rows={rows ?? []}
            rowsPerPageOptions={[]}
            disableColumnMenu
            className="productList"
            disableSelectionOnClick
            paginationMode="server"
            page={pageNumber}
            onPageChange={(newPageNumber) => setPageNumber(newPageNumber)}
            rowCount={rowCountState}
            sortingMode="server"
            sortModel={sortModel}
            onSortModelChange={setSortModel}
          />
        </EventContextProvider>
      </ProductsGridContainer>
    </ListComponent>
  );
};

function useProductColumns(
  subject: Subject<ProductListEvents>,
  portal: 'banks' | 'ops',
  page: 'viewPage' | 'productsList'
): GridColDef[] {
  const { t } = useTranslation(['products', 'common']);
  const getActions = (params: GridRowParams) =>
    [
      portal === 'banks' && (
        <GridActionsCellItem
          test-id="View"
          showInMenu
          icon={<VisibilityRounded />}
          onClick={() =>
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            subject.next({ action: 'view', payload: params.row as any })
          }
          label={t('products:actions.view')}
        />
      ),
      portal === 'ops' && (
        <GridActionsCellItem
          test-id="Update"
          disabled={params.row.state === 'PENDING'}
          showInMenu
          icon={<EditRounded />}
          onClick={() =>
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            subject.next({ action: 'update', payload: params.row as any })
          }
          label={t('products:actions.update')}
        />
      ),
      <GridActionsCellItem
        test-id="UpdateRate"
        showInMenu
        icon={<EditRounded />}
        onClick={() =>
          subject.next({
            action: 'update-rate',
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            payload: params.row as any,
          })
        }
        label={t('products:actions.updateRate')}
        disabled={
          params.row.changes.changeType === 'PRODUCT_RATE_UPDATE' ||
          params.row.state === 'PENDING' ||
          params.row.productType === 'TERM'
        }
      />,
      portal === 'ops' && (
        <GridActionsCellItem
          test-id="Delete"
          showInMenu
          icon={<DeleteRounded />}
          onClick={() =>
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            subject.next({ action: 'delete', payload: params.row as any })
          }
          label={t('products:actions.delete')}
          disabled={params.row.status !== 'ACTIVE'}
        />
      ),
      portal === 'ops' && (
        <GridActionsCellItem
          test-id="Duplicate"
          showInMenu
          icon={<CopyAllRounded />}
          onClick={() => {
            const check = confirm(t('products:feedback.duplicateFinalCheck'));
            if (check) {
              subject.next({ action: 'duplicate', payload: params.row });
            }
          }}
          label={t('products:actions.duplicate')}
          disabled={params.row.state !== 'ACTIVE'}
        />
      ),
      portal === 'ops' && (
        <GridActionsCellItem
          test-id="Soft Close"
          showInMenu
          icon={<CancelIcon />}
          onClick={() => {
            subject.next({ action: 'soft-close', payload: params.row });
          }}
          label={t('products:actions.softCloseProduct')}
          disabled={
            params.row.changes.changeType === 'PRODUCT_RATE_UPDATE' ||
            params.row.state === 'PENDING' ||
            params.row.state === 'CLOSED' ||
            params.row.state === 'SOFT_CLOSED'
          }
        />
      ),
      <GridActionsCellItem
        showInMenu
        icon={<CancelIcon />}
        onClick={() =>
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          subject.next({ action: 'delete', payload: params.row as any })
        }
        label={t('products:actions.closeProduct')}
        disabled={
          params.row.changes.changeType === 'PRODUCT_RATE_UPDATE' ||
          params.row.state === 'PENDING' ||
          params.row.state === 'CLOSED'
        }
      />,
      portal === 'ops' && (
        <GridActionsCellItem
          icon={<VisibilityRounded />}
          test-id="Open"
          disabled={params.row.state === 'PENDING'}
          onClick={() =>
            subject.next({
              action: 'modify-access',
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              payload: params.row as any,
            })
          }
          label={t('products:actions.modifyAccess')}
        />
      ),
    ].filter(Boolean);

  return [
    {
      field: 'productName',
      headerName: t('products:tableColumns.name'),
      width: 200,
    },
    {
      field: 'productType',
      headerName: t('products:tableColumns.type'),
      width: 125,
      valueFormatter({ value }) {
        return t(
          `products:productTypes.${
            value as ListableBankProductResponse['productType']
          }`
        );
      },
    },
    {
      field: 'period',
      headerName: t('products:tableColumns.term'),
      width: 125,
      valueGetter: ({ row }) => {
        const data = row as ListableBankProductPaginatedResponse;
        switch (row.productType) {
          case 'INSTANT':
            return 'Call';
          case 'TERM':
            return (data.period as number) + 'M';
          case 'NOTICE':
            return (data.period as number) + 'D';
          default:
            'Undefined';
        }
      },
    },
    {
      field: 'bankMeta.partnerTradingName',
      headerName: t('products:tableColumns.bank'),
      width: 150,
      hide: portal === 'banks',
      renderCell: ({ row }) => {
        const data = row as ListableBankProductResponse;
        return (
          <Button
            component="a"
            sx={{ justifyContent: 'flex-start', overflow: 'hidden' }}
            href={`/banks/${data.bankMeta.partnerUid}`}>
            {data.bankMeta.partnerTradingName
              ? data.bankMeta.partnerTradingName
              : data.bankMeta.partnerLegalName}
          </Button>
        );
      },
    },
    {
      field: 'grossRate',
      headerName: t('products:tableColumns.grossRate'),
      width: 150,
      type: 'number',
      valueGetter: ({ row }) => {
        const data = row as ListableBankProductResponse;
        return data.grossRate * 100;
      },
      valueFormatter: ({ value }) => {
        return `${(value as number).toFixed(2)}%`;
      },
    },
    {
      field: 'isTracker',
      headerName: t('products:tableColumns.isTracker'),
      width: 100,
      valueFormatter: ({ value }) => {
        return value ? 'Yes' : 'No';
      },
    },
    {
      field: 'tranchePendingAmount',
      headerName: t('products:tableColumns.subscribed'),
      width: 150,
      type: 'number',
      valueFormatter: ({ value }) =>
        t('common:currency', {
          value: {
            currency: 'GBP',
            amount: value,
          },
        }),
    },
    {
      field: 'held',
      headerName: t('products:tableColumns.held'),
      width: 150,
      type: 'number',
      valueGetter: ({ row }) => {
        const data = row as ListableBankProductResponse;
        return data.held;
      },
      valueFormatter: ({ value }) =>
        t('common:currency', {
          value: {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            currency: (value as any).currencyCode,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            amount: (value as any).amount,
          },
        }),
    },
    {
      field: 'max',
      headerName: t('products:tableColumns.max'),
      width: 150,
      type: 'number',
      valueFormatter: ({ value }) =>
        t('common:currency', {
          value: {
            currency: 'GBP',
            amount: value,
          },
        }),
    },
    {
      field: 'state',
      headerName: t('products:tableColumns.status'),
      width: 280,
      valueGetter: ({ row }) => {
        const state = t(
          `products:productStates.${
            row.state as ListableBankProductResponse['state']
          }`
        );
        const changes = row.changes;

        if (changes && (state === 'Active' || state === 'Soft closed')) {
          if (changes.changeType === 'PRODUCT_CLOSE_PROPOSED') {
            return (
              state + ' - ' + t('products:tableColumns.productCloseProposed')
            );
          } else if (changes.changeType === 'PRODUCT_CLOSE') {
            return (
              state +
              ' - ' +
              t('products:tableColumns.productClose', {
                initiator: changes.activeFrom,
              })
            );
          } else if (changes.changeType === 'PRODUCT_RATE_UPDATE_PROPOSED') {
            return (
              state +
              ' - ' +
              t('products:tableColumns.rateProposedChangeDisplay', {
                initiator: (changes.value * 100 ?? 0.0).toFixed(2),
              })
            );
          } else if (changes.changeType === 'PRODUCT_RATE_UPDATE') {
            return (
              state +
              ' - ' +
              t('products:tableColumns.rateChangeDisplay', {
                initiator: (changes.value * 100 ?? 0.0).toFixed(2),
              })
            );
          } else if (changes.changeType === 'PRODUCT_SOFT_CLOSE_PROPOSED') {
            return (
              state +
              ' - ' +
              t('products:tableColumns.productSoftCloseProposed')
            );
          } else if (changes.changeType === 'PRODUCT_SOFT_CLOSE') {
            return (
              state +
              ' - ' +
              t('products:tableColumns.productSoftClose', {
                initiator: changes.activeFrom,
              })
            );
          }
        }
        return state;
      },
    },
    {
      field: 'createdAt',
      headerName: t('products:tableColumns.date'),
      width: 125,
      type: 'dateTime',
      valueFormatter: ({ value }) =>
        t('common:date', {
          value: new Date(value as string),
        }),
    },
    {
      field: 'startDate',
      headerName: t('products:tableColumns.startDate'),
      width: 125,
      type: 'dateTime',
      valueFormatter: ({ value }) => {
        return value
          ? t('common:date', {
              value: new Date(value as string),
            })
          : null;
      },
    },
    {
      field: 'bondsmithFee',
      hide: page === 'productsList',
      headerName: t('products:tableColumns.bondsmithFee'),
      width: 150,
      type: 'number',
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: t('products:tableColumns.actions'),
      width: 150,
      sortable: false,
      resizable: false,
      // @ts-expect-error TODO refactor
      getActions,
    },
  ];
}

export const ProductsListToolbar = styled('div')(({ theme }) => ({
  display: 'grid',
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  borderBottom: `1px solid ${theme.palette.divider}`,
  gridTemplateAreas: `"productName productType" "showClosed showHistorical"`,
  gridTemplateColumns: `repeat(2, 1fr)`,
  gridGap: theme.spacing(1),

  [theme.breakpoints.up('md')]: {
    gridTemplateAreas: `"productName productType spacer showClosed showHistorical"`,
    gridTemplateColumns: `repeat(2, minmax(${theme.spacing(
      26
    )}, auto)) 1fr repeat(2, auto)`,
  },
}));

export const ProductsListContainer = styled(Paper)`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const ProductsGridContainer = styled('div')(() => ({
  flexGrow: 2,
  '.MuiDataGrid-root': {
    border: 'none',
  },
}));

const DataGrid = styled(MuiDataGrid)``;
