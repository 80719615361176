import React from 'react';

export function Banks() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="70.095"
      height="70.095"
      data-name="Group 3316"
      viewBox="0 0 70.095 70.095">
      <g data-name="Group 2994">
        <g data-name="Ellipse 28-3">
          <ellipse
            cx="35.047"
            cy="35.048"
            fill="#ebedff"
            data-name="Ellipse 34"
            rx="35.047"
            ry="35.048"></ellipse>
        </g>
      </g>
      <g data-name="Group 3069" transform="translate(19.126 29.495)">
        <path
          fill="#3b49d3"
          d="M0 0H1.896V16.49H0z"
          data-name="Rectangle 1714"></path>
      </g>
      <g data-name="Group 3070" transform="translate(24.21 29.495)">
        <path
          fill="#3b49d3"
          d="M0 0H1.896V16.49H0z"
          data-name="Rectangle 1715"></path>
      </g>
      <g data-name="Group 3071" transform="translate(31.755 29.495)">
        <path
          fill="#3b49d3"
          d="M0 0H1.896V16.49H0z"
          data-name="Rectangle 1716"></path>
      </g>
      <g data-name="Group 3072" transform="translate(36.84 29.495)">
        <path
          fill="#3b49d3"
          d="M0 0H1.896V16.49H0z"
          data-name="Rectangle 1717"></path>
      </g>
      <g data-name="Group 3073" transform="translate(44.385 29.495)">
        <path
          fill="#3b49d3"
          d="M0 0H1.896V16.49H0z"
          data-name="Rectangle 1718"></path>
      </g>
      <g data-name="Group 3074" transform="translate(49.47 29.495)">
        <path
          fill="#3b49d3"
          d="M0 0H1.896V16.49H0z"
          data-name="Rectangle 1719"></path>
      </g>
      <g data-name="Group 3079" transform="translate(14.681 45.643)">
        <g data-name="Group 3076">
          <g data-name="Group 3075">
            <path
              fill="#3b49d3"
              d="M239.784 141.652h-20.672v-3.7a3.34 3.34 0 01.677-2.417 2.632 2.632 0 012.075-.859h18.1v1.9h-18.1c-.46 0-.6.156-.644.207a1.512 1.512 0 00-.22 1l.008.119v1.861h18.776z"
              data-name="Path 2583"
              transform="translate(-219.096 -134.671)"></path>
          </g>
        </g>
        <g data-name="Group 3078" transform="translate(19.773)">
          <g data-name="Group 3077">
            <path
              fill="#3b49d3"
              d="M266.024 141.652h-20.671v-1.9h18.775l.009-1.988a1.511 1.511 0 00-.221-.994c-.045-.051-.184-.207-.643-.207h-18.1v-1.9h18.1a2.631 2.631 0 012.074.859 3.335 3.335 0 01.677 2.417z"
              data-name="Path 2584"
              transform="translate(-245.168 -134.671)"></path>
          </g>
        </g>
      </g>
      <g data-name="Group 3080" transform="translate(14.697 12.617)">
        <path
          fill="#3b49d3"
          d="M258.8 108.345h-38.735a.948.948 0 01-.948-.948v-4.277a.949.949 0 01.481-.825l19.533-11.048a.944.944 0 01.94 0l19.2 11.048a.947.947 0 01.475.821v4.284a.948.948 0 01-.946.945zm-37.786-1.9h36.838v-2.78l-18.256-10.5-18.581 10.51z"
          data-name="Path 2585"
          transform="translate(-219.117 -91.124)"></path>
      </g>
      <g data-name="Group 3081" transform="translate(31.683 18.48)">
        <path
          fill="#3b49d3"
          d="M245.054 105.937a3.54 3.54 0 113.54-3.54 3.544 3.544 0 01-3.54 3.54zm0-5.185a1.644 1.644 0 101.646 1.648 1.646 1.646 0 00-1.646-1.648z"
          data-name="Path 2586"
          transform="translate(-241.514 -98.856)"></path>
      </g>
    </svg>
  );
}
