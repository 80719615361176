import React from 'react';

export function Platforms() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="72"
      height="73"
      viewBox="0 0 72 73">
      <g data-name="Group 3317" transform="translate(-.498 -.066)">
        <g data-name="Group 2995">
          <g data-name="Ellipse 28-4">
            <ellipse
              cx="36"
              cy="36.5"
              fill="#ebedff"
              data-name="Ellipse 35"
              rx="36"
              ry="36.5"
              transform="translate(.498 .066)"></ellipse>
          </g>
        </g>
        <g data-name="Group 3083" transform="translate(14.637 17.165)">
          <path
            fill="#374adb"
            d="M361.654 125.959h-35.635a3.719 3.719 0 01-3.715-3.714V99a3.719 3.719 0 013.715-3.715h35.635A3.718 3.718 0 01365.368 99v23.247a3.718 3.718 0 01-3.714 3.712zm-35.635-28.78A1.82 1.82 0 00324.2 99v23.247a1.82 1.82 0 001.819 1.818h35.635a1.82 1.82 0 001.818-1.818V99a1.82 1.82 0 00-1.818-1.819z"
            data-name="Path 2587"
            transform="translate(-322.304 -95.283)"></path>
        </g>
        <g data-name="Group 3084" transform="translate(40.27 23.055)">
          <path
            fill="#374adb"
            d="M366.522 104.945h-9.471a.948.948 0 010-1.9h9.471a.948.948 0 010 1.9z"
            data-name="Path 2588"
            transform="translate(-356.103 -103.049)"></path>
        </g>
        <g data-name="Group 3085" transform="translate(40.27 28.747)">
          <path
            fill="#374adb"
            d="M366.522 112.45h-9.471a.948.948 0 010-1.9h9.471a.948.948 0 010 1.9z"
            data-name="Path 2589"
            transform="translate(-356.103 -110.554)"></path>
        </g>
        <g data-name="Group 3086" transform="translate(40.27 34.439)">
          <path
            fill="#374adb"
            d="M366.522 119.955h-9.471a.948.948 0 010-1.9h9.471a.948.948 0 010 1.9z"
            data-name="Path 2590"
            transform="translate(-356.103 -118.059)"></path>
        </g>
        <g data-name="Group 3087" transform="translate(27.747 50.387)">
          <path
            fill="#3b49d3"
            d="M353.587 144.957h-11.061a2.935 2.935 0 010-5.87h11.061a2.935 2.935 0 010 5.87zm-11.061-3.974a1.039 1.039 0 000 2.078h11.061a1.039 1.039 0 000-2.078z"
            data-name="Path 2591"
            transform="translate(-339.591 -139.087)"></path>
        </g>
        <g data-name="Group 3088" transform="translate(30.842 46.578)">
          <path
            fill="#3b49d3"
            d="M0 0H4.428V1.895H0z"
            data-name="Rectangle 1720"
            transform="rotate(-70.602 2.95 2.088)"></path>
        </g>
        <g data-name="Group 3089" transform="translate(38.457 46.578)">
          <path
            fill="#3b49d3"
            d="M0 0H1.895V4.428H0z"
            data-name="Rectangle 1721"
            transform="matrix(.943 -.332 .332 .943 0 .63)"></path>
        </g>
        <g data-name="Group 3090" transform="translate(35.017 42.364)">
          <path
            fill="#3b49d3"
            d="M350.333 130.82a1.156 1.156 0 111.155-1.157 1.158 1.158 0 01-1.155 1.157zm0-1.517a.361.361 0 10.36.36.361.361 0 00-.36-.363z"
            data-name="Path 2592"
            transform="translate(-349.176 -128.508)"></path>
        </g>
        <g data-name="Group 3091" transform="translate(18.11 39.477)">
          <path
            fill="#374adb"
            d="M362.143 126.6h-34.311a.948.948 0 010-1.9h34.311a.948.948 0 010 1.9z"
            data-name="Path 2593"
            transform="translate(-326.884 -124.702)"></path>
        </g>
        <g data-name="Group 3092" transform="translate(20.173 23.055)">
          <path
            fill="#3b49d3"
            d="M341.386 116.329h-8.693a3.093 3.093 0 01-3.089-3.09v-7.1a3.092 3.092 0 013.089-3.089h8.693a3.093 3.093 0 013.09 3.089v7.1a3.094 3.094 0 01-3.09 3.09zm-8.693-11.384a1.2 1.2 0 00-1.193 1.193v7.1a1.2 1.2 0 001.193 1.194h8.693a1.2 1.2 0 001.194-1.194v-7.1a1.2 1.2 0 00-1.194-1.193z"
            data-name="Path 2594"
            transform="translate(-329.604 -103.049)"></path>
        </g>
        <g data-name="Group 3093" transform="translate(25.457 23.055)">
          <path
            fill="#3b49d3"
            d="M337.519 115.77a.947.947 0 01-.948-.948V104a.948.948 0 111.9 0v10.825a.948.948 0 01-.952.945z"
            data-name="Path 2595"
            transform="translate(-336.571 -103.049)"></path>
        </g>
      </g>
    </svg>
  );
}
