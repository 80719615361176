import { useListAllBankUids } from '@b7hio/api-lib/src/ops-portal';
import { FormAutocomplete } from '@b7hio/core-lib/src/components/Form/FormAutocomplete/FormAutocomplete';
import type { TextFieldProps } from '@mui/material';
import { CircularProgress } from '@mui/material';
import React from 'react';
import type { UseControllerProps } from 'react-hook-form';
import { FieldValues } from 'react-hook-form/dist/types';
import type { TFunction } from 'react-i18next';

type Props<T> = UseControllerProps<T> & {
  readonly label: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  readonly t: TFunction<any>;
  readonly disabled?: boolean;
  readonly size?: TextFieldProps['size'];
};

export function FormBankList<T extends FieldValues = FieldValues>(
  props: Props<T>
) {
  const { data, isLoading, refetch, isStale } = useListAllBankUids();

  const banks =
    data
      ?.map((bank) => ({
        label: bank.tradingName,
        value: bank.uid,
      }))
      .sort((a, b) => a.label.localeCompare(b.label)) ?? [];

  return (
    <FormAutocomplete
      options={banks}
      isOptionEqualToValue={(o1, o2) => o1.value === o2.value}
      loading={isLoading}
      onOpen={() => isStale && refetch()}
      InputProps={(InputProps) => ({
        ...InputProps,
        endAdornment: (
          <React.Fragment>
            {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
            {InputProps.endAdornment}
          </React.Fragment>
        ),
      })}
      {...props}
    />
  );
}
