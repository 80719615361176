import { BankProductClosureRequest } from '@b7hio/api-lib/src/bank-portal';
import { FormFriendly } from '@b7hio/core-lib/src/form';
import { notEmpty } from '@b7hio/core-lib/src/form/validators';
import * as s from 'superstruct';
import { object, string } from 'superstruct';

export const ProductClosureFormValidation = object({
  closureDate: notEmpty(string()),
});

export const defaultValues = (): FormFriendly<BankProductClosureRequest> => ({
  closureDate: '',
});
