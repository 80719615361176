import { BankProductResponse as BankPortalBankProductResponse } from '@b7hio/api-lib/src/bank-portal';
import {
  BankProductResponse,
  RateDetailUpdateRequest,
} from '@b7hio/api-lib/src/ops-portal';
import { FormDatePicker } from '@b7hio/core-lib/src/components/Form/FormDatePicker/FormDatePicker';
import { MaskedTextField } from '@b7hio/core-lib/src/components/Form/FormMaskedTextField/MaskedTextField';
import { superstructResolver } from '@b7hio/core-lib/src/form/resolver';
import type { FormFriendly } from '@b7hio/core-lib/src/form/types';
import { Button, styled, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import React from 'react';
import { useForm } from 'react-hook-form';
import { UseFormSetError } from 'react-hook-form/dist/types/form';
import { useTranslation } from 'react-i18next';
import { create } from 'superstruct';
import { ProductRateChangeFormValidation } from './ProductRateChangeForm.validation';

interface Props {
  readonly product: BankProductResponse | BankPortalBankProductResponse;
  readonly onSubmit: (
    values: RateDetailUpdateRequest,
    setError: UseFormSetError<FormFriendly<RateDetailUpdateRequest>>
  ) => void;
}

export const ProductRateChangeForm = ({
  onSubmit,
  product,
}: Props): JSX.Element => {
  const { t } = useTranslation(['products']);
  const { handleSubmit, formState, setError, control, watch } = useForm<
    FormFriendly<RateDetailUpdateRequest>
  >({
    mode: 'onBlur',
    resolver: superstructResolver(ProductRateChangeFormValidation, {
      coerce: true,
    }),
    defaultValues: createRateChangeDefaults,
  });

  const grossRate = watch('grossRate');

  function shouldDisableInvalidDates(
    day: DateTime,
    newGrossRate: number,
    isTracker: boolean
  ) {
    const rateIncrease: boolean = newGrossRate / 100 - product.grossRate >= 0;
    const currentDate = DateTime.now();
    const dayDifference = day.diff(currentDate, ['days']).toObject();
    if (isTracker) {
      return dayDifference.days! < -1;
    }
    if (rateIncrease) {
      return dayDifference.days! < 0;
    }
    return dayDifference.days! < 14 + (product.periodFeature.noticePeriod ?? 0);
  }

  function shouldDisableAnnouncedAtDates(day: DateTime) {
    const currentDate = DateTime.now();
    const rateStartDateObject = DateTime.fromJSDate(new Date(rateStartDate));

    if (
      (day.day < currentDate.day && day.month === currentDate.month) ||
      day >= rateStartDateObject
    ) {
      return true;
    }
    return false;
  }

  const rateStartDate = watch('startDate');
  const { isSubmitting } = formState;

  return (
    <form
      onSubmit={handleSubmit(
        async (values) => {
          await onSubmit(
            create(values, ProductRateChangeFormValidation),
            setError
          );
        },
        // eslint-disable-next-line no-console
        (errors) => console.log(errors)
      )}
      style={{ gridArea: 'form' }}>
      <FormContainer sx={{ gap: 2 }}>
        <Typography id="currentRate" margin="normal">
          Current Rate: {(product.grossRate * 100).toFixed(2)}% (AER:{' '}
          {(product.aerRate * 100).toFixed(2)}%)
        </Typography>
        <Typography id="currentRate" margin="normal">
          Payout Period:{' '}
          {t(
            `products:payoutPeriod.${product.interestFeature.payoutPeriod}` as any
          )}
        </Typography>
        <MaskedTextField
          label={t('products:rateChange.newRate')}
          name="grossRate"
          t={t}
          inputProps={{
            mask: 'NUM%',
            blocks: {
              NUM: {
                // nested masks are available!
                mask: Number,
                scale: 2,
                padFractionalZeros: true,
                normalizeZeros: true,
                radix: '.',
              },
            },
          }}
          control={control}
        />
        <FormDatePicker
          name="startDate"
          control={control}
          label={t('products:rateChange.effectiveDate')}
          t={t}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          getErrorPath={(type) => t(`common:validation.date.${type}` as any)}
          disablePast={true}
          shouldDisableDate={(date) =>
            shouldDisableInvalidDates(
              date,
              grossRate as number,
              product.isTracker ?? false
            )
          }
          disabled={grossRate === ''}
        />
        <FormDatePicker
          name="announcedAt"
          control={control}
          label={t('products:rateChange.announcedAt')}
          t={t}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          getErrorPath={(type) => t(`common:validation.date.${type}` as any)}
          disablePast={true}
          disabled={rateStartDate === ''}
          shouldDisableDate={shouldDisableAnnouncedAtDates}
        />
        <Button
          sx={{ mt: 1, mb: 2 }}
          color="primary"
          variant="contained"
          type="submit"
          disabled={isSubmitting}>
          {t('products:rateChange.submit')}
        </Button>
      </FormContainer>
    </form>
  );
};

const createRateChangeDefaults = {
  startDate: '',
  grossRate: '',
};

const FormContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
}));
