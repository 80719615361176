/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Bondsmith Savings Exchange Bank Portal APIs
 * OpenAPI spec version: 0.1
 */

export type OrderAscDesc = typeof OrderAscDesc[keyof typeof OrderAscDesc];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OrderAscDesc = {
  ASC: 'ASC',
  DESC: 'DESC',
} as const;
