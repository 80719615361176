/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Bondsmith Savings Exchange Operation Portal APIs
 * OpenAPI spec version: 0.1
 */

export type InternalTaxWrapper =
  typeof InternalTaxWrapper[keyof typeof InternalTaxWrapper];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InternalTaxWrapper = {
  ISA: 'ISA',
  SIPP: 'SIPP',
  NONE: 'NONE',
  SSAS: 'SSAS',
} as const;
