import React from 'react';

export function Products() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="72.885"
      height="72.885"
      viewBox="0 0 72.885 72.885">
      <g data-name="Group 3318">
        <g data-name="Group 2995">
          <g data-name="Ellipse 28-4">
            <ellipse
              cx="36.442"
              cy="36.443"
              fill="#ebedff"
              data-name="Ellipse 36"
              rx="36.442"
              ry="36.443"></ellipse>
          </g>
        </g>
        <g data-name="Group 3095" transform="translate(17.929 25.371)">
          <rect
            width="35.648"
            height="27.383"
            fill="#ebedff"
            data-name="Rectangle 1722"
            rx="1.37"
            transform="translate(.948 .948)"></rect>
          <path
            fill="#3b49d3"
            d="M470.074 138.347H436.5a1.99 1.99 0 01-1.987-1.987v-25.305a1.989 1.989 0 011.987-1.987h33.57a1.989 1.989 0 011.987 1.987v25.305a1.99 1.99 0 01-1.983 1.987zM436.5 110.964a.1.1 0 00-.091.091v25.305a.1.1 0 00.091.091h33.57a.093.093 0 00.091-.091v-25.305a.1.1 0 00-.091-.091z"
            data-name="Path 2596"
            transform="translate(-434.517 -109.068)"></path>
        </g>
        <g data-name="Group 3096" transform="translate(17.929 35.034)">
          <path
            fill="#3b49d3"
            d="M471.113 123.705h-35.648a.948.948 0 010-1.9h35.648a.948.948 0 110 1.9z"
            data-name="Path 2597"
            transform="translate(-434.517 -121.809)"></path>
        </g>
        <g data-name="Group 3097" transform="translate(32.484 30.687)">
          <path
            fill="#ebedff"
            d="M0 0H6.459V10.434H0z"
            data-name="Rectangle 1723"
            transform="translate(.948 .948)"></path>
          <path
            fill="#3b49d3"
            d="M461.115 128.407h-6.459a.948.948 0 01-.948-.948v-10.434a.948.948 0 01.948-.948h6.459a.948.948 0 01.948.948v10.433a.948.948 0 01-.948.949zm-5.511-1.9h4.563v-8.537H455.6z"
            data-name="Path 2598"
            transform="translate(-453.708 -116.077)"></path>
        </g>
        <g data-name="Group 3098" transform="translate(21.729 48.586)">
          <path
            fill="#3b49d3"
            d="M458.757 141.574h-18.282a.948.948 0 110-1.9h18.282a.948.948 0 110 1.9z"
            data-name="Path 2599"
            transform="translate(-439.527 -139.678)"></path>
        </g>
        <g data-name="Group 3099" transform="translate(21.729 44.15)">
          <path
            fill="#3b49d3"
            d="M445.852 135.724h-5.377a.948.948 0 110-1.9h5.377a.948.948 0 010 1.9z"
            data-name="Path 2600"
            transform="translate(-439.527 -133.828)"></path>
        </g>
        <g data-name="Group 3100" transform="translate(21.729 39.756)">
          <path
            fill="#3b49d3"
            d="M443.164 129.93h-2.689a.948.948 0 110-1.9h2.689a.948.948 0 010 1.9z"
            data-name="Path 2601"
            transform="translate(-439.527 -128.034)"></path>
        </g>
        <g data-name="Group 3101" transform="translate(22.768 12.525)">
          <path
            fill="#3b49d3"
            d="M467.736 106.872a.945.945 0 01-.717-.326l-10.163-11.7a2.982 2.982 0 00-4.177.049l-10.118 11.655a.948.948 0 01-1.43-1.243l10.163-11.7a4.9 4.9 0 016.949-.049L468.45 105.3a.948.948 0 01-.714 1.569z"
            data-name="Path 2602"
            transform="translate(-440.898 -92.129)"></path>
        </g>
      </g>
    </svg>
  );
}
