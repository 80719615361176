import {
  BankProductRequestWithRateAndAccountDetails,
  BankProductResponse,
  CoolOffPeriodType,
  CurrencyCode,
  ExoticType,
  HolidayCalendarType,
  InterestPayoutPeriodType,
  InternalAccountHolderType,
  InternalTaxWrapper,
  listAccountsForBanks,
  ProductType,
  RequestAccountHolderType,
  UKVerifiedPaymentAccountResponse,
} from '@b7hio/api-lib/src/ops-portal';
import {
  FormAutocomplete,
  FormDatePicker,
  FormTextField,
  MaskedTextField,
} from '@b7hio/core-lib/src/components';
import { FormFriendly, superstructResolver } from '@b7hio/core-lib/src/form';
import { omit } from '@b7hio/core-lib/src/utils';
import {
  Autocomplete,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  styled,
  TextField as MuiTextField,
  TextField,
} from '@mui/material';
import { pipe } from 'fp-ts/lib/function';
import * as RA from 'fp-ts/ReadonlyArray';
import * as RR from 'fp-ts/ReadonlyRecord';
import React, { RefObject, useEffect, useRef, useState } from 'react';
import type { FieldValues } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { UseFormSetError } from 'react-hook-form/dist/types/form';
import { useTranslation } from 'react-i18next';
import { create } from 'superstruct';
import { FormBankList } from '../../../../components';
import { ProductDocuments } from '../../hooks/useProductInteractivity';
import {
  AddProductValidation,
  AddProductValidationWithAccountDetails,
  EditProductValidation,
  EditProductValidationWithAccountDetails,
} from './ProductForm.validation';
import { FormDateTimePicker } from '@b7hio/core-lib/src/components/Form/FormDateTimePicker';

type BankProductResponseForUpdate = Omit<
  BankProductResponse,
  | 'uid'
  | 'bank'
  | 'createdAt'
  | 'updatedAt'
  | 'feeDetails'
  | 'rateDetails'
  | 'proposalUid'
  | 'currentState'
>;

type Props<
  P,
  S extends FieldValues = BankProductRequestWithRateAndAccountDetails
> = {
  readonly product: P;
  readonly onSubmit: (
    values: S,
    files: ProductDocuments,
    setError: UseFormSetError<S>
  ) => Promise<void>;
};

export function ProductForm<
  P extends BankProductResponseForUpdate | undefined
>({ onSubmit, product }: Props<P>) {
  const isEditing = Boolean(product);

  const accountNumRef = useRef<string | undefined>(undefined);
  const sortCodeRef = useRef<string | undefined>(undefined);
  const [provideAccountDetails, setProvideAccountDetails] =
    useState<boolean>(false);

  const [tandcUrl, setTandcFile] = useState<File | undefined>(undefined);
  const [brochureUrl, setBrochureFile] = useState<File | undefined>(undefined);

  const currentProduct =
    product != null
      ? {
          accountHolderTypes: convertAccountHolderTypes(
            product.accountHolderTypes
          ),
          bankUid: product.bankUid,
          currency: product.currency,
          depositRequirement: product.depositRequirement,
          exoticType: product.exoticType,
          externalId: product.externalId,
          holidayCalendar: product.holidayCalendar,
          interestFeature: product.interestFeature,
          maximumAvailable: product.maximumAvailable,
          name: product.name,
          periodFeature: product.periodFeature,
          productAvailability: product.productAvailability,
          productLiterature: {
            brochureUrl: product.productLiterature?.brochureUrl ?? null,
            tandcUrl: product.productLiterature?.tandcUrl ?? null,
          },
          productType: product.productType,
          rateDetail: {
            grossRate: product.grossRate,
            startDate: product.startDate,
          },
          isTracker: product.isTracker ? product.isTracker.toString() : 'false',
          startDate: product.startDate,
          stopDisplayAt: product.stopDisplayAt,
          taxWrappers: [],
        }
      : undefined;

  const {
    handleSubmit,
    setError,
    formState,
    watch,
    control,
    setValue,
    getValues,
  } = useForm<FormFriendly<BankProductRequestWithRateAndAccountDetails>>({
    mode: 'onSubmit',
    resolver: superstructResolver(
      getValidator(
        isEditing,
        provideAccountDetails,
        accountNumRef,
        sortCodeRef
      ),
      { coerce: true }
    ),
    defaultValues: currentProduct ?? createProductDefaults('23:45:00'),
  });

  const { isSubmitting } = formState;
  const { t } = useTranslation(['common', 'products']);

  const productType = watch('productType');
  const bankUid = watch('bankUid');

  const [paymentAccounts, setPaymentAccounts] = useState<
    readonly UKVerifiedPaymentAccountResponse[]
  >([]);
  const [manualPayment, setManualPayment] = useState<boolean>(false);
  const [selectedPaymentAccount, setSelectedPaymentAccount] = useState<{
    readonly sortCode: string;
    readonly accountNumber: string;
  } | null>();

  const fetchPaymentAccounts = async () => {
    setPaymentAccounts(
      await listAccountsForBanks({ accountOwnerUid: bankUid })
    );
  };

  const maturityOptions = pipe(
    productType === ProductType.INSTANT || productType === ProductType.NOTICE
      ? omit(InterestPayoutPeriodType, ['AT_MATURITY'])
      : InterestPayoutPeriodType,
    RR.keys,
    RA.map((v) => ({
      label: t(`products:addProduct.interestPayoutPeriodType.${v}`),
      value: v,
    }))
  );

  const accountNumInput = watch('accountDetails.accountNumber');
  const sortcodeInput = watch('accountDetails.sortCode');

  const handleSelectPaymentAccount = (
    sortCode: string | null,
    accountNumber: string | null
  ) => {
    setSelectedPaymentAccount(
      sortCode && accountNumber ? { sortCode, accountNumber } : null
    );
    setValue('accountDetails.sortCode', sortCode ?? '');
    setValue('accountDetails.accountNumber', accountNumber ?? '');
  };

  const booleanOptions = [
    {
      label: 'Yes',
      value: 'true',
    },
    {
      label: 'No',
      value: 'false',
    },
  ];

  useEffect(() => {
    if (productType === ProductType.NOTICE) {
      setValue('periodFeature.termPeriod', '');
    }
    if (productType === ProductType.TERM) {
      setValue('periodFeature.noticePeriod', '');
    }
  }, [productType]);

  useEffect(() => {
    if (product) setManualPayment(true);
  }, [product]);

  useEffect(() => {
    if (bankUid) fetchPaymentAccounts();

    // Clear fields if bankUid is unselected
    if (!bankUid) {
      setValue('accountDetails.sortCode', '');
      setValue('accountDetails.accountNumber', '');
    }
  }, [bankUid]);

  useEffect(() => {
    accountNumRef.current = accountNumInput;
    sortCodeRef.current = sortcodeInput;
  }, [accountNumInput, sortcodeInput]);

  const handleAccountCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setProvideAccountDetails(checked);
  };

  return (
    <form
      onSubmit={handleSubmit(
        async (values) => {
          if (product) {
            const output = create(
              possiblyOmitAccountDetails(values, provideAccountDetails),
              // @ts-expect-error TODO
              getValidator(
                isEditing,
                provideAccountDetails,
                accountNumRef,
                sortCodeRef
              )
            );
            // @ts-expect-error TODO
            await onSubmit(output, setError);
          } else {
            const output = create(
              possiblyOmitAccountDetails(values, provideAccountDetails),
              // @ts-expect-error TODO
              getValidator(
                isEditing,
                provideAccountDetails,
                accountNumRef,
                sortCodeRef
              )
            );
            const productDocs: ProductDocuments = {
              tandcUrl: tandcUrl,
              brochureUrl: brochureUrl,
            };
            // @ts-expect-error TODO
            await onSubmit(output, productDocs, setError);
          }
        },
        // eslint-disable-next-line no-console
        (errors) => console.log(errors, getValues())
      )}
      style={{ gridArea: 'form' }}>
      <FormContainer>
        <FormBankList
          label={t('products:addProduct.bank')}
          t={t}
          name="bankUid"
          control={control}
          disabled={isEditing}
          data-testid="chooseBank"
        />
        {!isEditing && (
          <FormTextField
            name="name"
            label={t('products:addProduct.name')}
            t={t}
            control={control}
            data-testid="productName"
          />
        )}
        <FormTextField
          control={control}
          name="externalId"
          label={t('products:addProduct.externalId')}
          t={t}
        />
        <FormAutocomplete
          name="currency"
          data-testid="currency"
          label={t('products:addProduct.currency')}
          options={Object.values(CurrencyCode).map((v) => {
            return {
              label: t(`products:addProduct.currencyCodes.${v}`),
              value: v,
            };
          })}
          t={t}
          control={control}
        />

        {!isEditing && (
          <FormAutocomplete
            multiple
            options={Object.values(InternalTaxWrapper)}
            name="taxWrappers"
            label={t('products:addProduct.taxWrappers')}
            t={t}
            control={control}
            data-testid="taxWrappers"
          />
        )}
        <FormAutocomplete
          options={Object.values(ProductType).map((v) => {
            return {
              label: t(`products:productTypes.${v}`),
              value: v,
            };
          })}
          name="productType"
          label={t('products:addProduct.type')}
          t={t}
          control={control}
          data-testid="productType"
        />
        {productType === ProductType.NOTICE && (
          <FormTextField
            name="periodFeature.noticePeriod"
            label={t('products:addProduct.noticePeriod')}
            control={control}
            t={t}
          />
        )}
        {productType === ProductType.TERM && (
          <FormTextField
            name="periodFeature.termPeriod"
            label={t('products:addProduct.termPeriod')}
            control={control}
            t={t}
          />
        )}
        <FormAutocomplete
          name="exoticType"
          label={t('products:addProduct.exoticType')}
          options={Object.values(ExoticType).map((v) => {
            return {
              label: t(`products:addProduct.exoticTypes.${v}`),
              value: v,
            };
          })}
          control={control}
          t={t}
        />
        <FormAutocomplete
          multiple
          name="accountHolderTypes"
          label={t('products:addProduct.accountHolderTypes')}
          options={Object.values(RequestAccountHolderType).map((v) => {
            return {
              label: t(`products:addProduct.accountHolderTypesValues.${v}`),
              value: v,
            };
          })}
          control={control}
          t={t}
        />
        <FormTextField
          name="depositRequirement.min"
          label={t('products:addProduct.minDeposit')}
          t={t}
          control={control}
          currencyFormat
        />
        <FormTextField
          name="depositRequirement.max"
          label={t('products:addProduct.maxDeposit')}
          t={t}
          control={control}
          currencyFormat
        />
        <FormAutocomplete
          name="interestFeature.payoutPeriod"
          label={t('products:addProduct.interestPaymentPeriod')}
          options={maturityOptions}
          control={control}
          t={t}
        />
        {!isEditing && (
          <>
            <MaskedTextField
              label={t('products:addProduct.grossRate')}
              name="rateDetail.grossRate"
              t={t}
              inputProps={{
                mask: 'NUM%',
                blocks: {
                  NUM: {
                    // nested masks are available!
                    mask: Number,
                    scale: 2,
                    padFractionalZeros: true,
                    normalizeZeros: true,
                    radix: '.',
                  },
                },
              }}
              control={control}
            />
          </>
        )}
        <FormAutocomplete
          name="isTracker"
          label={t('products:addProduct.isTracker')}
          options={booleanOptions}
          control={control}
          t={t}
        />
        <Divider sx={{ mb: 2 }} />
        <FormControlLabel
          control={<Checkbox onChange={handleAccountCheckboxChange} />}
          label={t('products:addProduct.enterAccountDetails')}
        />
        {provideAccountDetails && !manualPayment && (
          <Autocomplete
            renderInput={(params) => (
              <TextField
                {...params}
                label={t('products:addProduct.bankAccount')}
              />
            )}
            options={paymentAccounts.map((v) => {
              return {
                label: t('products:addProduct.bankAccountLabel', {
                  sortCode: v.sortCode,
                  accountNumber: v.accountNumber,
                }),
                account: {
                  sortCode: v.sortCode,
                  accountNumber: v.accountNumber,
                },
              };
            })}
            onChange={(event, newValue) => {
              handleSelectPaymentAccount(
                newValue?.account.sortCode ?? null,
                newValue?.account.accountNumber ?? null
              );
            }}
          />
        )}
        {provideAccountDetails && (selectedPaymentAccount || manualPayment) && (
          <MaskedTextField
            label={t('products:addProduct.sortCode')}
            name="accountDetails.sortCode"
            t={t}
            inputProps={{
              mask: '00-00-00',
            }}
            control={control}
          />
        )}
        {provideAccountDetails && (selectedPaymentAccount || manualPayment) && (
          <FormTextField
            name="accountDetails.accountNumber"
            label={t('products:addProduct.accountNumber')}
            control={control}
            disabled={!manualPayment}
            t={t}
            data-testid="accountDetails.accountNumber"
          />
        )}
        {provideAccountDetails && (
          <Button
            color="primary"
            variant="outlined"
            onClick={() => setManualPayment(!manualPayment)}
            disabled={isSubmitting}>
            {manualPayment
              ? t('products:addProduct.choosePaymentBtn')
              : t('products:addProduct.manualPaymentBtn')}
          </Button>
        )}
        <Divider sx={{ pb: 2, mb: 2 }} />
        {!isEditing && (
          <FormAutocomplete
            name="holidayCalendar"
            label={t('products:addProduct.holidayCalendar')}
            options={Object.values(HolidayCalendarType).map((v) => {
              return {
                label: t(`products:addProduct.holidayCalendarTypes.${v}`),
                value: v,
              };
            })}
            control={control}
            t={t}
          />
        )}
        {productType === ProductType.TERM && (
          <FormDatePicker
            name="startDate"
            control={control}
            label={t('products:addProduct.startDate')}
            t={t}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            getErrorPath={(type) => t(`common:validation.date.${type}` as any)}
          />
        )}
        <FormDatePicker
          name="productAvailability.availableFrom"
          control={control}
          label={t('products:addProduct.availableFrom')}
          t={t}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          getErrorPath={(type) => t(`common:validation.date.${type}` as any)}
        />
        {productType === ProductType.TERM && (
          <FormDatePicker
            name="productAvailability.availableUntil"
            control={control}
            label={t('products:addProduct.availableUntil')}
            t={t}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            getErrorPath={(type) => t(`common:validation.date.${type}` as any)}
          />
        )}
        <FormDateTimePicker
          name="stopDisplayAt"
          control={control}
          label={t('products:addProduct.stopDisplayAt')}
          t={t}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          getErrorPath={(type) => t(`common:validation.date.${type}` as any)}
        />
        <FormAutocomplete
          name="periodFeature.coolOffPeriod"
          label={t('products:addProduct.coolOffPeriod')}
          // TODO: translation for options
          options={Object.values(CoolOffPeriodType).map((v) => {
            return {
              label: t(`products:addProduct.coolOffPeriodTypes.${v}`),
              value: v,
            };
          })}
          control={control}
          t={t}
        />
        <FormTextField
          name="maximumAvailable"
          label={t('products:addProduct.maximumAvailable')}
          control={control}
          t={t}
          getErrorPath={(type) => t(`common:validation.${type}` as any)}
          currencyFormat
        />
        <FormAutocomplete
          name="isCompositeProduct"
          options={booleanOptions}
          label={t('products:addProduct.isCompositeProduct')}
          control={control}
          t={t}
        />
        <MuiTextField
          type="file"
          label={t('products:addProduct.termsAndConditions')}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            const file = event.target?.files?.[0];
            if (file && file.name !== '') {
              setTandcFile(file);
            }
          }}
        />
        <MuiTextField
          type="file"
          label={t('products:addProduct.productBrochure')}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            const file = event.target?.files?.[0];
            if (file && file.name !== '') {
              setBrochureFile(file);
            }
          }}
        />
        <Button
          sx={{ mt: 2, mb: 2 }}
          color="primary"
          variant="contained"
          type="submit"
          disabled={isSubmitting}>
          {t(
            isEditing
              ? 'products:editProduct.submit'
              : 'products:addProduct.submit'
          )}
        </Button>
      </FormContainer>
    </form>
  );
}

const FormContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  '> div': {
    marginBottom: theme.spacing(2),

    '&:last-of-type': {
      marginBottom: 0,
    },
  },
}));

function createProductDefaults(interestCutOffTime: string) {
  return {
    bankUid: '',
    name: '',
    externalId: '',
    currency: '',
    productType: '',
    interestFeature: {
      payoutPeriod: '',
      interestCutOffTime: interestCutOffTime,
    },
    rateDetail: {
      grossRate: '',
      startDate: '',
    },
    holidayCalendar: '',
    periodFeature: {
      termPeriod: '',
      noticePeriod: '',
      coolOffPeriod: '',
    },
    exoticType: '',
    accountHolderTypes: [],
    productAvailability: { availableFrom: '', availableUntil: '' },
    depositRequirement: { min: '', max: '' },
    accountDetails: {
      sortCode: '',
      accountNumber: '',
    },
    productLiterature: {
      infoUrl: '',
      tandcUrl: '',
      brochureUrl: '',
    },
    taxWrappers: [],
    maximumAvailable: 1,
  } as FormFriendly<BankProductRequestWithRateAndAccountDetails>;
}

/**
 * Use this to drop the account details if the user is not providing them explicitly
 * (we don't want to override core data with empty strings for the account details).
 */
const possiblyOmitAccountDetails = (
  values: any,
  sendAccountDetails: boolean
) => {
  return sendAccountDetails ? values : omit(values, ['accountDetails']);
};

/**
 * Validate either an addition or an edit of a product, maybe checking (or not) the
 * account details
 */
const getValidator = (
  isEditing: boolean,
  provideAccountDetails: boolean,
  accountNumRef: RefObject<string | undefined>,
  sortCodeRef: RefObject<string | undefined>
) => {
  if (provideAccountDetails) {
    return isEditing
      ? EditProductValidationWithAccountDetails(accountNumRef, sortCodeRef)
      : AddProductValidationWithAccountDetails(accountNumRef, sortCodeRef);
  }

  return isEditing ? EditProductValidation : AddProductValidation;
};

function convertAccountHolderType(
  value: InternalAccountHolderType
): RequestAccountHolderType {
  if (value === InternalAccountHolderType.COMPANY) {
    return RequestAccountHolderType.CORPORATE;
  }
  return RequestAccountHolderType[value];
}

function convertAccountHolderTypes(
  values: InternalAccountHolderType[]
): RequestAccountHolderType[] {
  return values.map(convertAccountHolderType);
}
