/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Bondsmith Savings Exchange Operation Portal APIs
 * OpenAPI spec version: 0.1
 */
import { useMutation, useQuery } from 'react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { customInstance } from '../../../../mutator/instance';
import type {
  BondsmithPagePartnerBankPaginatedResponse,
  ListBanks1Params,
  PartnerBankNotificationsResponse,
  PartnerBankNotificationsUpdateRequest,
  PartnerBankResponse,
  PartnerBankUidResponse,
  SearchBanksParams,
} from '../../models';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

export const listBanks = (signal?: AbortSignal) => {
  return customInstance<PartnerBankResponse[]>({
    url: `/operations/banks`,
    method: 'GET',
    signal,
  });
};

export const getListBanksQueryKey = () => {
  return [`/operations/banks`] as const;
};

export const getListBanksQueryOptions = <
  TData = Awaited<ReturnType<typeof listBanks>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof listBanks>>, TError, TData>;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListBanksQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listBanks>>> = ({
    signal,
  }) => listBanks(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof listBanks>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListBanksQueryResult = NonNullable<
  Awaited<ReturnType<typeof listBanks>>
>;
export type ListBanksQueryError = unknown;

export const useListBanks = <
  TData = Awaited<ReturnType<typeof listBanks>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof listBanks>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListBanksQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const listAllBankUids = (signal?: AbortSignal) => {
  return customInstance<PartnerBankUidResponse[]>({
    url: `/operations/banks/all/uids`,
    method: 'GET',
    signal,
  });
};

export const getListAllBankUidsQueryKey = () => {
  return [`/operations/banks/all/uids`] as const;
};

export const getListAllBankUidsQueryOptions = <
  TData = Awaited<ReturnType<typeof listAllBankUids>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof listAllBankUids>>,
    TError,
    TData
  >;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListAllBankUidsQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listAllBankUids>>> = ({
    signal,
  }) => listAllBankUids(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof listAllBankUids>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListAllBankUidsQueryResult = NonNullable<
  Awaited<ReturnType<typeof listAllBankUids>>
>;
export type ListAllBankUidsQueryError = unknown;

export const useListAllBankUids = <
  TData = Awaited<ReturnType<typeof listAllBankUids>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof listAllBankUids>>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListAllBankUidsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const listBanks1 = (params?: ListBanks1Params, signal?: AbortSignal) => {
  return customInstance<BondsmithPagePartnerBankPaginatedResponse>({
    url: `/operations/banks/paginated`,
    method: 'GET',
    params,
    signal,
  });
};

export const getListBanks1QueryKey = (params?: ListBanks1Params) => {
  return [`/operations/banks/paginated`, ...(params ? [params] : [])] as const;
};

export const getListBanks1QueryOptions = <
  TData = Awaited<ReturnType<typeof listBanks1>>,
  TError = unknown
>(
  params?: ListBanks1Params,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof listBanks1>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListBanks1QueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listBanks1>>> = ({
    signal,
  }) => listBanks1(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof listBanks1>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListBanks1QueryResult = NonNullable<
  Awaited<ReturnType<typeof listBanks1>>
>;
export type ListBanks1QueryError = unknown;

export const useListBanks1 = <
  TData = Awaited<ReturnType<typeof listBanks1>>,
  TError = unknown
>(
  params?: ListBanks1Params,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof listBanks1>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListBanks1QueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const searchBanks = (
  params?: SearchBanksParams,
  signal?: AbortSignal
) => {
  return customInstance<PartnerBankResponse[]>({
    url: `/operations/banks/search`,
    method: 'GET',
    params,
    signal,
  });
};

export const getSearchBanksQueryKey = (params?: SearchBanksParams) => {
  return [`/operations/banks/search`, ...(params ? [params] : [])] as const;
};

export const getSearchBanksQueryOptions = <
  TData = Awaited<ReturnType<typeof searchBanks>>,
  TError = unknown
>(
  params?: SearchBanksParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof searchBanks>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getSearchBanksQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof searchBanks>>> = ({
    signal,
  }) => searchBanks(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof searchBanks>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SearchBanksQueryResult = NonNullable<
  Awaited<ReturnType<typeof searchBanks>>
>;
export type SearchBanksQueryError = unknown;

export const useSearchBanks = <
  TData = Awaited<ReturnType<typeof searchBanks>>,
  TError = unknown
>(
  params?: SearchBanksParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof searchBanks>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSearchBanksQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const fetchBank = (bankUid: string, signal?: AbortSignal) => {
  return customInstance<PartnerBankResponse>({
    url: `/operations/banks/${bankUid}`,
    method: 'GET',
    signal,
  });
};

export const getFetchBankQueryKey = (bankUid: string) => {
  return [`/operations/banks/${bankUid}`] as const;
};

export const getFetchBankQueryOptions = <
  TData = Awaited<ReturnType<typeof fetchBank>>,
  TError = unknown
>(
  bankUid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof fetchBank>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getFetchBankQueryKey(bankUid);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof fetchBank>>> = ({
    signal,
  }) => fetchBank(bankUid, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!bankUid,
    ...queryOptions,
  } as UseQueryOptions<Awaited<ReturnType<typeof fetchBank>>, TError, TData> & {
    queryKey: QueryKey;
  };
};

export type FetchBankQueryResult = NonNullable<
  Awaited<ReturnType<typeof fetchBank>>
>;
export type FetchBankQueryError = unknown;

export const useFetchBank = <
  TData = Awaited<ReturnType<typeof fetchBank>>,
  TError = unknown
>(
  bankUid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof fetchBank>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getFetchBankQueryOptions(bankUid, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getPartnerBankNotifications = (
  bankUid: string,
  signal?: AbortSignal
) => {
  return customInstance<PartnerBankNotificationsResponse>({
    url: `/operations/banks/${bankUid}/notifications`,
    method: 'GET',
    signal,
  });
};

export const getGetPartnerBankNotificationsQueryKey = (bankUid: string) => {
  return [`/operations/banks/${bankUid}/notifications`] as const;
};

export const getGetPartnerBankNotificationsQueryOptions = <
  TData = Awaited<ReturnType<typeof getPartnerBankNotifications>>,
  TError = unknown
>(
  bankUid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPartnerBankNotifications>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetPartnerBankNotificationsQueryKey(bankUid);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPartnerBankNotifications>>
  > = ({ signal }) => getPartnerBankNotifications(bankUid, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!bankUid,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getPartnerBankNotifications>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetPartnerBankNotificationsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPartnerBankNotifications>>
>;
export type GetPartnerBankNotificationsQueryError = unknown;

export const useGetPartnerBankNotifications = <
  TData = Awaited<ReturnType<typeof getPartnerBankNotifications>>,
  TError = unknown
>(
  bankUid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPartnerBankNotifications>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPartnerBankNotificationsQueryOptions(
    bankUid,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const updatePartnerBankNotifications = (
  bankUid: string,
  partnerBankNotificationsUpdateRequest: PartnerBankNotificationsUpdateRequest
) => {
  return customInstance<void>({
    url: `/operations/banks/${bankUid}/notifications`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: partnerBankNotificationsUpdateRequest,
  });
};

export const getUpdatePartnerBankNotificationsMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updatePartnerBankNotifications>>,
    TError,
    { bankUid: string; data: PartnerBankNotificationsUpdateRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updatePartnerBankNotifications>>,
  TError,
  { bankUid: string; data: PartnerBankNotificationsUpdateRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updatePartnerBankNotifications>>,
    { bankUid: string; data: PartnerBankNotificationsUpdateRequest }
  > = (props) => {
    const { bankUid, data } = props ?? {};

    return updatePartnerBankNotifications(bankUid, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdatePartnerBankNotificationsMutationResult = NonNullable<
  Awaited<ReturnType<typeof updatePartnerBankNotifications>>
>;
export type UpdatePartnerBankNotificationsMutationBody =
  PartnerBankNotificationsUpdateRequest;
export type UpdatePartnerBankNotificationsMutationError = unknown;

export const useUpdatePartnerBankNotifications = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updatePartnerBankNotifications>>,
    TError,
    { bankUid: string; data: PartnerBankNotificationsUpdateRequest },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof updatePartnerBankNotifications>>,
  TError,
  { bankUid: string; data: PartnerBankNotificationsUpdateRequest },
  TContext
> => {
  const mutationOptions =
    getUpdatePartnerBankNotificationsMutationOptions(options);

  return useMutation(mutationOptions);
};
