/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Bondsmith Savings Exchange Bank Portal APIs
 * OpenAPI spec version: 0.1
 */
import { useMutation, useQuery } from 'react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { customInstance } from '../../../../mutator/instance';
import type {
  BankProductClosureRequest,
  BankProductRequestWithRateDetail,
  BankProductResponse,
  BankProductUploadRequestBody,
  BanksFetchProductBalanceParams,
  BondsmithPageCustomerProductAccountsInsightsPaginatedRecord,
  BondsmithPageListableAnonymousBankProductResponse,
  BondsmithPageListableBankProductPaginatedResponse,
  BondsmithPageUpcomingMaturitiesPaginatedRecord,
  GetAccountDetailsForInsightsParams,
  GetActiveAnonymousProductsParams,
  IncomingInstructionsResponse,
  ListActiveAndPendingProductsParams,
  ListOutstandingProposalsParams,
  ListUpcomingMaturitiesParams,
  ListableBankProductUploadResponse,
  PartnerBankFscsBrochureRequest,
  PartnerBankFscsBrochureResponse,
  PlatformProductBalancesResponse,
  ProductProposalResponse,
  RateDetailUpdateRequest,
  RateProposalResponse,
  UpcomingNoticeWithdrawalsResponse,
} from '../../models';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

/**
 * @summary Fetch product balance
 */
export const banksFetchProductBalance = (
  params: BanksFetchProductBalanceParams,
  signal?: AbortSignal
) => {
  return customInstance<PlatformProductBalancesResponse>({
    url: `/banks/own/balance/product`,
    method: 'GET',
    params,
    signal,
  });
};

export const getBanksFetchProductBalanceQueryKey = (
  params: BanksFetchProductBalanceParams
) => {
  return [`/banks/own/balance/product`, ...(params ? [params] : [])] as const;
};

export const getBanksFetchProductBalanceQueryOptions = <
  TData = Awaited<ReturnType<typeof banksFetchProductBalance>>,
  TError = unknown
>(
  params: BanksFetchProductBalanceParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof banksFetchProductBalance>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getBanksFetchProductBalanceQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof banksFetchProductBalance>>
  > = ({ signal }) => banksFetchProductBalance(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof banksFetchProductBalance>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type BanksFetchProductBalanceQueryResult = NonNullable<
  Awaited<ReturnType<typeof banksFetchProductBalance>>
>;
export type BanksFetchProductBalanceQueryError = unknown;

/**
 * @summary Fetch product balance
 */
export const useBanksFetchProductBalance = <
  TData = Awaited<ReturnType<typeof banksFetchProductBalance>>,
  TError = unknown
>(
  params: BanksFetchProductBalanceParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof banksFetchProductBalance>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getBanksFetchProductBalanceQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Fetch product balance for a bank
 */
export const banksFetchProductBalancesForBank = (signal?: AbortSignal) => {
  return customInstance<PlatformProductBalancesResponse>({
    url: `/banks/own/balance/product/bank`,
    method: 'GET',
    signal,
  });
};

export const getBanksFetchProductBalancesForBankQueryKey = () => {
  return [`/banks/own/balance/product/bank`] as const;
};

export const getBanksFetchProductBalancesForBankQueryOptions = <
  TData = Awaited<ReturnType<typeof banksFetchProductBalancesForBank>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof banksFetchProductBalancesForBank>>,
    TError,
    TData
  >;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getBanksFetchProductBalancesForBankQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof banksFetchProductBalancesForBank>>
  > = ({ signal }) => banksFetchProductBalancesForBank(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof banksFetchProductBalancesForBank>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type BanksFetchProductBalancesForBankQueryResult = NonNullable<
  Awaited<ReturnType<typeof banksFetchProductBalancesForBank>>
>;
export type BanksFetchProductBalancesForBankQueryError = unknown;

/**
 * @summary Fetch product balance for a bank
 */
export const useBanksFetchProductBalancesForBank = <
  TData = Awaited<ReturnType<typeof banksFetchProductBalancesForBank>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof banksFetchProductBalancesForBank>>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getBanksFetchProductBalancesForBankQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Fetch product balances for bank and platform
 */
export const banksFetchProductBalancesForBankAndPlatform = (
  platformUid: string,
  signal?: AbortSignal
) => {
  return customInstance<PlatformProductBalancesResponse>({
    url: `/banks/own/balance/product/platform/${platformUid}/bank`,
    method: 'GET',
    signal,
  });
};

export const getBanksFetchProductBalancesForBankAndPlatformQueryKey = (
  platformUid: string
) => {
  return [`/banks/own/balance/product/platform/${platformUid}/bank`] as const;
};

export const getBanksFetchProductBalancesForBankAndPlatformQueryOptions = <
  TData = Awaited<
    ReturnType<typeof banksFetchProductBalancesForBankAndPlatform>
  >,
  TError = unknown
>(
  platformUid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof banksFetchProductBalancesForBankAndPlatform>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getBanksFetchProductBalancesForBankAndPlatformQueryKey(platformUid);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof banksFetchProductBalancesForBankAndPlatform>>
  > = ({ signal }) =>
    banksFetchProductBalancesForBankAndPlatform(platformUid, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!platformUid,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof banksFetchProductBalancesForBankAndPlatform>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type BanksFetchProductBalancesForBankAndPlatformQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof banksFetchProductBalancesForBankAndPlatform>>
  >;
export type BanksFetchProductBalancesForBankAndPlatformQueryError = unknown;

/**
 * @summary Fetch product balances for bank and platform
 */
export const useBanksFetchProductBalancesForBankAndPlatform = <
  TData = Awaited<
    ReturnType<typeof banksFetchProductBalancesForBankAndPlatform>
  >,
  TError = unknown
>(
  platformUid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof banksFetchProductBalancesForBankAndPlatform>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getBanksFetchProductBalancesForBankAndPlatformQueryOptions(
      platformUid,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Fetch product balance for a platform
 */
export const banksFetchProductBalanceForPlatform = (
  productUid: string,
  platformUid: string,
  signal?: AbortSignal
) => {
  return customInstance<PlatformProductBalancesResponse>({
    url: `/banks/own/balance/product/${productUid}/platform/${platformUid}`,
    method: 'GET',
    signal,
  });
};

export const getBanksFetchProductBalanceForPlatformQueryKey = (
  productUid: string,
  platformUid: string
) => {
  return [
    `/banks/own/balance/product/${productUid}/platform/${platformUid}`,
  ] as const;
};

export const getBanksFetchProductBalanceForPlatformQueryOptions = <
  TData = Awaited<ReturnType<typeof banksFetchProductBalanceForPlatform>>,
  TError = unknown
>(
  productUid: string,
  platformUid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof banksFetchProductBalanceForPlatform>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getBanksFetchProductBalanceForPlatformQueryKey(productUid, platformUid);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof banksFetchProductBalanceForPlatform>>
  > = ({ signal }) =>
    banksFetchProductBalanceForPlatform(productUid, platformUid, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!(productUid && platformUid),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof banksFetchProductBalanceForPlatform>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type BanksFetchProductBalanceForPlatformQueryResult = NonNullable<
  Awaited<ReturnType<typeof banksFetchProductBalanceForPlatform>>
>;
export type BanksFetchProductBalanceForPlatformQueryError = unknown;

/**
 * @summary Fetch product balance for a platform
 */
export const useBanksFetchProductBalanceForPlatform = <
  TData = Awaited<ReturnType<typeof banksFetchProductBalanceForPlatform>>,
  TError = unknown
>(
  productUid: string,
  platformUid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof banksFetchProductBalanceForPlatform>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getBanksFetchProductBalanceForPlatformQueryOptions(
    productUid,
    platformUid,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getActiveAnonymousProducts = (
  params?: GetActiveAnonymousProductsParams,
  signal?: AbortSignal
) => {
  return customInstance<BondsmithPageListableAnonymousBankProductResponse>({
    url: `/banks/products/anonymousProducts`,
    method: 'GET',
    params,
    signal,
  });
};

export const getGetActiveAnonymousProductsQueryKey = (
  params?: GetActiveAnonymousProductsParams
) => {
  return [
    `/banks/products/anonymousProducts`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetActiveAnonymousProductsQueryOptions = <
  TData = Awaited<ReturnType<typeof getActiveAnonymousProducts>>,
  TError = unknown
>(
  params?: GetActiveAnonymousProductsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getActiveAnonymousProducts>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetActiveAnonymousProductsQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getActiveAnonymousProducts>>
  > = ({ signal }) => getActiveAnonymousProducts(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getActiveAnonymousProducts>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetActiveAnonymousProductsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getActiveAnonymousProducts>>
>;
export type GetActiveAnonymousProductsQueryError = unknown;

export const useGetActiveAnonymousProducts = <
  TData = Awaited<ReturnType<typeof getActiveAnonymousProducts>>,
  TError = unknown
>(
  params?: GetActiveAnonymousProductsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getActiveAnonymousProducts>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetActiveAnonymousProductsQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getFscsBrochure = (signal?: AbortSignal) => {
  return customInstance<PartnerBankFscsBrochureResponse>({
    url: `/banks/products/bank/brochure`,
    method: 'GET',
    signal,
  });
};

export const getGetFscsBrochureQueryKey = () => {
  return [`/banks/products/bank/brochure`] as const;
};

export const getGetFscsBrochureQueryOptions = <
  TData = Awaited<ReturnType<typeof getFscsBrochure>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getFscsBrochure>>,
    TError,
    TData
  >;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetFscsBrochureQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getFscsBrochure>>> = ({
    signal,
  }) => getFscsBrochure(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getFscsBrochure>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetFscsBrochureQueryResult = NonNullable<
  Awaited<ReturnType<typeof getFscsBrochure>>
>;
export type GetFscsBrochureQueryError = unknown;

export const useGetFscsBrochure = <
  TData = Awaited<ReturnType<typeof getFscsBrochure>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getFscsBrochure>>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetFscsBrochureQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const updateFscsBrochureForBank = (
  partnerBankFscsBrochureRequest: PartnerBankFscsBrochureRequest
) => {
  return customInstance<void>({
    url: `/banks/products/bank/brochure`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: partnerBankFscsBrochureRequest,
  });
};

export const getUpdateFscsBrochureForBankMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateFscsBrochureForBank>>,
    TError,
    { data: PartnerBankFscsBrochureRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateFscsBrochureForBank>>,
  TError,
  { data: PartnerBankFscsBrochureRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateFscsBrochureForBank>>,
    { data: PartnerBankFscsBrochureRequest }
  > = (props) => {
    const { data } = props ?? {};

    return updateFscsBrochureForBank(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateFscsBrochureForBankMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateFscsBrochureForBank>>
>;
export type UpdateFscsBrochureForBankMutationBody =
  PartnerBankFscsBrochureRequest;
export type UpdateFscsBrochureForBankMutationError = unknown;

export const useUpdateFscsBrochureForBank = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateFscsBrochureForBank>>,
    TError,
    { data: PartnerBankFscsBrochureRequest },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateFscsBrochureForBank>>,
  TError,
  { data: PartnerBankFscsBrochureRequest },
  TContext
> => {
  const mutationOptions = getUpdateFscsBrochureForBankMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getAccountDetailsForInsights = (
  params?: GetAccountDetailsForInsightsParams,
  signal?: AbortSignal
) => {
  return customInstance<BondsmithPageCustomerProductAccountsInsightsPaginatedRecord>(
    { url: `/banks/products/customerAccounts`, method: 'GET', params, signal }
  );
};

export const getGetAccountDetailsForInsightsQueryKey = (
  params?: GetAccountDetailsForInsightsParams
) => {
  return [
    `/banks/products/customerAccounts`,
    ...(params ? [params] : []),
  ] as const;
};

export const getGetAccountDetailsForInsightsQueryOptions = <
  TData = Awaited<ReturnType<typeof getAccountDetailsForInsights>>,
  TError = unknown
>(
  params?: GetAccountDetailsForInsightsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getAccountDetailsForInsights>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetAccountDetailsForInsightsQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getAccountDetailsForInsights>>
  > = ({ signal }) => getAccountDetailsForInsights(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getAccountDetailsForInsights>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetAccountDetailsForInsightsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAccountDetailsForInsights>>
>;
export type GetAccountDetailsForInsightsQueryError = unknown;

export const useGetAccountDetailsForInsights = <
  TData = Awaited<ReturnType<typeof getAccountDetailsForInsights>>,
  TError = unknown
>(
  params?: GetAccountDetailsForInsightsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getAccountDetailsForInsights>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetAccountDetailsForInsightsQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const listProductAmountsExpectedToGoToBank = (signal?: AbortSignal) => {
  return customInstance<IncomingInstructionsResponse[]>({
    url: `/banks/products/incoming-instructions/expected`,
    method: 'GET',
    signal,
  });
};

export const getListProductAmountsExpectedToGoToBankQueryKey = () => {
  return [`/banks/products/incoming-instructions/expected`] as const;
};

export const getListProductAmountsExpectedToGoToBankQueryOptions = <
  TData = Awaited<ReturnType<typeof listProductAmountsExpectedToGoToBank>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof listProductAmountsExpectedToGoToBank>>,
    TError,
    TData
  >;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getListProductAmountsExpectedToGoToBankQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof listProductAmountsExpectedToGoToBank>>
  > = ({ signal }) => listProductAmountsExpectedToGoToBank(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof listProductAmountsExpectedToGoToBank>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListProductAmountsExpectedToGoToBankQueryResult = NonNullable<
  Awaited<ReturnType<typeof listProductAmountsExpectedToGoToBank>>
>;
export type ListProductAmountsExpectedToGoToBankQueryError = unknown;

export const useListProductAmountsExpectedToGoToBank = <
  TData = Awaited<ReturnType<typeof listProductAmountsExpectedToGoToBank>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof listProductAmountsExpectedToGoToBank>>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getListProductAmountsExpectedToGoToBankQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const listPendingDepositsPerProduct = (signal?: AbortSignal) => {
  return customInstance<IncomingInstructionsResponse[]>({
    url: `/banks/products/incoming-instructions/pending`,
    method: 'GET',
    signal,
  });
};

export const getListPendingDepositsPerProductQueryKey = () => {
  return [`/banks/products/incoming-instructions/pending`] as const;
};

export const getListPendingDepositsPerProductQueryOptions = <
  TData = Awaited<ReturnType<typeof listPendingDepositsPerProduct>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof listPendingDepositsPerProduct>>,
    TError,
    TData
  >;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getListPendingDepositsPerProductQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof listPendingDepositsPerProduct>>
  > = ({ signal }) => listPendingDepositsPerProduct(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof listPendingDepositsPerProduct>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListPendingDepositsPerProductQueryResult = NonNullable<
  Awaited<ReturnType<typeof listPendingDepositsPerProduct>>
>;
export type ListPendingDepositsPerProductQueryError = unknown;

export const useListPendingDepositsPerProduct = <
  TData = Awaited<ReturnType<typeof listPendingDepositsPerProduct>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof listPendingDepositsPerProduct>>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListPendingDepositsPerProductQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const listUpcomingNoticeWithdrawals = (signal?: AbortSignal) => {
  return customInstance<UpcomingNoticeWithdrawalsResponse[]>({
    url: `/banks/products/notice-withdrawals`,
    method: 'GET',
    signal,
  });
};

export const getListUpcomingNoticeWithdrawalsQueryKey = () => {
  return [`/banks/products/notice-withdrawals`] as const;
};

export const getListUpcomingNoticeWithdrawalsQueryOptions = <
  TData = Awaited<ReturnType<typeof listUpcomingNoticeWithdrawals>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof listUpcomingNoticeWithdrawals>>,
    TError,
    TData
  >;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getListUpcomingNoticeWithdrawalsQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof listUpcomingNoticeWithdrawals>>
  > = ({ signal }) => listUpcomingNoticeWithdrawals(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof listUpcomingNoticeWithdrawals>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListUpcomingNoticeWithdrawalsQueryResult = NonNullable<
  Awaited<ReturnType<typeof listUpcomingNoticeWithdrawals>>
>;
export type ListUpcomingNoticeWithdrawalsQueryError = unknown;

export const useListUpcomingNoticeWithdrawals = <
  TData = Awaited<ReturnType<typeof listUpcomingNoticeWithdrawals>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof listUpcomingNoticeWithdrawals>>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListUpcomingNoticeWithdrawalsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const listOutstandingProposals = (
  params?: ListOutstandingProposalsParams,
  signal?: AbortSignal
) => {
  return customInstance<ProductProposalResponse[]>({
    url: `/banks/products/product-proposals`,
    method: 'GET',
    params,
    signal,
  });
};

export const getListOutstandingProposalsQueryKey = (
  params?: ListOutstandingProposalsParams
) => {
  return [
    `/banks/products/product-proposals`,
    ...(params ? [params] : []),
  ] as const;
};

export const getListOutstandingProposalsQueryOptions = <
  TData = Awaited<ReturnType<typeof listOutstandingProposals>>,
  TError = unknown
>(
  params?: ListOutstandingProposalsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof listOutstandingProposals>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getListOutstandingProposalsQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof listOutstandingProposals>>
  > = ({ signal }) => listOutstandingProposals(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof listOutstandingProposals>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListOutstandingProposalsQueryResult = NonNullable<
  Awaited<ReturnType<typeof listOutstandingProposals>>
>;
export type ListOutstandingProposalsQueryError = unknown;

export const useListOutstandingProposals = <
  TData = Awaited<ReturnType<typeof listOutstandingProposals>>,
  TError = unknown
>(
  params?: ListOutstandingProposalsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof listOutstandingProposals>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListOutstandingProposalsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const fetchRateProposal = (
  proposalUid: string,
  signal?: AbortSignal
) => {
  return customInstance<RateProposalResponse>({
    url: `/banks/products/product-proposals/rate/${proposalUid}`,
    method: 'GET',
    signal,
  });
};

export const getFetchRateProposalQueryKey = (proposalUid: string) => {
  return [`/banks/products/product-proposals/rate/${proposalUid}`] as const;
};

export const getFetchRateProposalQueryOptions = <
  TData = Awaited<ReturnType<typeof fetchRateProposal>>,
  TError = unknown
>(
  proposalUid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof fetchRateProposal>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getFetchRateProposalQueryKey(proposalUid);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof fetchRateProposal>>> =
    ({ signal }) => fetchRateProposal(proposalUid, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!proposalUid,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof fetchRateProposal>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type FetchRateProposalQueryResult = NonNullable<
  Awaited<ReturnType<typeof fetchRateProposal>>
>;
export type FetchRateProposalQueryError = unknown;

export const useFetchRateProposal = <
  TData = Awaited<ReturnType<typeof fetchRateProposal>>,
  TError = unknown
>(
  proposalUid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof fetchRateProposal>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getFetchRateProposalQueryOptions(proposalUid, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const fetchProposal = (proposalUid: string, signal?: AbortSignal) => {
  return customInstance<ProductProposalResponse>({
    url: `/banks/products/product-proposals/${proposalUid}`,
    method: 'GET',
    signal,
  });
};

export const getFetchProposalQueryKey = (proposalUid: string) => {
  return [`/banks/products/product-proposals/${proposalUid}`] as const;
};

export const getFetchProposalQueryOptions = <
  TData = Awaited<ReturnType<typeof fetchProposal>>,
  TError = unknown
>(
  proposalUid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof fetchProposal>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getFetchProposalQueryKey(proposalUid);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof fetchProposal>>> = ({
    signal,
  }) => fetchProposal(proposalUid, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!proposalUid,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof fetchProposal>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type FetchProposalQueryResult = NonNullable<
  Awaited<ReturnType<typeof fetchProposal>>
>;
export type FetchProposalQueryError = unknown;

export const useFetchProposal = <
  TData = Awaited<ReturnType<typeof fetchProposal>>,
  TError = unknown
>(
  proposalUid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof fetchProposal>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getFetchProposalQueryOptions(proposalUid, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const deleteProposal = (proposalUid: string) => {
  return customInstance<void>({
    url: `/banks/products/product-proposals/${proposalUid}`,
    method: 'DELETE',
  });
};

export const getDeleteProposalMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteProposal>>,
    TError,
    { proposalUid: string },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteProposal>>,
  TError,
  { proposalUid: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteProposal>>,
    { proposalUid: string }
  > = (props) => {
    const { proposalUid } = props ?? {};

    return deleteProposal(proposalUid);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteProposalMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteProposal>>
>;

export type DeleteProposalMutationError = unknown;

export const useDeleteProposal = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteProposal>>,
    TError,
    { proposalUid: string },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteProposal>>,
  TError,
  { proposalUid: string },
  TContext
> => {
  const mutationOptions = getDeleteProposalMutationOptions(options);

  return useMutation(mutationOptions);
};
export const listActiveAndPendingProducts = (
  params?: ListActiveAndPendingProductsParams,
  signal?: AbortSignal
) => {
  return customInstance<BondsmithPageListableBankProductPaginatedResponse>({
    url: `/banks/products/products`,
    method: 'GET',
    params,
    signal,
  });
};

export const getListActiveAndPendingProductsQueryKey = (
  params?: ListActiveAndPendingProductsParams
) => {
  return [`/banks/products/products`, ...(params ? [params] : [])] as const;
};

export const getListActiveAndPendingProductsQueryOptions = <
  TData = Awaited<ReturnType<typeof listActiveAndPendingProducts>>,
  TError = unknown
>(
  params?: ListActiveAndPendingProductsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof listActiveAndPendingProducts>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getListActiveAndPendingProductsQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof listActiveAndPendingProducts>>
  > = ({ signal }) => listActiveAndPendingProducts(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof listActiveAndPendingProducts>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListActiveAndPendingProductsQueryResult = NonNullable<
  Awaited<ReturnType<typeof listActiveAndPendingProducts>>
>;
export type ListActiveAndPendingProductsQueryError = unknown;

export const useListActiveAndPendingProducts = <
  TData = Awaited<ReturnType<typeof listActiveAndPendingProducts>>,
  TError = unknown
>(
  params?: ListActiveAndPendingProductsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof listActiveAndPendingProducts>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListActiveAndPendingProductsQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const proposeProduct = (
  bankProductRequestWithRateDetail: BankProductRequestWithRateDetail
) => {
  return customInstance<void>({
    url: `/banks/products/products`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: bankProductRequestWithRateDetail,
  });
};

export const getProposeProductMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof proposeProduct>>,
    TError,
    { data: BankProductRequestWithRateDetail },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof proposeProduct>>,
  TError,
  { data: BankProductRequestWithRateDetail },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof proposeProduct>>,
    { data: BankProductRequestWithRateDetail }
  > = (props) => {
    const { data } = props ?? {};

    return proposeProduct(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type ProposeProductMutationResult = NonNullable<
  Awaited<ReturnType<typeof proposeProduct>>
>;
export type ProposeProductMutationBody = BankProductRequestWithRateDetail;
export type ProposeProductMutationError = unknown;

export const useProposeProduct = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof proposeProduct>>,
    TError,
    { data: BankProductRequestWithRateDetail },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof proposeProduct>>,
  TError,
  { data: BankProductRequestWithRateDetail },
  TContext
> => {
  const mutationOptions = getProposeProductMutationOptions(options);

  return useMutation(mutationOptions);
};
export const downloadAllProducts = (signal?: AbortSignal) => {
  return customInstance<Blob>({
    url: `/banks/products/products.xlsx`,
    method: 'GET',
    responseType: 'blob',
    signal,
  });
};

export const getDownloadAllProductsQueryKey = () => {
  return [`/banks/products/products.xlsx`] as const;
};

export const getDownloadAllProductsQueryOptions = <
  TData = Awaited<ReturnType<typeof downloadAllProducts>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof downloadAllProducts>>,
    TError,
    TData
  >;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getDownloadAllProductsQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof downloadAllProducts>>
  > = ({ signal }) => downloadAllProducts(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof downloadAllProducts>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type DownloadAllProductsQueryResult = NonNullable<
  Awaited<ReturnType<typeof downloadAllProducts>>
>;
export type DownloadAllProductsQueryError = unknown;

export const useDownloadAllProducts = <
  TData = Awaited<ReturnType<typeof downloadAllProducts>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof downloadAllProducts>>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getDownloadAllProductsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const uploadAllProducts = (
  bankProductUploadRequestBody: BankProductUploadRequestBody
) => {
  const formData = new FormData();
  formData.append('data', bankProductUploadRequestBody.data);

  return customInstance<ListableBankProductUploadResponse[]>({
    url: `/banks/products/products.xlsx`,
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data' },
    data: formData,
  });
};

export const getUploadAllProductsMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof uploadAllProducts>>,
    TError,
    { data: BankProductUploadRequestBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof uploadAllProducts>>,
  TError,
  { data: BankProductUploadRequestBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof uploadAllProducts>>,
    { data: BankProductUploadRequestBody }
  > = (props) => {
    const { data } = props ?? {};

    return uploadAllProducts(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UploadAllProductsMutationResult = NonNullable<
  Awaited<ReturnType<typeof uploadAllProducts>>
>;
export type UploadAllProductsMutationBody = BankProductUploadRequestBody;
export type UploadAllProductsMutationError = unknown;

export const useUploadAllProducts = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof uploadAllProducts>>,
    TError,
    { data: BankProductUploadRequestBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof uploadAllProducts>>,
  TError,
  { data: BankProductUploadRequestBody },
  TContext
> => {
  const mutationOptions = getUploadAllProductsMutationOptions(options);

  return useMutation(mutationOptions);
};
export const calculateAer = (signal?: AbortSignal) => {
  return customInstance<number>({
    url: `/banks/products/products/calculate-aer`,
    method: 'GET',
    signal,
  });
};

export const getCalculateAerQueryKey = () => {
  return [`/banks/products/products/calculate-aer`] as const;
};

export const getCalculateAerQueryOptions = <
  TData = Awaited<ReturnType<typeof calculateAer>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof calculateAer>>,
    TError,
    TData
  >;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getCalculateAerQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof calculateAer>>> = ({
    signal,
  }) => calculateAer(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof calculateAer>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type CalculateAerQueryResult = NonNullable<
  Awaited<ReturnType<typeof calculateAer>>
>;
export type CalculateAerQueryError = unknown;

export const useCalculateAer = <
  TData = Awaited<ReturnType<typeof calculateAer>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof calculateAer>>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getCalculateAerQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const fetchProduct = (productUid: string, signal?: AbortSignal) => {
  return customInstance<BankProductResponse>({
    url: `/banks/products/products/${productUid}`,
    method: 'GET',
    signal,
  });
};

export const getFetchProductQueryKey = (productUid: string) => {
  return [`/banks/products/products/${productUid}`] as const;
};

export const getFetchProductQueryOptions = <
  TData = Awaited<ReturnType<typeof fetchProduct>>,
  TError = unknown
>(
  productUid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof fetchProduct>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getFetchProductQueryKey(productUid);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof fetchProduct>>> = ({
    signal,
  }) => fetchProduct(productUid, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!productUid,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof fetchProduct>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type FetchProductQueryResult = NonNullable<
  Awaited<ReturnType<typeof fetchProduct>>
>;
export type FetchProductQueryError = unknown;

export const useFetchProduct = <
  TData = Awaited<ReturnType<typeof fetchProduct>>,
  TError = unknown
>(
  productUid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof fetchProduct>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getFetchProductQueryOptions(productUid, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const proposeRetireProduct = (
  productUid: string,
  bankProductClosureRequest: BankProductClosureRequest
) => {
  return customInstance<void>({
    url: `/banks/products/products/${productUid}`,
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
    data: bankProductClosureRequest,
  });
};

export const getProposeRetireProductMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof proposeRetireProduct>>,
    TError,
    { productUid: string; data: BankProductClosureRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof proposeRetireProduct>>,
  TError,
  { productUid: string; data: BankProductClosureRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof proposeRetireProduct>>,
    { productUid: string; data: BankProductClosureRequest }
  > = (props) => {
    const { productUid, data } = props ?? {};

    return proposeRetireProduct(productUid, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type ProposeRetireProductMutationResult = NonNullable<
  Awaited<ReturnType<typeof proposeRetireProduct>>
>;
export type ProposeRetireProductMutationBody = BankProductClosureRequest;
export type ProposeRetireProductMutationError = unknown;

export const useProposeRetireProduct = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof proposeRetireProduct>>,
    TError,
    { productUid: string; data: BankProductClosureRequest },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof proposeRetireProduct>>,
  TError,
  { productUid: string; data: BankProductClosureRequest },
  TContext
> => {
  const mutationOptions = getProposeRetireProductMutationOptions(options);

  return useMutation(mutationOptions);
};
export const proposeProductSoftClosure = (
  productUid: string,
  bankProductClosureRequest: BankProductClosureRequest
) => {
  return customInstance<void>({
    url: `/banks/products/products/${productUid}/close/soft`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: bankProductClosureRequest,
  });
};

export const getProposeProductSoftClosureMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof proposeProductSoftClosure>>,
    TError,
    { productUid: string; data: BankProductClosureRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof proposeProductSoftClosure>>,
  TError,
  { productUid: string; data: BankProductClosureRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof proposeProductSoftClosure>>,
    { productUid: string; data: BankProductClosureRequest }
  > = (props) => {
    const { productUid, data } = props ?? {};

    return proposeProductSoftClosure(productUid, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type ProposeProductSoftClosureMutationResult = NonNullable<
  Awaited<ReturnType<typeof proposeProductSoftClosure>>
>;
export type ProposeProductSoftClosureMutationBody = BankProductClosureRequest;
export type ProposeProductSoftClosureMutationError = unknown;

export const useProposeProductSoftClosure = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof proposeProductSoftClosure>>,
    TError,
    { productUid: string; data: BankProductClosureRequest },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof proposeProductSoftClosure>>,
  TError,
  { productUid: string; data: BankProductClosureRequest },
  TContext
> => {
  const mutationOptions = getProposeProductSoftClosureMutationOptions(options);

  return useMutation(mutationOptions);
};
export const proposeUpdateRate = (
  productUid: string,
  rateDetailUpdateRequest: RateDetailUpdateRequest
) => {
  return customInstance<void>({
    url: `/banks/products/products/${productUid}/rates`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: rateDetailUpdateRequest,
  });
};

export const getProposeUpdateRateMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof proposeUpdateRate>>,
    TError,
    { productUid: string; data: RateDetailUpdateRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof proposeUpdateRate>>,
  TError,
  { productUid: string; data: RateDetailUpdateRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof proposeUpdateRate>>,
    { productUid: string; data: RateDetailUpdateRequest }
  > = (props) => {
    const { productUid, data } = props ?? {};

    return proposeUpdateRate(productUid, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type ProposeUpdateRateMutationResult = NonNullable<
  Awaited<ReturnType<typeof proposeUpdateRate>>
>;
export type ProposeUpdateRateMutationBody = RateDetailUpdateRequest;
export type ProposeUpdateRateMutationError = unknown;

export const useProposeUpdateRate = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof proposeUpdateRate>>,
    TError,
    { productUid: string; data: RateDetailUpdateRequest },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof proposeUpdateRate>>,
  TError,
  { productUid: string; data: RateDetailUpdateRequest },
  TContext
> => {
  const mutationOptions = getProposeUpdateRateMutationOptions(options);

  return useMutation(mutationOptions);
};
export const listUpcomingMaturities = (
  params?: ListUpcomingMaturitiesParams,
  signal?: AbortSignal
) => {
  return customInstance<BondsmithPageUpcomingMaturitiesPaginatedRecord>({
    url: `/banks/products/upcoming-maturities`,
    method: 'GET',
    params,
    signal,
  });
};

export const getListUpcomingMaturitiesQueryKey = (
  params?: ListUpcomingMaturitiesParams
) => {
  return [
    `/banks/products/upcoming-maturities`,
    ...(params ? [params] : []),
  ] as const;
};

export const getListUpcomingMaturitiesQueryOptions = <
  TData = Awaited<ReturnType<typeof listUpcomingMaturities>>,
  TError = unknown
>(
  params?: ListUpcomingMaturitiesParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof listUpcomingMaturities>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getListUpcomingMaturitiesQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof listUpcomingMaturities>>
  > = ({ signal }) => listUpcomingMaturities(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof listUpcomingMaturities>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListUpcomingMaturitiesQueryResult = NonNullable<
  Awaited<ReturnType<typeof listUpcomingMaturities>>
>;
export type ListUpcomingMaturitiesQueryError = unknown;

export const useListUpcomingMaturities = <
  TData = Awaited<ReturnType<typeof listUpcomingMaturities>>,
  TError = unknown
>(
  params?: ListUpcomingMaturitiesParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof listUpcomingMaturities>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListUpcomingMaturitiesQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};
