/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Bondsmith Savings Exchange Operation Portal APIs
 * OpenAPI spec version: 0.1
 */

export type UserRole = typeof UserRole[keyof typeof UserRole];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserRole = {
  BANK_ADMIN: 'BANK_ADMIN',
  BANK_USER: 'BANK_USER',
  BANK_READ_ONLY: 'BANK_READ_ONLY',
  BANK_INPUTTER: 'BANK_INPUTTER',
  BANK_AUTHORISER: 'BANK_AUTHORISER',
  BONDSMITH_OPS_READ_ONLY: 'BONDSMITH_OPS_READ_ONLY',
  BONDSMITH_OPS_USER: 'BONDSMITH_OPS_USER',
  BONDSMITH_COMPLIANCE_USER: 'BONDSMITH_COMPLIANCE_USER',
  BONDSMITH_SUPER_ADMIN: 'BONDSMITH_SUPER_ADMIN',
  DISTRIBUTOR_PORTAL_USER: 'DISTRIBUTOR_PORTAL_USER',
  DISTRIBUTOR_PORTAL_ADMIN: 'DISTRIBUTOR_PORTAL_ADMIN',
  DISTRIBUTOR_PORTAL_COMPLIANCE: 'DISTRIBUTOR_PORTAL_COMPLIANCE',
  DISTRIBUTOR_ALL: 'DISTRIBUTOR_ALL',
  PAYMENT_AUTHORISER: 'PAYMENT_AUTHORISER',
} as const;
