import { PortalUserResponse } from '@b7hio/api-lib/src/ops-portal';
import { FeatureDialog } from '@b7hio/core-lib/src';
import { Button, styled } from '@mui/material';
import { useTranslation } from 'next-i18next';
import React from 'react';

interface Props {
  readonly open: boolean;
  readonly user?: PortalUserResponse;
  readonly onClose: (event: object, reason: string) => void;
  readonly onSubmit: (user: PortalUserResponse) => void;
}

export const UserDeleteDialog: React.FC<Props> = ({
  open,
  user,
  onClose,
  onSubmit,
}) => {
  const { t } = useTranslation(['users']);

  return (
    <FeatureDialog
      id="UserDeleteDialog"
      open={open}
      title={t('users:deleteUser.title')}
      onClose={onClose}
      skipClose={true}>
      <QuestionContainer>
        <span>{t('users:deleteUser.question')}</span>
        <span>
          <strong>{t('users:deleteUser.user', { user: user?.email })}</strong>
        </span>
      </QuestionContainer>

      <OptionsContainer>
        <Button variant="outlined" onClick={(e) => onClose(e, 'skip')}>
          {t('users:actions.cancel')}
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={() => {
            if (user) {
              onSubmit(user);
            }
          }}>
          {t('users:deleteUser.title')}
        </Button>
      </OptionsContainer>
    </FeatureDialog>
  );
};

const QuestionContainer = styled('p')(() => ({
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'center',
  marginTop: 0,
}));

const OptionsContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  columnGap: '0.5rem',
}));
