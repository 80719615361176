/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Bondsmith Savings Exchange Operation Portal APIs
 * OpenAPI spec version: 0.1
 */
import { useMutation, useQuery } from 'react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { customInstance } from '../../../../mutator/instance';
import type {
  IntegrationPartnerPlatformRequest,
  ListPlatforms2Params,
  NominatedAccountRequest,
  PartnerPlatformMinimalResponse,
  PartnerPlatformRequest,
  PartnerPlatformResponse,
  PartnerPlatformUpdateRequest,
  PlatformTaxWrapperNominatedAccountRequest,
  ScheduleEventType,
  UpdatePlatformEndOfDayParams,
} from '../../models';

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

export const listPlatforms2 = (
  params?: ListPlatforms2Params,
  signal?: AbortSignal
) => {
  return customInstance<PartnerPlatformResponse[]>({
    url: `/operations/platforms`,
    method: 'GET',
    params,
    signal,
  });
};

export const getListPlatforms2QueryKey = (params?: ListPlatforms2Params) => {
  return [`/operations/platforms`, ...(params ? [params] : [])] as const;
};

export const getListPlatforms2QueryOptions = <
  TData = Awaited<ReturnType<typeof listPlatforms2>>,
  TError = unknown
>(
  params?: ListPlatforms2Params,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof listPlatforms2>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListPlatforms2QueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listPlatforms2>>> = ({
    signal,
  }) => listPlatforms2(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof listPlatforms2>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListPlatforms2QueryResult = NonNullable<
  Awaited<ReturnType<typeof listPlatforms2>>
>;
export type ListPlatforms2QueryError = unknown;

export const useListPlatforms2 = <
  TData = Awaited<ReturnType<typeof listPlatforms2>>,
  TError = unknown
>(
  params?: ListPlatforms2Params,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof listPlatforms2>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListPlatforms2QueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const createPlatform1 = (
  partnerPlatformRequest: PartnerPlatformRequest
) => {
  return customInstance<string>({
    url: `/operations/platforms`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: partnerPlatformRequest,
  });
};

export const getCreatePlatform1MutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createPlatform1>>,
    TError,
    { data: PartnerPlatformRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createPlatform1>>,
  TError,
  { data: PartnerPlatformRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createPlatform1>>,
    { data: PartnerPlatformRequest }
  > = (props) => {
    const { data } = props ?? {};

    return createPlatform1(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreatePlatform1MutationResult = NonNullable<
  Awaited<ReturnType<typeof createPlatform1>>
>;
export type CreatePlatform1MutationBody = PartnerPlatformRequest;
export type CreatePlatform1MutationError = unknown;

export const useCreatePlatform1 = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createPlatform1>>,
    TError,
    { data: PartnerPlatformRequest },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof createPlatform1>>,
  TError,
  { data: PartnerPlatformRequest },
  TContext
> => {
  const mutationOptions = getCreatePlatform1MutationOptions(options);

  return useMutation(mutationOptions);
};
export const getPlatformForClient = (
  clientId: string,
  signal?: AbortSignal
) => {
  return customInstance<string>({
    url: `/operations/platforms/clients/${clientId}`,
    method: 'GET',
    signal,
  });
};

export const getGetPlatformForClientQueryKey = (clientId: string) => {
  return [`/operations/platforms/clients/${clientId}`] as const;
};

export const getGetPlatformForClientQueryOptions = <
  TData = Awaited<ReturnType<typeof getPlatformForClient>>,
  TError = unknown
>(
  clientId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPlatformForClient>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetPlatformForClientQueryKey(clientId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPlatformForClient>>
  > = ({ signal }) => getPlatformForClient(clientId, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!clientId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getPlatformForClient>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetPlatformForClientQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPlatformForClient>>
>;
export type GetPlatformForClientQueryError = unknown;

export const useGetPlatformForClient = <
  TData = Awaited<ReturnType<typeof getPlatformForClient>>,
  TError = unknown
>(
  clientId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPlatformForClient>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPlatformForClientQueryOptions(clientId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const listAllPlatformsMinimal = (signal?: AbortSignal) => {
  return customInstance<PartnerPlatformMinimalResponse[]>({
    url: `/operations/platforms/minimal`,
    method: 'GET',
    signal,
  });
};

export const getListAllPlatformsMinimalQueryKey = () => {
  return [`/operations/platforms/minimal`] as const;
};

export const getListAllPlatformsMinimalQueryOptions = <
  TData = Awaited<ReturnType<typeof listAllPlatformsMinimal>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof listAllPlatformsMinimal>>,
    TError,
    TData
  >;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getListAllPlatformsMinimalQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof listAllPlatformsMinimal>>
  > = ({ signal }) => listAllPlatformsMinimal(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof listAllPlatformsMinimal>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type ListAllPlatformsMinimalQueryResult = NonNullable<
  Awaited<ReturnType<typeof listAllPlatformsMinimal>>
>;
export type ListAllPlatformsMinimalQueryError = unknown;

export const useListAllPlatformsMinimal = <
  TData = Awaited<ReturnType<typeof listAllPlatformsMinimal>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof listAllPlatformsMinimal>>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListAllPlatformsMinimalQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const createIntegrationPartner = (
  integrationPartnerPlatformRequest: IntegrationPartnerPlatformRequest
) => {
  return customInstance<string>({
    url: `/operations/platforms/platforms/integration`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: integrationPartnerPlatformRequest,
  });
};

export const getCreateIntegrationPartnerMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createIntegrationPartner>>,
    TError,
    { data: IntegrationPartnerPlatformRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createIntegrationPartner>>,
  TError,
  { data: IntegrationPartnerPlatformRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createIntegrationPartner>>,
    { data: IntegrationPartnerPlatformRequest }
  > = (props) => {
    const { data } = props ?? {};

    return createIntegrationPartner(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateIntegrationPartnerMutationResult = NonNullable<
  Awaited<ReturnType<typeof createIntegrationPartner>>
>;
export type CreateIntegrationPartnerMutationBody =
  IntegrationPartnerPlatformRequest;
export type CreateIntegrationPartnerMutationError = unknown;

export const useCreateIntegrationPartner = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createIntegrationPartner>>,
    TError,
    { data: IntegrationPartnerPlatformRequest },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof createIntegrationPartner>>,
  TError,
  { data: IntegrationPartnerPlatformRequest },
  TContext
> => {
  const mutationOptions = getCreateIntegrationPartnerMutationOptions(options);

  return useMutation(mutationOptions);
};
export const fetchPlatform1 = (platformUid: string, signal?: AbortSignal) => {
  return customInstance<PartnerPlatformResponse>({
    url: `/operations/platforms/${platformUid}`,
    method: 'GET',
    signal,
  });
};

export const getFetchPlatform1QueryKey = (platformUid: string) => {
  return [`/operations/platforms/${platformUid}`] as const;
};

export const getFetchPlatform1QueryOptions = <
  TData = Awaited<ReturnType<typeof fetchPlatform1>>,
  TError = unknown
>(
  platformUid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof fetchPlatform1>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getFetchPlatform1QueryKey(platformUid);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof fetchPlatform1>>> = ({
    signal,
  }) => fetchPlatform1(platformUid, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!platformUid,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof fetchPlatform1>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type FetchPlatform1QueryResult = NonNullable<
  Awaited<ReturnType<typeof fetchPlatform1>>
>;
export type FetchPlatform1QueryError = unknown;

export const useFetchPlatform1 = <
  TData = Awaited<ReturnType<typeof fetchPlatform1>>,
  TError = unknown
>(
  platformUid: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof fetchPlatform1>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getFetchPlatform1QueryOptions(platformUid, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const updatePlatform = (
  platformUid: string,
  partnerPlatformRequest: PartnerPlatformRequest
) => {
  return customInstance<void>({
    url: `/operations/platforms/${platformUid}`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: partnerPlatformRequest,
  });
};

export const getUpdatePlatformMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updatePlatform>>,
    TError,
    { platformUid: string; data: PartnerPlatformRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updatePlatform>>,
  TError,
  { platformUid: string; data: PartnerPlatformRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updatePlatform>>,
    { platformUid: string; data: PartnerPlatformRequest }
  > = (props) => {
    const { platformUid, data } = props ?? {};

    return updatePlatform(platformUid, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdatePlatformMutationResult = NonNullable<
  Awaited<ReturnType<typeof updatePlatform>>
>;
export type UpdatePlatformMutationBody = PartnerPlatformRequest;
export type UpdatePlatformMutationError = unknown;

export const useUpdatePlatform = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updatePlatform>>,
    TError,
    { platformUid: string; data: PartnerPlatformRequest },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof updatePlatform>>,
  TError,
  { platformUid: string; data: PartnerPlatformRequest },
  TContext
> => {
  const mutationOptions = getUpdatePlatformMutationOptions(options);

  return useMutation(mutationOptions);
};
export const patchPlatform = (
  platformUid: string,
  partnerPlatformUpdateRequest: PartnerPlatformUpdateRequest
) => {
  return customInstance<void>({
    url: `/operations/platforms/${platformUid}`,
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    data: partnerPlatformUpdateRequest,
  });
};

export const getPatchPlatformMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchPlatform>>,
    TError,
    { platformUid: string; data: PartnerPlatformUpdateRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof patchPlatform>>,
  TError,
  { platformUid: string; data: PartnerPlatformUpdateRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof patchPlatform>>,
    { platformUid: string; data: PartnerPlatformUpdateRequest }
  > = (props) => {
    const { platformUid, data } = props ?? {};

    return patchPlatform(platformUid, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PatchPlatformMutationResult = NonNullable<
  Awaited<ReturnType<typeof patchPlatform>>
>;
export type PatchPlatformMutationBody = PartnerPlatformUpdateRequest;
export type PatchPlatformMutationError = unknown;

export const usePatchPlatform = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchPlatform>>,
    TError,
    { platformUid: string; data: PartnerPlatformUpdateRequest },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof patchPlatform>>,
  TError,
  { platformUid: string; data: PartnerPlatformUpdateRequest },
  TContext
> => {
  const mutationOptions = getPatchPlatformMutationOptions(options);

  return useMutation(mutationOptions);
};
export const togglePlatformApiAccess = (
  platformUid: string,
  allow: boolean
) => {
  return customInstance<void>({
    url: `/operations/platforms/${platformUid}/apiAccess/${allow}`,
    method: 'DELETE',
  });
};

export const getTogglePlatformApiAccessMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof togglePlatformApiAccess>>,
    TError,
    { platformUid: string; allow: boolean },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof togglePlatformApiAccess>>,
  TError,
  { platformUid: string; allow: boolean },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof togglePlatformApiAccess>>,
    { platformUid: string; allow: boolean }
  > = (props) => {
    const { platformUid, allow } = props ?? {};

    return togglePlatformApiAccess(platformUid, allow);
  };

  return { mutationFn, ...mutationOptions };
};

export type TogglePlatformApiAccessMutationResult = NonNullable<
  Awaited<ReturnType<typeof togglePlatformApiAccess>>
>;

export type TogglePlatformApiAccessMutationError = unknown;

export const useTogglePlatformApiAccess = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof togglePlatformApiAccess>>,
    TError,
    { platformUid: string; allow: boolean },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof togglePlatformApiAccess>>,
  TError,
  { platformUid: string; allow: boolean },
  TContext
> => {
  const mutationOptions = getTogglePlatformApiAccessMutationOptions(options);

  return useMutation(mutationOptions);
};
export const createCollectionsAccount = (
  platformUid: string,
  nominatedAccountRequest: NominatedAccountRequest
) => {
  return customInstance<void>({
    url: `/operations/platforms/${platformUid}/collections-account`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: nominatedAccountRequest,
  });
};

export const getCreateCollectionsAccountMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createCollectionsAccount>>,
    TError,
    { platformUid: string; data: NominatedAccountRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createCollectionsAccount>>,
  TError,
  { platformUid: string; data: NominatedAccountRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createCollectionsAccount>>,
    { platformUid: string; data: NominatedAccountRequest }
  > = (props) => {
    const { platformUid, data } = props ?? {};

    return createCollectionsAccount(platformUid, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateCollectionsAccountMutationResult = NonNullable<
  Awaited<ReturnType<typeof createCollectionsAccount>>
>;
export type CreateCollectionsAccountMutationBody = NominatedAccountRequest;
export type CreateCollectionsAccountMutationError = unknown;

export const useCreateCollectionsAccount = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createCollectionsAccount>>,
    TError,
    { platformUid: string; data: NominatedAccountRequest },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof createCollectionsAccount>>,
  TError,
  { platformUid: string; data: NominatedAccountRequest },
  TContext
> => {
  const mutationOptions = getCreateCollectionsAccountMutationOptions(options);

  return useMutation(mutationOptions);
};
export const updatePlatformEndOfDay = (
  platformUid: string,
  params: UpdatePlatformEndOfDayParams
) => {
  return customInstance<void>({
    url: `/operations/platforms/${platformUid}/endOfDay`,
    method: 'PUT',
    params,
  });
};

export const getUpdatePlatformEndOfDayMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updatePlatformEndOfDay>>,
    TError,
    { platformUid: string; params: UpdatePlatformEndOfDayParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updatePlatformEndOfDay>>,
  TError,
  { platformUid: string; params: UpdatePlatformEndOfDayParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updatePlatformEndOfDay>>,
    { platformUid: string; params: UpdatePlatformEndOfDayParams }
  > = (props) => {
    const { platformUid, params } = props ?? {};

    return updatePlatformEndOfDay(platformUid, params);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdatePlatformEndOfDayMutationResult = NonNullable<
  Awaited<ReturnType<typeof updatePlatformEndOfDay>>
>;

export type UpdatePlatformEndOfDayMutationError = unknown;

export const useUpdatePlatformEndOfDay = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updatePlatformEndOfDay>>,
    TError,
    { platformUid: string; params: UpdatePlatformEndOfDayParams },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof updatePlatformEndOfDay>>,
  TError,
  { platformUid: string; params: UpdatePlatformEndOfDayParams },
  TContext
> => {
  const mutationOptions = getUpdatePlatformEndOfDayMutationOptions(options);

  return useMutation(mutationOptions);
};
export const insertPlatformTaxWrapperNominatedAccount = (
  platformUid: string,
  platformTaxWrapperNominatedAccountRequest: PlatformTaxWrapperNominatedAccountRequest
) => {
  return customInstance<void>({
    url: `/operations/platforms/${platformUid}/nominated-account`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: platformTaxWrapperNominatedAccountRequest,
  });
};

export const getInsertPlatformTaxWrapperNominatedAccountMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof insertPlatformTaxWrapperNominatedAccount>>,
    TError,
    { platformUid: string; data: PlatformTaxWrapperNominatedAccountRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof insertPlatformTaxWrapperNominatedAccount>>,
  TError,
  { platformUid: string; data: PlatformTaxWrapperNominatedAccountRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof insertPlatformTaxWrapperNominatedAccount>>,
    { platformUid: string; data: PlatformTaxWrapperNominatedAccountRequest }
  > = (props) => {
    const { platformUid, data } = props ?? {};

    return insertPlatformTaxWrapperNominatedAccount(platformUid, data);
  };

  return { mutationFn, ...mutationOptions };
};

export type InsertPlatformTaxWrapperNominatedAccountMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof insertPlatformTaxWrapperNominatedAccount>>
  >;
export type InsertPlatformTaxWrapperNominatedAccountMutationBody =
  PlatformTaxWrapperNominatedAccountRequest;
export type InsertPlatformTaxWrapperNominatedAccountMutationError = unknown;

export const useInsertPlatformTaxWrapperNominatedAccount = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof insertPlatformTaxWrapperNominatedAccount>>,
    TError,
    { platformUid: string; data: PlatformTaxWrapperNominatedAccountRequest },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof insertPlatformTaxWrapperNominatedAccount>>,
  TError,
  { platformUid: string; data: PlatformTaxWrapperNominatedAccountRequest },
  TContext
> => {
  const mutationOptions =
    getInsertPlatformTaxWrapperNominatedAccountMutationOptions(options);

  return useMutation(mutationOptions);
};
export const addPlatformSchedule = (
  platformUid: string,
  scheduleEventType: ScheduleEventType
) => {
  return customInstance<void>({
    url: `/operations/platforms/${platformUid}/schedules/${scheduleEventType}`,
    method: 'POST',
  });
};

export const getAddPlatformScheduleMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof addPlatformSchedule>>,
    TError,
    { platformUid: string; scheduleEventType: ScheduleEventType },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<typeof addPlatformSchedule>>,
  TError,
  { platformUid: string; scheduleEventType: ScheduleEventType },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof addPlatformSchedule>>,
    { platformUid: string; scheduleEventType: ScheduleEventType }
  > = (props) => {
    const { platformUid, scheduleEventType } = props ?? {};

    return addPlatformSchedule(platformUid, scheduleEventType);
  };

  return { mutationFn, ...mutationOptions };
};

export type AddPlatformScheduleMutationResult = NonNullable<
  Awaited<ReturnType<typeof addPlatformSchedule>>
>;

export type AddPlatformScheduleMutationError = unknown;

export const useAddPlatformSchedule = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof addPlatformSchedule>>,
    TError,
    { platformUid: string; scheduleEventType: ScheduleEventType },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<typeof addPlatformSchedule>>,
  TError,
  { platformUid: string; scheduleEventType: ScheduleEventType },
  TContext
> => {
  const mutationOptions = getAddPlatformScheduleMutationOptions(options);

  return useMutation(mutationOptions);
};
