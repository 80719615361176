/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Bondsmith Savings Exchange Operation Portal APIs
 * OpenAPI spec version: 0.1
 */

export type ExchangeDebitCreditCodeType =
  typeof ExchangeDebitCreditCodeType[keyof typeof ExchangeDebitCreditCodeType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ExchangeDebitCreditCodeType = {
  CREDIT: 'CREDIT',
  DEBIT: 'DEBIT',
} as const;
